import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	reduxForm,
	Field,
	SubmissionError,
	formValueSelector,
	getFormSubmitErrors,
	change
} from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router";
import { compose } from "redux";
import get from "lodash/get";
import has from "lodash/has";
import styled from "styled-components";
import {
	emailRegex,
	ONLY_CHARS,
	PERMANENT,
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	CV_SIZE_LIMIT,
	FREELANCER,
	CONSULTANCY
} from "config";
import checkPasswordRules from "common/PasswordChecker";
import FormField from "common/FormField";
import AcceptTermsAndPolicyModal from "common/TermsModal";
import { capitalize } from "config/helpers";
import PhoneNumberField from "common/PhoneNumberField";
import { scrollToFirstError } from "common/Functions";
import { getSignInRoute } from "../Login";
import S3Uploader from "common/S3Uploader";
import toaster from "common/Toaster";
import ReCaptcha from "common/ReCaptcha";
import AxiosHelper from "config/axios-helper";
import {
	PRE_SIGNED_URL_OFFLINE,
	RECAPTCHA_VERIFY_ENDPOINT
} from "config/api-endpoints";
import linkedinInsight from "lib/linkedIn-insight";
import AcceptPrivacyPolicy from "./AcceptPrivacyPolicy";
import { ReactComponent as FileAttachmentIcon } from "static/icons/file-attachment.svg";
import { ReactComponent as FileIcon } from "static/icons/file.svg";
import { ReactComponent as CancelIcon } from "static/icons/cancel.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-2.svg";
import { ReactComponent as CompletedIcon } from "static/icons/completed-check.svg";
import { bytesToSize } from "config/helpers";
import cx from "classnames";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import { Box, Flex } from "rebass";

const ReCaptchaContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	clear: both;
`;

const UploaderContainer = styled.div`
	margin-bottom: 20px;
`;

const isProd = window.location.hostname === "app.wiggli.io";

class SignUpFirstStep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			acceptPolicy: false,
			captchaResponse: undefined,
			apiResponse: undefined,
			captchaFailed: false
		};
		if (isProd) {
			linkedinInsight.init("3411970");
		}
	}
	acceptPolicyHandler = () => {
		this.props.dispatch(change("signup", "accept_policies", true));
	};

	policyCloseHandler = () => {
		this.setState({ acceptPolicy: false });
	};

	handleSignUp = props => {
		if (
			!this.state.captchaResponse &&
			this.props.type === PERMANENT &&
			!window.Cypress
		) {
			return this.setState({ captchaFailed: true });
		}
		return this.props.onSignUp(props).catch(error => {
			return Promise.reject(new SubmissionError(error));
		});
	};

	onUploadSuccess = data => {
		this.props.dispatch(change("signup", "cv_doc", get(data, "url", null)));
	};

	resetFile = () => {
		this.onUploadSuccess(null);
	};

	hasAcceptedTermsAndPolicy = () => {
		const {
			firstname,
			lastname,
			email,
			password,
			acceptedPolices
		} = this.props;

		let showModalCond = firstname && lastname && email && password;
		if (showModalCond) {
			const nextState = {};
			if (!acceptedPolices) nextState.acceptPolicy = true;

			this.setState(nextState);
		}
	};

	handleClaimAccount = () => {
		const { onClaimAccount } = this.props;
		onClaimAccount({
			firstname: get(this.props, "firstname", ""),
			lastname: get(this.props, "lastname", ""),
			email: get(this.props, "email", "")
		});
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	progressComponent({ percentCompleted }) {
		if (percentCompleted) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	}

	renderUploader = ({ meta: { touched, error } }) => (
		<div className="cv-upload">
			<S3Uploader
				accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
				id="cv-upload"
				multiple={false}
				folder={this.props.type === PERMANENT ? "candidate" : "cv"}
				autoSubmit={true}
				onSuccess={this.onUploadSuccess}
				onClose={this.resetFile}
				resetOnSuccess={false}
				isLogin={true}
				onError={error =>
					toaster.danger(error, { id: "uploader-error", duration: 7 })
				}
				customRender={({ progressObject, filesList, onCancel }) => {
					const file = filesList?.[0];
					const isCompleted =
						!!file &&
						progressObject?.percentCompleted === 100 &&
						!(touched && error);

					if (file)
						return (
							<div
								className={`fake-file ${touched && error ? "has-error" : ""}`}
							>
								<div className="infos">
									<div className="icon">
										<FileIcon />
									</div>
									<div className="name">{file.name} </div>
									{isCompleted && (
										<span className="size">&nbsp;{bytesToSize(file.size)}</span>
									)}
								</div>
								<div
									className={cx(
										"custom-progress",
										!!file && isCompleted && "is-completed"
									)}
								>
									{!!file && (
										<div className="file-progress">
											{isCompleted ? (
												<div className="completed">
													<span>Completed</span>
													<CompletedIcon />
												</div>
											) : (
												this.progressComponent(progressObject)
											)}
										</div>
									)}
									{!!file && (
										<button
											type="button"
											onClick={onCancel}
											className="cancel-icon"
										>
											{isCompleted ? <TrashIcon /> : <CancelIcon />}
										</button>
									)}
								</div>
							</div>
						);

					return (
						<div
							className={`placeholder-file ${
								touched && error ? "has-error" : ""
							}`}
						>
							<div className="icon">
								<FileAttachmentIcon />
							</div>
							<div>
								<div style={{ marginBottom: 6, marginTop: -4 }}>
									<span className="link">Upload file</span> or drag and drop
								</div>
								<div>Pdf, Docx, Xls (max. {bytesToSize(CV_SIZE_LIMIT)})</div>
							</div>
						</div>
					);
				}}
				text={{
					label: "Upload CV",
					labelWithAsterisk: true
				}}
				preSignedUrl={PRE_SIGNED_URL_OFFLINE}
				preSignedData={{ id: this.props.uuid }}
				sizeLimit={CV_SIZE_LIMIT}
			/>
			{!!(touched && error) && (
				<div className="help-block inline-error">{error}</div>
			)}
		</div>
	);

	signupForm = () => {
		const { submitErrors, submitFailed, type } = this.props;
		const { apiResponse, captchaFailed, captchaResponse } = this.state;
		const isPermanent = type === PERMANENT;
		const isFreelancer = type === FREELANCER;
		const isTalent = isPermanent || isFreelancer;
		const isConsultant = type === CONSULTANCY;
		return (
			<div>
				{!!(isFreelancer || isPermanent) && (
					<UploaderContainer>{this.renderCVField()}</UploaderContainer>
				)}
				<Flex sx={{ gap: 20 }}>
					<Box width={1 / 2} className="form-field">
						<Field
							placeholder="Enter your name"
							type="text"
							name="first_name"
							label="First name"
							labelWithAsterisk
							component={FormField}
						/>
					</Box>
					<Box width={1 / 2} className="form-field">
						<Field
							placeholder="Enter your last name"
							type="text"
							name="last_name"
							label="Last name"
							labelWithAsterisk
							component={FormField}
						/>
					</Box>
				</Flex>
				<Flex sx={{ gap: 20 }}>
					<Box width={1 / 2} className="form-field">
						<Field
							placeholder="000 00 00 00"
							name="phone"
							label="Phone number"
							labelWithAsterisk
							component={PhoneNumberField}
							normalize={value => {
								return value.formattedValue;
							}}
						/>
					</Box>
					<Box width={1 / 2} className="form-field">
						<Field
							placeholder="Enter your email"
							type="text"
							name="email"
							label="Email"
							labelWithAsterisk
							component={FormField}
							normalize={this.normalizeEmail}
							hasError={
								has(submitErrors, "email_already_exist") &&
								has(submitErrors, "email")
							}
							errorComponent={
								<div className="help-block email-exists">
									An account for this email already exists.{" "}
									<Link to={getSignInRoute()}>Log in</Link> Or{" "}
									<span
										role="button"
										className="claim-btn"
										onClick={this.handleClaimAccount}
									>
										Claim this email.
									</span>
								</div>
							}
						/>
					</Box>
				</Flex>
				{!isPermanent && (
					<>
						<div className="form-field">
							<Field
								placeholder="••••••••"
								type="password"
								name="password"
								label="Password"
								labelWithAsterisk
								component={FormField}
							/>
						</div>
						<div className="description" style={{ marginBottom: 24 }}>
							Your password must include at least one number, one uppercase
							letter, and one special character.
						</div>

						<AcceptPrivacyPolicy
							isConsultant={isConsultant}
							isFreelancer={isFreelancer}
						/>
					</>
				)}
				{isPermanent && (
					<AcceptPrivacyPolicy
						isConsultant={isConsultant}
						isPermanent={isPermanent}
					/>
				)}

				{isTalent && (
					<>
						<ReCaptchaContainer isFreelancer={isFreelancer}>
							<ReCaptcha
								verifyCallback={this.verifyRecaptcha}
								action="login"
								apiResponse={apiResponse}
							/>
							{(submitFailed || captchaFailed) && !captchaResponse && (
								<div className="help-block inline-error">
									Please check the captcha field before advancing.
								</div>
							)}
						</ReCaptchaContainer>
					</>
				)}
			</div>
		);
	};

	verifyRecaptcha = result => {
		this.setState({ captchaResponse: result }, () => {
			AxiosHelper.post({
				url: RECAPTCHA_VERIFY_ENDPOINT,
				isLogin: true,
				data: { "recaptcha-response": result }
			}).then(result => {
				this.setState({ apiResponse: result });
				if (result && !result.success) {
					this.setState({ captchaFailed: false, captchaResponse: undefined });
				}
			});
		});
	};

	renderCVField = () => <Field name="cv_doc" component={this.renderUploader} />;

	render() {
		const { type, handleSubmit, isLoading } = this.props;
		const isPermanent = type === PERMANENT;
		const { acceptPolicy } = this.state;

		return (
			<div>
				<h3 className="heading">
					Register as a {capitalize(isPermanent ? "candidate" : type)}
				</h3>
				<div className="description">
					Create your account, enter your information to get started.
				</div>
				<form className="form" onSubmit={handleSubmit(this.handleSignUp)}>
					{this.signupForm()}
					<div style={{ height: 20 }}></div>
					<button
						onClick={this.hasAcceptedTermsAndPolicy}
						disabled={isLoading}
						className="form-btn"
					>
						{isLoading ? (
							<AnimatedLoaderIcon width={24} />
						) : isPermanent ? (
							"Create your profile"
						) : (
							"Next"
						)}
					</button>
					<div className="description sign-up-cta">
						{`Already have an account?`}{" "}
						<Link className="link" to="/sign-in">
							Log in
						</Link>
					</div>
					{acceptPolicy && (
						<AcceptTermsAndPolicyModal
							onModalToggle={this.policyCloseHandler}
							acceptHandler={this.acceptPolicyHandler}
							title="Management policy of personal data"
							mode="policy"
						/>
					)}
					{isProd && (
						<noscript>
							<img
								src={linkedinInsight.track("3411970")}
								height="1"
								width="1"
								style="display:none;"
								alt=""
							/>
						</noscript>
					)}
				</form>
			</div>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.accept_policies) {
		errors.accept_policies = "You must accept the Privacy Policy.";
	}
	if (!formProps.accept_process) {
		errors.accept_process = "You must accept the process.";
	}
	if (!formProps.accept_condition) {
		errors.accept_condition = "You must accept this box";
	}
	if (!formProps.first_name) {
		errors.first_name = "First name is required.";
	} else if (!ONLY_CHARS.test(formProps.first_name)) {
		errors.first_name = "First name accept only letters.";
	}
	if (!formProps.last_name) {
		errors.last_name = "Last name is required.";
	} else if (!ONLY_CHARS.test(formProps.last_name)) {
		errors.last_name = "Last name accept only letters.";
	}
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Email is required.";
	} else if (formProps.email && !emailRegex.test(formProps.email)) {
		errors.email = "Email is invalid.";
	}
	if (
		!formProps.phone ||
		!parsePhoneNumberFromString(formProps.phone)?.nationalNumber
	) {
		errors.phone = "Please enter your phone number.";
	}
	if (!formProps.cv_doc) {
		errors.cv_doc = "Please choose your CV file.";
	}

	errors.password = checkPasswordRules(formProps.password);

	return errors;
};

SignUpFirstStep.propTypes = {
	onSuccess: PropTypes.func,
	first_name: PropTypes.string,
	last_name: PropTypes.string,
	type: PropTypes.string,
	invite_email: PropTypes.string
};

const selector = formValueSelector("signup");
const mapStateToProps = state => {
	return {
		acceptedTerms: selector(state, "accept_terms"),
		acceptedPolices: selector(state, "accept_policies"),
		firstname: selector(state, "first_name"),
		lastname: selector(state, "last_name"),
		phone: selector(state, "phone"),
		email: selector(state, "email"),
		password: selector(state, "password"),
		submitErrors: getFormSubmitErrors("signup")(state)
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "signup",
		validate,
		touchOnBlur: false,
		onSubmitFail: errors => scrollToFirstError(errors),
		destroyOnUnmount: false
	})
)(SignUpFirstStep);
