import React from "react";
import { Link } from "react-router";
import { Field } from "redux-form";
import CheckBoxInput from "common/CheckBoxInput";
import { getTermsAndConditionsRoute } from "common/termsAndConditions/components/TermsAndConditionsContainer";
import { getPolicyRoute } from "common/privacyPolicy/components/PolicyContainer";

function AcceptPrivacyPolicy({ isFreelancer, isPermanent, isConsultant }) {
	return (
		<div className="accept-privacy">
			<Field type="checkbox" name="accept_policies" component={CheckBoxInput}>
				<div className="description">
					{isPermanent || isFreelancer
						? "By submitting your email address, CV, and any other personal information to this website, you consent to such information being collected, held, used and disclosed in accordance with our "
						: "By submitting your email address, and any other personal information to this website, you consent to such information being collected, held, used and disclosed inaccordance with our "}
					<Link className="link" target="_blank" to={getPolicyRoute()}>
						Privacy Policy
					</Link>{" "}
					and our{" "}
					<Link
						className="link"
						target="_blank"
						to={getTermsAndConditionsRoute()}
					>
						Website T&Cs.
					</Link>
					<br />
					{isFreelancer || isPermanent ? (
						<div style={{ marginTop: 12 }}>
							Any questions about your privacy?{" "}
							<Link to="/ask-question" className="link">
								Contact us
							</Link>
						</div>
					) : null}
				</div>
			</Field>

			{isConsultant && (
				<Field
					type="checkbox"
					name="accept_condition"
					component={CheckBoxInput}
				>
					<div className="description">
						By ticking this box, you confirm that you have obtained the consent
						from any third parties (consultants) for their personal information
						to be submitted to Wiggli or are able to rely on another lawful
						basis for uploading their personal data
					</div>
				</Field>
			)}
		</div>
	);
}
export default AcceptPrivacyPolicy;
