/* eslint-disable react/display-name */
//TODO move constants HISTORY_TYPE & HISTORY_TYPE_LABEL to root
import React from "react";
import {
	VIDEO_CONFERENCE,
	COMPANY_ADDRESS,
	SEARCH_ADDRESS,
	PHONE_SCREEN,
	REMOTE
} from "config";
import { formatDate, formatSalary, getEmploymentType } from "./helper";
import { JobOffersFilesMenu } from "../components/JobOffersFilesMenu";

export const BREADCRUMBS_ITEMS = [{ name: "Settings" }, { name: "GDPR" }];

export const PROFILE_TAB_ID = "PROFILE_TAB_ID";
export const EVALUATION_TAB_ID = "EVALUATION_TAB_ID";
export const PROCESSES_TAB_ID = "PROCESSES_TAB_ID";
export const INTERACTIONS_TAB_ID = "INTERACTIONS_TAB_ID";
export const NOTES_TAB_ID = "NOTES_TAB_ID";
export const FILES_TAB_ID = "FILES_TAB_ID";
export const NOT_FOUND = "N/A";

export const LOCATION_TYPE_LABELS = {
	[REMOTE]: "Video interview",
	[VIDEO_CONFERENCE]: "Video interview",
	[COMPANY_ADDRESS]: "Company Address",
	[SEARCH_ADDRESS]: "Another Address",
	[PHONE_SCREEN]: "Phone screening"
};

export const PAYMENTS_TIME_LABEL = {
	monthly: "month",
	yearly: "year",
	hourly: "hour"
};

export const TABS = [
	{
		id: PROFILE_TAB_ID,
		label: "Profiles"
	},
	{
		id: EVALUATION_TAB_ID,
		label: "Evaluations"
	},
	{
		id: PROCESSES_TAB_ID,
		label: "Processes"
	},
	{
		id: INTERACTIONS_TAB_ID,
		label: "Interactions"
	},
	{ id: NOTES_TAB_ID, label: "Notes" },
	{ id: FILES_TAB_ID, label: "Files" }
];
export const HISTORY_TYPE = {
	offer_accepted: "#065F46",
	offer_sent: "#92400E",
	offer_refused: "#991B1B",
	offer_cancelled: "#991B1B",
	poke: "#1F2937",
	poke_expired: "#991B1B",
	poke_cancelled: "#991B1B",
	shortlist: "#1F2937",
	application: "#065F46",
	Referred: "#6C17D8",
	application_rejected: "#991B1B",
	application_cancelled: "#991B1B",
	interest_declined: "#991B1B",
	interest_confirmed: "#065F46",
	interview_done: "#065F46",
	hired: "#065F46",
	vacancy_sent: "#92400E",
	internal_note: "#1F2937",
	interview_sent: "#065F46",
	interview_expired: "#991B1B",
	interview_planned: "#92400E",
	interview_refused: "#991B1B",
	interview_cancelled: "#991B1B",
	interview_declined: "#991B1B",
	interview_pending: "#92400E"
};

export const HISTORY_TYPE_LABEL = {
	offer_accepted: "Offer Accepted",
	offer_sent: "Offer Sent",
	offer_refused: "Offer Refused",
	offer_cancelled: "Offer Cancelled",
	poke: "Poke",
	poke_expired: "Poke Expired",
	poke_cancelled: "Poke Cancelled",
	shortlist: "Sourced",
	application: "Application",
	Referred: "Referred",
	application_rejected: "Application Rejected",
	application_cancelled: "Application Cancelled",
	interest_declined: "Interest Declined",
	interest_confirmed: "Interest Confirmed",
	interview_done: "Interview Done",
	hired: "Hired",
	vacancy_sent: "Vacancy Sent",
	internal_note: "Internal Note",
	interview_sent: "Interview Sent",
	interview_expired: "Interview Overdue",
	interview_planned: "Interview Planned",
	interview_refused: "Interview Refused",
	interview_cancelled: "Interview Cancelled",
	interview_declined: "Interview Declined",
	interview_pending: "Interview Pending"
};

export const INTERVIEWS_HISTORY_TABLE_SCHEMA = [
	{
		id: "created_at",
		title: "Created on",
		Cell: ({ value }) => {
			if (!value) return "N/A";
			return formatDate(value);
		}
	},
	{
		id: "attendees_users",
		title: "Attendees",
		Cell: ({ value }) => {
			if (!value) return "N/A";
			const { first_name, last_name } = value[0];
			const fullName = `${first_name} ${last_name}`;
			if (value.length === 1) {
				return fullName;
			} else {
				return `${fullName}, +${value.length - 1}`;
			}
		}
	},
	{
		id: "location_type",
		title: "Location",
		Cell: ({ value }) => {
			if (!value) return "N/A";
			return LOCATION_TYPE_LABELS[value];
		}
	},
	{
		id: "new_interview_start_date",
		title: "Date & time",
		Cell: ({ value }) => {
			if (!value) return "N/A";
			return formatDate(value);
		}
	},
	{ id: "status", title: "Status" }
];

export const jobOffersTableSchema = [
	{
		id: "start_date",
		title: "Created on",
		Cell: ({ value }) => formatDate(value)
	},
	{
		id: "contract_type",
		title: "Contract type",
		Cell: ({ value }) => getEmploymentType(value)
	},
	{
		id: "amount",
		title: "Salary",
		Cell: ({ value, row: { payment_time, payment_type, symbol } }) => {
			return `${formatSalary(value)} ${symbol}/${
				PAYMENTS_TIME_LABEL[payment_time]
			} (${payment_type})`;
		}
	},
	{
		id: "status",
		title: "Status"
	},
	{
		id: "files",
		title: "Files",
		Cell: ({ value }) =>
			value?.length > 0 ? <JobOffersFilesMenu files={value} /> : 0
	}
];

export const STAGES = [
	{
		value: "application",
		label: "Application",
		color: "#1F2937",
		background: "#F3F4F6"
	},
	{
		value: "interview",
		label: "Interview",
		color: "#1F2937",
		background: "#F3F4F6"
	},
	{
		value: "internal_review",
		label: "Internal review",
		color: "#92400E",
		background: "#FEF3C7"
	},
	{
		value: "shortlist",
		label: "Shortlist",
		color: "#92400E",
		background: "#FEF3C7"
	},
	{
		value: "phone_screening",
		label: "Phone screening",
		color: "#92400E",
		background: "#FEF3C7"
	},
	{
		value: "test_assessment",
		label: "Test/Assessment",
		color: "#1E40AF",
		background: "#DBEAFE"
	},
	{ value: "offer", label: "Offer", color: "#1E40AF", background: "#DBEAFE" },
	{ value: "hired", label: "Hired", color: "#1E40AF", background: "#DBEAFE" },
	{ value: "custom", label: "Custom", color: "#1E40AF", background: "#DBEAFE" }
];
