import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { reduxForm, Field, change } from "redux-form";
import { emailRegex } from "config";
import FormField from "common/FormField";
import RecoverSuccess from "./RecoverSuccess";
import AxiosHelper from "config/axios-helper";
import { RECOVER_PASSWORD } from "config/api-endpoints";
import { searchParser } from "config/helpers";
import AuthenticationLayout from "../Layout";
import Container from "../Container";
import BackButton from "../BackButton";
import SubContainer from "../SubContainer";
import { Link } from "react-router";
import Header from "../Header";
import { Box, Flex } from "rebass";
import { ReactComponent as EmailIcon } from "static/icons/email.svg";
import { useSelector } from "react-redux";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";

const formName = "recover-password";

export const getRecoverPasswordRoute = () => {
	return "/recover-password";
};

const _recoverPassword = (
	{ email },
	vacancy_id,
	source_id,
	campaign_id,
	version
) => {
	return AxiosHelper.post({
		url: RECOVER_PASSWORD,
		data: { email, vacancy_id, source_id, campaign_id, version },
		isLogin: true,
		loadingText: "Resetting password..."
	});
};

const normalizeEmail = value => {
	return value.toLowerCase();
};

var RecoverPassword = ({ handleSubmit, submitSucceeded, dispatch }) => {
	const {
		vacancy_id,
		source_id,
		campaign_id,
		applicantEmail,
		version
	} = searchParser();

	useEffect(() => {
		if (applicantEmail)
			return dispatch(change(formName, "email", applicantEmail));
	}, []);

	const inAction = useSelector(state => state.app.axiosHelper.inAction);

	return (
		<AuthenticationLayout>
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>Recover password</title>
			</Helmet>
			<Header>
				<Flex height={"100%"} alignItems={"center"}>
					<BackButton />
				</Flex>
			</Header>
			<Box flexGrow={1} display={"flex"} alignItems={"center"}>
				<Container>
					<SubContainer>
						<h3 className="heading">Reset Password</h3>
						{submitSucceeded ? (
							<RecoverSuccess isFromVacancy={Boolean(vacancy_id)} />
						) : (
							<>
								<p className="description">
									{`Enter the e-mail address you used to register, we'll send
										you an e-mail to reset your password${
											vacancy_id
												? " and by following this process your application will be done."
												: "."
										}`}
									If needed, don&#39;t hesitate to{" "}
									<Link className="link" to="/ask-question">
										contact our support team!
									</Link>
								</p>
								<form
									className="form"
									onSubmit={handleSubmit(values =>
										_recoverPassword(
											values,
											vacancy_id,
											source_id,
											campaign_id,
											version
										)
									)}
								>
									<div className="form-field">
										<Field
											label="Email"
											classes="inner-icon left-icon"
											position="bottom"
											svgPrefixIcon={
												<span className="icon">
													<EmailIcon />
												</span>
											}
											placeholder="name@example.com"
											type="text"
											name="email"
											component={FormField}
											normalize={normalizeEmail}
										/>
									</div>
									<button className="form-btn" disabled={inAction}>
										{inAction ? (
											<AnimatedLoaderIcon width={24} />
										) : (
											"Reset password"
										)}
									</button>
									<div style={{ height: 20 }}></div>
									<p className="description" style={{ textAlign: "center" }}>
										Don’t have an account?
										<Link to="/sign-up" className="link">
											&nbsp;Sign up
										</Link>
									</p>
								</form>
							</>
						)}
					</SubContainer>
				</Container>
			</Box>
		</AuthenticationLayout>
	);
};

const validate = formProps => {
	const errors = {};
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Email is required.";
	} else if (formProps.email && !emailRegex.test(formProps.email)) {
		errors.email = "Email is invalid.";
	}
	return errors;
};

RecoverPassword = reduxForm({
	form: formName,
	validate,
	touchOnBlur: false
})(RecoverPassword);

export default RecoverPassword;
