import AxiosHelper from "config/axios-helper";
import { createToken } from "modules/user/actions/authActions";
import {
	FETCH_PERMANENT_PROFILE,
	FETCH_MY_JOBS_SUCCESS,
	FETCH_POKE_DATA,
	RESET_CANDIDATE_PROFILE,
	DISPLAY_INTERVIEW_INVITATION,
	DISPLAY_PROPOSE_MODAL,
	TOGGLE_MODAL_REMOVE_INTEREST,
	TOGGLE_CANCEL_INTERVIEW_MODAL,
	SET_LIST_ATTENDEES,
	TOGGLE_CANCEL_APPLICATION_MODAL,
	UPDATE_UPLOADING_CV,
	UPDATE_UPLOADING_CV_ERROR
} from "./types";
import { CLEAN_STATE } from "../../vacancy/actions/types";
import {
	CHOOSE_PASSWORD,
	VIEW_CANDIDATE_ENDPOINT,
	FETCH_POKE_ENDPOINT,
	CHANGE_POKE_STATUS_ENDPOINT,
	LIST_POKES_ENDPOINT,
	ACCEPT_INTERVIEW_ENDPOINT,
	EDIT_INTERVIEW_ENDPOINT,
	REMOVE_INTEREST_ENDPOINT,
	CANCEL_INTERVIEW,
	FILTER_SETTINGS_ENDPOINT,
	CANCEL_APPLICATION_ENDPOINT
} from "config/api-endpoints";
import { FREELANCER, CANCELLED } from "config";

export function choosePassword(
	accountPayload,
	signInPayload,
	shouldRedirect = true,
	applyParams
) {
	const redirectTo = accountPayload.type === FREELANCER;

	return AxiosHelper.__post({
		isLogin: true,
		url: CHOOSE_PASSWORD,
		data: accountPayload,
		loadingText: "Signing in...",
		next: () =>
			createToken(
				signInPayload,
				shouldRedirect,
				redirectTo ? "/company/congratulation" : redirectTo,
				null,
				applyParams
			)
	});
}

export function fetchPoke(payload) {
	return AxiosHelper.__post({
		url: FETCH_POKE_ENDPOINT,
		data: payload,
		next: data => {
			return {
				type: FETCH_POKE_DATA,
				data
			};
		}
	});
}

export function fetchCandidateProfile(payload) {
	return AxiosHelper.__post({
		url: VIEW_CANDIDATE_ENDPOINT,
		data: payload,
		next: data => {
			return {
				type: FETCH_PERMANENT_PROFILE,
				data
			};
		}
	});
}

export function changePokeStatus(payload) {
	return AxiosHelper.__post({
		url: CHANGE_POKE_STATUS_ENDPOINT,
		data: payload,
		isModal: true,
		next: () => fetchPokeData(payload.id)
	});
}

export function fetchPokeData(id) {
	return AxiosHelper.__post({
		url: FETCH_POKE_ENDPOINT,
		data: { id: id },
		next: data => dispatch => {
			if (
				data.has_interview &&
				data.interview &&
				data.interview.status !== CANCELLED
			) {
				dispatch({ type: FETCH_POKE_DATA, data });
				return dispatch(openInterviewInvitation(true));
			}
			return dispatch({ type: FETCH_POKE_DATA, data });
		}
	});
}

export function fetchMyJobList(payload) {
	return AxiosHelper.__post({
		url: LIST_POKES_ENDPOINT,
		data: payload,
		REQUEST_SUCCESS: FETCH_MY_JOBS_SUCCESS
	});
}

export function acceptInterview(
	interviewId,
	tokenInvitation,
	pokeId,
	isConfirmed,
	nextAction
) {
	return AxiosHelper.__post({
		url: ACCEPT_INTERVIEW_ENDPOINT,
		isModal: true,
		data: { interview_id: interviewId, token_invitation_id: tokenInvitation },
		toastMessage: "Interview successfully accepted",
		next: () => dispatch => {
			nextAction && nextAction();
			dispatch(openInterviewInvitation(false));
			return dispatch(fetchPoke({ id: pokeId }));
		}
	});
}

export function editInterview(_id, data, pokeId) {
	return AxiosHelper.__post({
		url: EDIT_INTERVIEW_ENDPOINT,
		isModal: true,
		data: { ...data, interview_id: _id },
		toastMessage: "Interview successfully modified",
		next: () => dispatch => {
			dispatch(displayProposeModal(false));
			return dispatch(fetchPoke({ id: pokeId }));
		}
	});
}

export function resetCandidateProfile() {
	return {
		type: RESET_CANDIDATE_PROFILE
	};
}

export function openInterviewInvitation(canDisplayInvitation) {
	return {
		type: DISPLAY_INTERVIEW_INVITATION,
		canDisplayInvitation
	};
}

export function displayProposeModal(canDisplayPropose) {
	return {
		type: DISPLAY_PROPOSE_MODAL,
		canDisplayPropose
	};
}
export function removeInterest(data) {
	return AxiosHelper.__post({
		data,
		url: REMOVE_INTEREST_ENDPOINT,
		isModal: true,
		loadingText: "Removing interest ...",
		next: () => fetchPoke({ id: data.id }),
		toastMessage: "Interest successfully removed"
	});
}

export function cancelApplication(data) {
	return AxiosHelper.__post({
		data,
		url: CANCEL_APPLICATION_ENDPOINT,
		isModal: true,
		loadingText: "Cancelling application...",
		next: () => fetchPoke({ id: data.application_ids[0] }),
		toastMessage: "Application successfully cancelled"
	});
}

export function toggleModalRemoveInterest(modal = null) {
	return {
		type: TOGGLE_MODAL_REMOVE_INTEREST,
		modal
	};
}

export function cancelInterview(data) {
	return AxiosHelper.__post({
		data,
		url: CANCEL_INTERVIEW,
		isModal: true,
		loadingText: "Canceling the interview...",
		next: () => fetchPoke({ id: data.poke_id }),
		toastMessage: "Interview successfully cancelled"
	});
}

export function toggleCancelInterviewModal(modal = null) {
	return {
		type: TOGGLE_CANCEL_INTERVIEW_MODAL,
		modal
	};
}

export function toggleCancelApplicationModal(modal = null) {
	return {
		type: TOGGLE_CANCEL_APPLICATION_MODAL,
		modal
	};
}

export function getListAttendees(payload = { tag: "internal_user_note" }) {
	return AxiosHelper.__post({
		url: FILTER_SETTINGS_ENDPOINT,
		data: payload,
		REQUEST_SUCCESS: SET_LIST_ATTENDEES
	});
}

export function cleanState() {
	return {
		type: CLEAN_STATE
	};
}

export function setIsUploadingCv(data) {
	return {
		type: UPDATE_UPLOADING_CV,
		data
	};
}

export function setUploadCvError(data) {
	return {
		type: UPDATE_UPLOADING_CV_ERROR,
		data
	};
}
