import React, { useState } from "react";
import styles from "./add-timesheet-entry-form.module.scss";
import toaster from "common/Toaster";
import {
	FIFTY,
	HUNDRED,
	ONE_HUNDRED_AND_FIFTY,
	ONE_HUNDRED_AND_TWENTY_FIVE,
	TWO_HUNDRED
} from "config";
import { useAddEntryForm } from "./useAddEntryForm";
import { Controller } from "react-hook-form";
import { Button } from "common/Button";
import { AddTimesheetEntriesCard } from "modules/timesheets/components/AddTimesheetEntriesDrawerBody/AddTimesheetEntriesCard";
import { ReactComponent as ClockIcon } from "static/icons/clock.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-grey.svg";
import { ReactComponent as MoneyIcon } from "static/icons/money.svg";
import { ReactComponent as ArrowIcon } from "static/icons/arrow-1.svg";
import { ReactComponent as PuzzleIcon } from "static/icons/puzzle.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-2.svg";
import { ReactComponent as DocumentIcon } from "static/icons/document-2.svg";
import { ReactComponent as TagIcon } from "static/icons/tag-2.svg";
import cx from "classnames";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import FieldError from "common/FieldError/FieldError";
import { AddTimesheetEntriesTimeInput } from "../AddTimesheetEntriesTimeInput";
import { TYPE_PDF } from "config";
import NewCurrencyField from "common/NewCurrencyField";
import { generateId, renderError } from "config/helpers";
import useEntries from "modules/timesheets/hooks/useEntries";
import { TYPE_PJPEG, TYPE_JPEG, TYPE_PNG } from "config";
import { AddTimesheetInfoIcon } from "../AddTimesheetInfoIcon";
import {
	featureTimesheetTagsSelector,
	timesheetTagsProjectNameSelector,
	timesheetTagsSelectSelector
} from "modules/timesheets/timesheetSelector";
import { connect } from "react-redux";
import { TIMESHEET_TAGS_STATUS } from "config";
import { AddTimesheetEntriesDatePicker } from "../AddTimesheetEntriesDatePicker";
import { useComputeTimesheet } from "modules/timesheets/api/useComputeTimesheet";
import {
	formatWorkTimesList,
	getComputeAmountPayload
} from "modules/timesheets/utils/helper";
import _get from "lodash/get";
import { Uploader } from "common/react-hook-form-components/uploader/coumpound_uploader";
import { bytesToSize } from "config/helpers";
import { SUPPORTED_SIZE } from "config";

const TYPES_LIST = [
	{
		id: "worktime",
		label: "Worktime"
	},
	{ id: "overtime", label: "Overtime" },
	{
		id: "expense",
		label: "Expense"
	}
];

const AddTimesheetEntryForm = ({
	onSave,
	tagOptions,
	projectName,
	featureTimesheetTags,
	onCancel,
	timesheet
}) => {
	const isTagsUsed =
		featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
		tagOptions?.length > 0;

	const [computeAmounts] = useComputeTimesheet();

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors }
	} = useAddEntryForm(isTagsUsed);
	const { addEntries } = useEntries();

	const [receiptFile, setReceiptFile] = useState([]);

	const types = TYPES_LIST.filter(item => {
		if (item.id === "overtime") {
			return _get(timesheet, `data.reporting_settings.allow_overtime`, false);
		} else {
			return item;
		}
	}).map(item => <Item key={item.id}>{item.label}</Item>);

	const getTypeById = chosenId => {
		return TYPES_LIST.find(({ id }) => id === chosenId);
	};

	const onTypeSelectionChange = (key, onChange) => {
		const value = {
			label: getTypeById(key)?.label ?? "",
			value: key
		};

		onChange(value);
	};

	const onTagInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("rate")?.value
		};

		onChange(valueObject);
	};

	const getTagById = chosenId => {
		return tagOptions.find(({ value }) => value === chosenId);
	};

	const onTagSelectionChange = (key, onChange) => {
		const value = {
			label: getTagById(key)?.label ?? "",
			value: key
		};

		onChange(value);
	};

	const onSubmit = values => {
		const isExpense = values.type.value === "expense";

		if (!isExpense) {
			const entries = values.date.map(() => values);
			const payload = formatWorkTimesList(entries, false);
			computeAmounts(getComputeAmountPayload(payload, timesheet?.data?._id), {
				onSuccess: data => {
					const newEntries = values.date.map((date, i) => {
						const field = isExpense ? "expenses" : "worktimes";
						return {
							...values,
							_id: generateId(),
							date,
							calculatedAmount: data.dispute_items[field].added[i].amount
						};
					});
					addEntries(timesheet?.data?._id, newEntries);
					onSave();
				},
				onError: error => {
					renderError(error);
				}
			});
		} else {
			addEntries(timesheet?.data?._id, [
				{ ...values, date: values.date[0], _id: generateId() }
			]);
			onSave();
		}
	};

	const onDropRejected = fileRejections => {
		const REJECTIONS = {
			"file-invalid-type": "Invalid file type.",
			"file-too-large": `The file size should not exceed ${bytesToSize(
				SUPPORTED_SIZE
			)}.`
		};
		if (fileRejections.length) {
			fileRejections.forEach(({ errors }) => {
				errors.forEach(error =>
					toaster.danger(REJECTIONS[error.code], {
						duration: 8,
						id: error.code
					})
				);
			});
		}
	};
	const onDrop = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			setReceiptFile(acceptedFiles);
		}
	};

	return (
		<AddTimesheetEntriesCard className={styles.container}>
			<div className={styles.field}>
				<div className={styles.labelContainer}>
					<PuzzleIcon />
					<label className={styles.label}>Entry type</label>
					<div className={styles.asterisk}>*</div>
				</div>
				<Controller
					name="type"
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<ComboBox
								placeholder="Select type"
								inputRootClassName={styles.comboxBoxInput}
								inputValue={value?.label}
								selectedKey={value?.value}
								onSelectionChange={key => onTypeSelectionChange(key, onChange)}
								isReadonlyInput
							>
								{types}
							</ComboBox>
						);
					}}
				/>
				{errors?.type && <FieldError message={errors?.type.message} />}
			</div>

			<div className={styles.separator} />

			<div className={styles.fieldsContainer}>
				<div className={styles.calendarField}>
					<div className={styles.calendarLabelContainer}>
						<CalendarIcon className={styles.dateIcon} height={20} width={20} />
						<label className={styles.label}>Date</label>
						<div className={styles.asterisk}>*</div>
					</div>
					<div className={styles.inputError}>
						<Controller
							control={control}
							name="date"
							render={({ field: { onChange } }) => {
								return (
									<AddTimesheetEntriesDatePicker
										onChange={onChange}
										isMultiple={watch("type").value !== "expense"}
									/>
								);
							}}
						/>
						{errors?.date && <FieldError message={errors?.date.message} />}
					</div>
				</div>

				{["worktime", "overtime"].includes(watch("type")?.value) && (
					<>
						<div className={styles.field}>
							<div className={styles.labelContainer}>
								<ClockIcon
									className={styles.clockIcon}
									height={20}
									width={20}
								/>
								<label className={styles.label}>Amount of time</label>
								<div className={styles.asterisk}>*</div>
							</div>
							<div className={styles.inputError}>
								<AddTimesheetEntriesTimeInput
									control={control}
									watch={watch}
									timesheet={timesheet}
									setValue={setValue}
								/>
								{errors?.time && <FieldError message={errors?.time.message} />}
							</div>
						</div>
						<div className={styles.field}>
							<div className={styles.labelContainer}>
								<ArrowIcon className={styles.rateIcon} />
								<label className={styles.label}>Rate</label>
							</div>
							<Controller
								control={control}
								name="rate"
								render={({ field: { onChange, value } }) => {
									return (
										<div className={styles.rates}>
											{[
												FIFTY,
												HUNDRED,
												ONE_HUNDRED_AND_TWENTY_FIVE,
												ONE_HUNDRED_AND_FIFTY,
												TWO_HUNDRED
											].map(rate => {
												return (
													<button
														key={rate}
														className={cx(styles.rateButton, {
															[styles.selectedRate]: value === rate
														})}
														onClick={() => onChange(rate)}
													>
														{rate}%
													</button>
												);
											})}
										</div>
									);
								}}
							/>
						</div>
					</>
				)}

				{watch("type")?.value === "expense" && (
					<>
						<div className={styles.field}>
							<div className={styles.labelContainer}>
								<DocumentIcon />
								<label className={styles.label}>Receipt</label>
							</div>

							<Controller
								control={control}
								name="receipt"
								render={({ field: { onChange } }) => (
									<div style={{ minWidth: "410px", maxWidth: "410px" }}>
										<Uploader>
											{receiptFile.length === 0 && (
												<Uploader.Input
													onDrop={onDrop}
													multiple={false}
													onDropRejected={onDropRejected}
													maxFiles={1}
													isReadOnly={false}
													filesTypesDescription={"PDF, JPEG, PNG"}
													accept={[TYPE_PDF, TYPE_JPEG, TYPE_PNG, TYPE_PJPEG]}
													noClick={false}
												/>
											)}
											{receiptFile.map((acceptedFile, index) => (
												<Uploader.Progress
													key={index}
													index={index}
													file={acceptedFile}
													isExtracting={false}
													setFiles={setReceiptFile}
													onUrlChange={val => onChange(val[0]?.file_name)}
												/>
											))}
										</Uploader>
									</div>
								)}
							/>
						</div>

						<div className={styles.field}>
							<div className={styles.labelContainer}>
								<MoneyIcon />
								<label className={styles.label}>Amount</label>
								<div className={styles.asterisk}>*</div>
							</div>
							<div className={styles.inputError}>
								<Controller
									control={control}
									name="amount"
									render={({ field: { value, onChange } }) => (
										<NewCurrencyField
											rootClassName={styles.amount}
											className={styles.rateInput}
											input={{ value, onChange }}
										/>
									)}
								/>
								{errors?.amount && (
									<FieldError message={errors?.amount.message} />
								)}
							</div>
						</div>
					</>
				)}

				{isTagsUsed && (
					<div className={styles.field}>
						<div className={styles.labelContainer}>
							<TagIcon />
							<label className={styles.label}>{projectName || "TAG"}</label>
							<div className={styles.asterisk}>*</div>
							<AddTimesheetInfoIcon>{`Each timesheet entry should be related to a ${(
								projectName || "tag"
							).toUpperCase()}.`}</AddTimesheetInfoIcon>
						</div>
						<div className={styles.inputError}>
							<Controller
								control={control}
								name="tag"
								render={({ field: { value, onChange } }) => (
									<ComboBox
										placeholder="Select tag"
										inputRootClassName={styles.comboxBoxInput}
										inputValue={value?.label}
										selectedKey={value?.value}
										onSelectionChange={key =>
											onTagSelectionChange(key, onChange)
										}
										onInputChange={value => onTagInputChange(value, onChange)}
										onIn
									>
										{tagOptions.map(({ label, value }) => {
											return <Item key={value}>{label}</Item>;
										})}
									</ComboBox>
								)}
							/>
							{errors?.tag && <FieldError message={errors?.tag.message} />}
						</div>
					</div>
				)}

				<div className={styles.descriptionContainer}>
					<div className={styles.labelContainer}>
						<CommentIcon />
						<label className={styles.label}>Description</label>
						<div className={styles.asterisk}>*</div>
					</div>
					<div className={styles.inputError}>
						<Controller
							control={control}
							name="comment"
							render={({ field: { value, onChange } }) => (
								<input
									onChange={onChange}
									value={value}
									type="text"
									className={styles.comment}
									placeholder="Describe what you did"
								/>
							)}
						/>
						{errors?.comment && (
							<FieldError message={errors?.comment.message} />
						)}
					</div>
				</div>
			</div>

			<div className={styles.footer}>
				<Button
					className={styles.addButton}
					onClick={handleSubmit(onSubmit)}
					text="Add"
				/>
				<Button
					className={styles.cancelButton}
					variant="outlined"
					text="Cancel"
					onClick={onCancel}
				/>
			</div>
		</AddTimesheetEntriesCard>
	);
};

const mapStateToProps = state => ({
	tagOptions: timesheetTagsSelectSelector(state),
	projectName: timesheetTagsProjectNameSelector(state),
	featureTimesheetTags: featureTimesheetTagsSelector(state),
	timesheet: state.timesheet
});

export default connect(mapStateToProps)(AddTimesheetEntryForm);
