import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";

import {
	SHORTLIST,
	INTERVIEW,
	OFFER,
	RECRUITMENT,
	SENT,
	CONFIRMED,
	DECLINED,
	EXPIRED,
	INACTIVE,
	CANCELLED,
	DONE
} from "config";
import { formatDate } from "common/Functions";
import {
	fetchPoke,
	changePokeStatus,
	openInterviewInvitation,
	acceptInterview,
	displayProposeModal,
	editInterview,
	toggleModalRemoveInterest,
	cleanState,
	toggleCancelApplicationModal
} from "../../redux/actions";
import { pokeSelector } from "../../redux/selectors";
import { modalRemoveInterestSelector } from "../../redux/selectors";
import { modalCancelInterviewSelector } from "../../redux/selectors";
import { modalCancelApplicationSelector } from "../../redux/selectors";
import PokeView from "./PokeView";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { toggleHelpModal } from "modules/app/actions/ListsActions";
import { toggleCancelInterviewModal } from "../../redux/actions";
import { getMyProfile } from "modules/user/actions/authActions";
export const getPokeViewContainerRoute = id => {
	return `/pokes/${id || `:id`}`;
};

export const getPokeViewContainerPermissions = () => {
	return {
		type: [
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

class PokeViewContainer extends React.Component {
	state = {
		confirmModal: false,
		declineModal: false
	};
	componentDidMount() {
		this.fetchPokeData();
		if (_get(this.props, "user.account_status") === INACTIVE) {
			this.props.getMyProfile();
		}
	}

	componentDidUpdate({ params }) {
		const {
			params: { id }
		} = this.props;
		if (id && params.id !== id) {
			this.fetchPokeData();
		}
	}

	componentWillUnmount() {
		const { cleanState } = this.props;
		cleanState();
	}

	fetchPokeData = () => {
		const {
			params: { id },
			fetchPoke
		} = this.props;
		fetchPoke({ id });
	};
	toggleConfirmModal = () => {
		this.setState({
			confirmModal: !this.state.confirmModal
		});
	};
	toggleDeclineModal = () => {
		this.setState({
			declineModal: !this.state.declineModal
		});
	};
	confirmInterest = () => {
		const {
			params: { id },
			changePokeStatus
		} = this.props;
		changePokeStatus({ id, status: CONFIRMED }).then(() => {
			this.toggleConfirmModal();
		});
	};
	declineInterest = () => {
		const {
			params: { id },
			changePokeStatus
		} = this.props;
		changePokeStatus({ id, status: DECLINED }).then(() => {
			this.toggleDeclineModal();
		});
	};

	onConfirmInterview = (isConfirmed, nextAction) => {
		const { poke } = this.props;
		const { interview } = poke;
		const { _id, token_invitation_id } = interview;
		const { acceptInterview } = this.props;
		acceptInterview(
			_id,
			token_invitation_id,
			poke._id,
			isConfirmed,
			nextAction
		);
	};

	ProposeOtherInterview = () => {
		const { openInterviewInvitation, displayProposeModal } = this.props;
		openInterviewInvitation(false);
		displayProposeModal(true);
	};

	closeProposeModal = () => {
		const { displayProposeModal } = this.props;
		displayProposeModal(false);
	};

	closeInvitationModal = () => {
		const { openInterviewInvitation } = this.props;
		openInterviewInvitation(false);
	};

	onEditInterview = data => {
		const { editInterview, poke } = this.props;
		const { interview } = poke;
		const { _id } = interview;
		editInterview(_id, data, poke._id);
	};

	getSteps() {
		const { poke } = this.props;
		const tooltip = `The offer features are being tested and will shortly be available on wiggli!
											In the meantime, feel free to contact the client directly.`;

		const pokeStatus = _get(poke, "status");
		const interviewStatus = _get(poke, "interview.status");
		const pastInterviews = _get(poke, "interviews");
		const interview = _get(poke, "interview");

		const defaultStatus = "wait";

		const getShortlistStatus = pokeStatus => {
			const errorStatus = [DECLINED, EXPIRED, CANCELLED];
			return errorStatus.includes(pokeStatus)
				? "error"
				: pokeStatus === SENT
				? "process"
				: "finish";
		};

		const getInterviewStatus = (
			pokeStatus,
			interviewStatus,
			pastInterviews,
			interview
		) => {
			const interviewErrorStatus = [EXPIRED, CANCELLED];
			let isPastInterviewDone;
			let isPastInterviewError;
			pastInterviews &&
				pastInterviews.map(interview => {
					if (interview.status === DONE) {
						isPastInterviewDone = true;
					} else if (interviewErrorStatus.includes(interviewStatus)) {
						isPastInterviewError = true;
					}
				});
			if (Array.isArray(interview)) {
				return "wait";
			}
			if (
				interviewErrorStatus.includes(interviewStatus) ||
				isPastInterviewError
			) {
				return "error";
			}
			if (interviewStatus === DONE || isPastInterviewDone) {
				return "finish";
			}
			return "process";
		};

		const steps = [
			{
				label: SHORTLIST,
				status: getShortlistStatus(pokeStatus)
			},
			{
				label: INTERVIEW,
				status: getInterviewStatus(
					pokeStatus,
					interviewStatus,
					pastInterviews,
					interview
				)
			},
			{
				label: OFFER,
				status: defaultStatus,
				tooltip
			},
			{
				label: RECRUITMENT,
				status: defaultStatus,
				tooltip
			}
		];

		return steps;
	}

	render() {
		const {
			poke,
			user: { account_status },
			toggleHelpModal,
			user,
			modalRemoveInterest,
			toggleModalRemoveInterest,
			modalCancelInterview,
			toggleCancelInterviewModal,
			toggleCancelApplicationModal,
			modalCancelApplication,
			location: { query, pathname }
		} = this.props;

		const {
			canDisplayInvitation,
			canDisplayPropose,
			interview,
			has_interview
		} = poke;

		return (
			<PokeView
				poke={poke}
				client={_get(poke, "client")}
				job={_get(poke, "job")}
				stepperStatus={_get(poke, "stepper_status")}
				steps={this.getSteps(poke)}
				pokeDate={formatDate(_get(poke, "created_at"))}
				status_changed_on={formatDate(_get(poke, "status_changed_on"))}
				status={_get(poke, "status")}
				confirmed={_get(poke, "confirmed")}
				confirmedAt={formatDate(_get(poke, "confirmed_at"))}
				visible_status={_get(poke, "visible_status")}
				job_status={_get(poke, "job_status")}
				confirmInterest={this.confirmInterest}
				declineInterest={this.declineInterest}
				toggleDeclineModal={this.toggleDeclineModal}
				toggleConfirmModal={this.toggleConfirmModal}
				displayConfirmModal={this.state.confirmModal}
				displayDeclineModal={this.state.declineModal}
				accountStatus={account_status}
				toggleHelpModal={toggleHelpModal}
				cancelData={_get(poke, "cancel_data")}
				canDisplayInvitation={canDisplayInvitation}
				confirmInterview={this.onConfirmInterview}
				ProposeOtherInterview={this.ProposeOtherInterview}
				closeInvitationModal={this.closeInvitationModal}
				canDisplayPropose={canDisplayPropose}
				interview={interview}
				hasInterview={has_interview}
				closeProposeModal={this.closeProposeModal}
				editInterview={this.onEditInterview}
				openInterviewInvitation={this.props.openInterviewInvitation}
				user={user}
				additionalComment={_get(poke, "additional_comment")}
				modalCancelInterview={modalCancelInterview}
				toggleCancelInterviewModal={toggleCancelInterviewModal}
				modalRemoveInterest={modalRemoveInterest}
				toggleModalRemoveInterest={toggleModalRemoveInterest}
				toggleCancelApplicationModal={toggleCancelApplicationModal}
				modalCancelApplication={modalCancelApplication}
				query={query}
				pathname={pathname}
				questionnaireData={poke?.questionnaire}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		poke: pokeSelector(state),
		user: onlineUserSelector(state),
		modalRemoveInterest: modalRemoveInterestSelector(state),
		modalCancelInterview: modalCancelInterviewSelector(state),
		modalCancelApplication: modalCancelApplicationSelector(state)
	};
};
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchPoke,
			changePokeStatus,
			toggleHelpModal,
			getMyProfile,
			openInterviewInvitation,
			acceptInterview,
			displayProposeModal,
			editInterview,
			toggleModalRemoveInterest,
			toggleCancelInterviewModal,
			cleanState,
			toggleCancelApplicationModal
		},
		dispatch
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(PokeViewContainer);
