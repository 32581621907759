import Portal from "common/Portal";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import {
	UPDATE_PROFILE_PERSONAL_INFO,
	CHECK_ACTIVATE_ACCOUNT
} from "config/api-endpoints";
import * as yup from "yup";
import { AddMailModalContainer, Backdrop } from "./styles";
import { ReactComponent as CloseButton } from "static/icons/close-button.svg";
import get from "lodash/get";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import { historyPush } from "config/helpers";
import { CONSULTANCY, CLIENT, FREELANCER, PERMANENT } from "config";

const updateProfilePersonalInfo = data => {
	return client(UPDATE_PROFILE_PERSONAL_INFO, {
		body: {
			...data,
			tag: "disable_refresh_notif"
		}
	});
};

const AddMailModal = ({
	closeModal,
	profileId,
	sendInterviewHandler,
	logInterviewHandler,
	activeCompany,
	labelKey = "interview",
	setShowAddMailModal,
	vacancyId,
	closeDrawer
}) => {
	const [isMergeProfiles, setIsMergeProfiles] = useState(false);
	const [isAlreadyShortlisted, setIsAlreadyShortlisted] = useState(false);
	const [applicationId, setApplicationId] = useState("");
	const [userId, setUserId] = useState("");

	const schema = yup.object().shape({
		email: yup
			.string()
			.email("This email is invalid")
			.required("Email is required")
	});

	const {
		handleSubmit,
		control,
		formState: {
			errors: { email: emailError }
		},
		setError,
		watch
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema),
		defaultValues: {
			email: ""
		}
	});

	let { email } = watch();

	const [mutate, { isLoading }] = useMutation(updateProfilePersonalInfo, {
		onSuccess: () => {
			sendInterviewHandler(true);
			setIsMergeProfiles(false);
			setShowAddMailModal && setShowAddMailModal(false);
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					get(errorMessage, name) &&
						toaster.danger(
							`${name} : ${
								typeof get(errorMessage, name) === "string"
									? get(errorMessage, name)
									: get(errorMessage, name)[0]
							}`
						);
				});
			} else {
				toaster.danger(errorMessage);
			}

			setIsMergeProfiles(false);
		}
	});

	const onSubmit = async ({ email, callUpdatePersonalInfo }) => {
		try {
			const {
				exists,
				type,
				already_shortlisted,
				application_id,
				can_merge,
				user_id
			} = await client(CHECK_ACTIVATE_ACCOUNT, {
				body: {
					email,
					tag: "email",
					company_id: activeCompany,
					vacancy_id: vacancyId,
					id: profileId
				}
			});
			if (exists) {
				if (type === CONSULTANCY || type === CLIENT || type === FREELANCER) {
					setError("email", {
						type: "manual",
						message: "This email already exists"
					});
				}
				if (type === PERMANENT) {
					if (already_shortlisted) {
						setIsAlreadyShortlisted(true);
						setApplicationId(application_id);
						setUserId(user_id);
						return;
					}
					if (can_merge) {
						setIsMergeProfiles(true);
						return;
					}
					setError("email", {
						type: "manual",
						message: "This email already exists"
					});
				}

				return;
			}

			if (callUpdatePersonalInfo && !already_shortlisted) {
				mutate({ id: profileId, email });
			}
		} catch (error) {
			toaster.danger(get(get(error, "detail"), "email"));
			setError("email", {
				type: "manual",
				message: get(get(error, "detail"), "email")
			});
		}
	};

	const errorMessage = get(emailError, "message", "");

	const mergeProfileHandler = () => {
		if (isMergeProfiles) {
			mutate({ id: profileId, email });
		} else {
			handleSubmit(data =>
				onSubmit({ ...data, callUpdatePersonalInfo: true })
			)();
		}
	};

	const viewProfile = () => {
		closeDrawer();
		historyPush(`/permanent/view/${userId}?application_id=${applicationId}`);
	};

	return (
		<Portal>
			<Backdrop>
				<AddMailModalContainer>
					<div className="modal-interview-header">
						<span className="title">Ooops, Email not found!</span>
						<button
							onClick={e => {
								e.stopPropagation();
								closeModal();
							}}
							className="close-modal"
						>
							<CloseButton />
						</button>
					</div>
					<div className="modal-interview-body">
						<div className="modal-body-text">
							<span>There is no email address for this candidate.</span>
							<span>
								Please provide the address to which the {labelKey} notification
								can be sent.
							</span>
						</div>
						<div className="add-mail-input">
							<Controller
								name="email"
								control={control}
								render={({ field: { onChange, value } }) => {
									return (
										<input
											type="email"
											value={value}
											onChange={({ target }) => {
												if (isMergeProfiles) {
													setIsMergeProfiles(false);
												}
												if (isAlreadyShortlisted) {
													setIsAlreadyShortlisted(false);
												}
												onChange(target.value);
											}}
											placeholder="Email@exemple"
											className={errorMessage ? "error" : ""}
											onBlur={handleSubmit(onSubmit)}
										/>
									);
								}}
							/>
							{errorMessage && <span className="error"> {errorMessage}</span>}
							{isMergeProfiles && (
								<span className="merge-profile-text">
									{" "}
									This email address is used by another candidate profile which
									you can merge in one profile!
								</span>
							)}
							{isAlreadyShortlisted && (
								<span className="already-shorltlist-profile-text">
									{" "}
									This email address is used by another candidate in this
									vacancy!
									<span onClick={viewProfile} className="view-profile">
										View profile
									</span>
								</span>
							)}
						</div>
					</div>
					<div className="modal-interview-footer">
						<button
							onClick={logInterviewHandler}
							className="log-interview-button"
						>
							Log {labelKey}
						</button>
						<button
							onClick={mergeProfileHandler}
							className="send-interview-button"
							disabled={errorMessage || !email || isAlreadyShortlisted}
						>
							{isMergeProfiles
								? `Merge and send ${labelKey} notification`
								: `Send ${labelKey} notification`}
						</button>
					</div>
					<LoaderFull loading={isLoading} />
				</AddMailModalContainer>
			</Backdrop>
		</Portal>
	);
};

export default AddMailModal;
