import styled from "styled-components";

export const SendOfferContainer = styled.div`
	filter:${({ isLoading }) => isLoading && "blur(1px);"}
	.loader-container {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		z-index:999;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: not-allowed;
	}
`;
export const Container = styled.div`
	width: 860px;
	background: white;
	margin: 10px auto;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	.box-title {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		color: #181819;
	}
	.box-actions {
		display: flex;
		flex-direction: column;
		align-self: center;
		align-items: center;
	}
`;
export const OfferTermsContainer = styled.div`
	margin: 40px 0 30px 0;
	.offer_terms_label {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #a8aaac;
		> svg {
			margin-left: 10px;
		}
	}
	.offer_terms_form {
		border: 1px solid #f0f3f5;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 16px;
		.first_row {
			display: grid;
			grid-template-columns: 4fr 3fr 4fr 4fr 4fr;
			grid-template-rows: 8em;
			grid-gap: 8px;
			.select-label {
				color: #6d7175;
			}
			.Select-control {
				height: 36px;
				line-height: 36px;
				.Select-placeholder {
					line-height: 40px;
				}
				.Select-value-label {
					line-height: 40px;
				}
			}
			.form-group {
				margin-top: 10px;
				> label {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					color: #6d7175;
					text-transform: capitalize;
					font-family: "basiercircle";
				}
				.input-box {
					.currency-label {
						top: revert;
						bottom: revert;
						line-height: 36px;
					}
					.amount {
						padding: 0 45px 0 20px;
						height: 36px;
						line-height: 36px;
						min-height: inherit;
					}
				}
			}
		}
		.second_row {
			display: grid;
			grid-template-columns: 3fr 1fr;
			grid-gap: 8px;
			.form-group {
				margin-top: 5px;
				> label {
					color: #6d7175;
					font-family: "basiercircle";
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 16px;
					text-transform: capitalize;
				}
				.datePickerReadOnlyInput {
					padding: 0 10px;
					min-height: 30px;
					height: 36px;
					background: white;
				}
			}
			.input-box {
				.input-label {
					color: #6d7175;
					font-family: "basiercircle";
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 16px;
				}
				.extra_benefits {
					padding: 0 20px;
					min-height: 30px;
					height: 36px;
					&:focus {
						border-color: #66afe9;
						outline: 0;
						box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
							0 0 8px rgb(102 175 233 / 60%);
					}
					&:hover {
						border-color: #66afe9;
					}
				}
			}
		}
	}
`;

export const AdditionalFilesContainer = styled.form`
	width: 100%;
	margin: 40px 0;
	.title {
		font-size: 14px;
		line-height: 16px;
		color: #a8aaac;
	}
	> label {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #a8aaac;
		text-transform: capitalize;
	}
	.rc-progress-line {
		margin-top: 8px;
	}
`;
export const UploaderInput = styled.div`
	background: #ffffff;
	border: ${({ isDragActive }) =>
		isDragActive ? "2px dashed #e86148;" : "1px dashed #48494a"};
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
	color: #181819;
	&:hover {
		border: 1px dashed #e86148;
	}
	.text {
		margin-left: 16px;
		.bold_text {
			color: #e86148;
			font-weight: 600;
		}
	}
`;

export const LocationContainer = styled.div`
	width: 100%;
`;
