import moment from "moment";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { getFullName } from "config/helpers";
export const formatTimesheetsList = list => {
	return list.map(item => {
		return {
			id: item._id,
			job_id: item?.job?._id,
			title: item?.job?.title,
			user_id: `${item?.user?.first_name} ${item?.user?.last_name}`,
			manager_ts: `${item?.manager_ts?.first_name} ${item?.manager_ts?.last_name}`,
			business_manager: getFullName(item.business_manager),
			supplier_company_name: item.contract?.invoice_details?.company_name,
			profile_type: item?.user?.type,
			period: {
				monthly: moment(item?.period, "MM-YYYY").format("MMMM YYYY"),
				weekly: "Week " + moment(item?.period, "WW-YYYY").format("WW, YYYY")
			}[item?.reporting_settings?.timesheet_frequency],
			extended: item?.extended,
			submitted_at: item?.submitted_date
				? moment(item?.submitted_date, "X").format("DD/MM/YYYY")
				: null,
			approved_at: item?.approval_date
				? moment(item.approval_date, "X").format("DD/MM/YYYY")
				: null,
			start_date: moment(item.start_date, "X").format("DD/MM/YYYY"),
			end_date: moment(item.end_date, "X").format("DD/MM/YYYY"),
			status: item?.status,
			timesheet_frequency: capitalize(
				item?.reporting_settings?.timesheet_frequency
			),
			total_amount: item?.total_amount,
			reporting_settings: item?.reporting_settings,
			external_manager: get(
				item,
				"contract?.invoice_details?.company_timesheet_manager_email"
			),
			accounting_manager: get(
				item,
				"contract?.invoice_details?.accounting_manager_email"
			),
			po_number: get(item, "contract?.invoice_details?.po_number"),
			external_total_amount: item.external_total_amount
		};
	});
};

export const isTimesheetsFiltersFull = filters => {
	let list = [];
	if (filters) {
		list = filters.filter(({ value }) => !isTimesheetsFilterEmpty(value));
	}
	return list.length > 0;
};

export const isTimesheetsFilterEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};
