import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { PERMANENT } from "config";
import {
	showGetHelpSelector,
	showHelpModalSelector,
	helpModalFieldsSelector
} from "modules/app/selectors";
import { authenticatedUserSelector } from "modules/bids/selectors";
import { getSignUpFirstStepRoute } from "../SignUpFirstStepContainer";
import SubContainer from "../../SubContainer";

function SuccessCreate({ email, handleResendEmail }) {
	return (
		<SubContainer>
			<div className="success-content">
				<h3 className="heading">Your account has successfully been created!</h3>
				<p className="description">
					We’ve sent you a link to confirm your email address. Please check your
					inbox. It could take up to 10 minutes to show up in your inbox.
				</p>
				<div className="divider"></div>
				<div className="instructions">
					<h4 className="heading">Didn’t receive the email?</h4>
					<ol>
						<li className="item">
							<div>
								Is <b>{email}</b> your correct email without typos?
							</div>
							<div>
								If not,&nbsp;
								<Link
									className="inline-link"
									to={getSignUpFirstStepRoute(PERMANENT)}
								>
									you can restart the sign up process
								</Link>
							</div>
						</li>
						<li className="item">Check your spam folder</li>
						<li className="item">
							Add <u>noreply@wiggli.io</u> to your contacts
						</li>
						<li className="item">
							Click{" "}
							<span className="inline-link" onClick={handleResendEmail}>
								here
							</span>{" "}
							to resend the email
						</li>
					</ol>
					<div className="item">
						Still having trouble?&nbsp;
						<Link className="inline-link" to="/ask-question">
							Contact us
						</Link>
					</div>
				</div>
			</div>
		</SubContainer>
	);
}

const mapStateToProps = state => ({
	user: authenticatedUserSelector(state),
	showGetHelp: showGetHelpSelector(state),
	showHelpModal: showHelpModalSelector(state),
	fields: helpModalFieldsSelector(state)
});

export default connect(mapStateToProps)(SuccessCreate);
