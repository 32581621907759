import React from "react";
import Container from "./Container";

export default function Header({ children }) {
	return (
		<div className="content__header">
			<Container style={{ height: "100%" }}>{children}</Container>
		</div>
	);
}
