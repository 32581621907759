//TODO Remove array of free channels
//TODO Use one state for selection, now we have 2 ambiguous states useConfirmationStore and SelectionBucket, we can use one state
import React, { useMemo, useState, useRef, useEffect } from "react";
import get from "lodash/get";
import { searchParser } from "config/helpers";
import s from "./result.module.scss";
import useGetListContract from "api/useGetListContract";
import useGetChannels from "../../api/useGetChannels";
import useFiltersStore from "../../store/useFiltersStore";
import usePostingRequirementStore from "../../store/usePostingRequirementStore";
import PostingRequirementDrawer from "../postingRequirementDrawer";
import useConfirmationStore from "../../store/useConfirmationStore";
import { WIIGLI_CHANNEL } from "../../store/useConfirmationStore";
import useGetFreeChannels from "modules/publishJob/api/useGetFreeChannels";
import useLayoutElements from "hooks/useLayoutElements";
import useGetFeatureSettings from "modules/publishJob/api/useGetCompanyFeatureSettings";
import { isAllFreeChannelsSelected } from "modules/publishJob/helper/helper";
import { useSubscribeToPusher } from "hooks/useSubscribeToPusher";
import {
	EVENT_PROACTIVE_APPLICATION_SETTING_CHANGED,
	HIREME_CHANNEL_ID
} from "config";
import { useToggleFreeChannels } from "../../hooks/useToggleFreeChannels";
import { PublishVacancyPremiumChannels } from "../PublishVacancyPremiumChannels";
import { PublishVacancyContractsView } from "../PublishVacancyContractsView";
import { PublishVacancyFreeChannelsView } from "../PublishVacancyFreeChannelsView";
import { CONTRACTS_DEFAULT_GROUP_ID } from "modules/publishJob/helper/constants";
import { ReactComponent as IconInfo } from "static/icons/IconInfo.svg";
import { ReactComponent as IconClose } from "static/icons/IconClose.svg";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const EVENTS = [EVENT_PROACTIVE_APPLICATION_SETTING_CHANGED];

const ChannelsList = ({
	isInfoMissed,
	channelSelectionBucket,
	contractSelectionBucket,
	selContractLimit,
	freeChannelSelectionBucket
}) => {
	const [isInitializeDone, setIsInitializeDone] = useState(false);
	const [showAlertInfo, setShowAlertInfo] = useState(true);
	const [freeChannelsOffset, setFreeChannelsOffset] = useState(0);
	const contractsFetchingCounter = useRef(0);
	const contractDOMElement = useRef(null);
	const [isContractPageChangeEvent, setIsContractPageChangeEvent] = useState(
		false
	);
	const [contractsGroupId, setContractsGroupId] = useState();
	const { workflowId } = useOnboardingStore();
	const { data: featureSettings, refetch } = useGetFeatureSettings({
		enabled: !workflowId
	});
	const isTalentPoolActive =
		featureSettings?.feature_settings?.talent_pool.active;
	const isMyCandidatesActive =
		featureSettings?.feature_settings?.my_candidates.active;

	const togglePremiumChannel = channelSelectionBucket.actions.row;
	const toggleContract = contractSelectionBucket.actions.row;
	const toggleFreeChannel = freeChannelSelectionBucket.actions.row;
	const isPremiumChannelSelected =
		channelSelectionBucket.helpers.isChannelSelected;

	useToggleFreeChannels(
		isMyCandidatesActive,
		isTalentPoolActive,
		freeChannelSelectionBucket
	);

	const { siteId: vacancySiteId } = searchParser();
	const isDefaultChannelSetted = useRef(false);

	const {
		header: headerElement,
		scrollarea: scrollareaElement
	} = useLayoutElements();

	const {
		setvalue,
		products_specs,
		freeChannels,
		open: openPostingRequirementsDrawer
	} = usePostingRequirementStore();

	const {
		add: addPremiumChannelToConfirmationStore,
		addDynamicFreeChannels,
		removeDynamicFreeChannels,
		resetDynamicFreeChannels,
		removeChannel: removeChannelFromConfirmationStore
	} = useConfirmationStore();

	const {
		setContractOffset,
		channelName,
		region,
		industry,
		jobTitle,
		jobFunction,
		display,
		sortBy,
		contractOffset
	} = useFiltersStore();

	const payload = {
		offset: contractOffset,
		job_function_id: jobFunction?.id,
		job_title_id: jobTitle?.id,
		exact_location_id: get(region, "id", ""),
		industry_id: get(industry, "id", ""),
		search: channelName,
		display,
		limit: 8,
		sort_by: sortBy
	};

	const {
		data: freeChannelsList,
		isLoading: isFreeChannelsOnLoading
	} = useGetFreeChannels(freeChannelsOffset);

	const {
		data,
		isLoading: isLoadingContract,
		isFetching: isContractFetching
	} = useGetListContract(payload, {
		onSuccess: res => {
			selContractLimit(res.data.lec);
			contractsFetchingCounter.current++;
		}
	});

	const {
		data: premiumChannels,
		isLoading,
		isFetching: isChannelFetching
	} = useGetChannels();

	useSubscribeToPusher(EVENTS, () => {
		refetch();
	});

	useEffect(() => {
		if (!isContractFetching && !isChannelFetching) {
			if (contractsFetchingCounter.current > 1 && !isContractPageChangeEvent) {
				scrollToContracts();
			}

			setIsContractPageChangeEvent(false);
		}
	}, [isContractFetching, isChannelFetching]);

	useEffect(() => {
		resetDynamicFreeChannels();
	}, []);

	useEffect(() => {
		if (freeChannelsList?.data && !isDefaultChannelSetted.current) {
			const channels = [];

			freeChannelsList.data.forEach(channel => {
				const { site_id, product_id } = channel;

				if (site_id === vacancySiteId) {
					channels.push(channel);
					toggleFreeChannel(product_id);
				}
			});

			if (channels.length > 0) {
				addDynamicFreeChannels(channels);
				isDefaultChannelSetted.current = true;
			}
		}
	}, [freeChannelsList?.data]);

	useEffect(() => {
		if (premiumChannels && !isInitializeDone) {
			const jobBoard = premiumChannels.data[0];
			togglePremiumChannel(jobBoard.product_id, false);
			addPremiumChannelToConfirmationStore(jobBoard, false);
			setIsInitializeDone(true);
		}
	}, [premiumChannels, isInitializeDone]);

	const scrollToContracts = () => {
		var { top } = contractDOMElement.current.getBoundingClientRect();

		scrollareaElement.scrollBy({
			top: top - (headerElement.offsetHeight + 20),
			behavior: "smooth"
		});
	};

	const handlePageClick = (callback, data) => page => {
		callback(page * get(data, "pagination.limit"));
		setIsContractPageChangeEvent(true);
	};

	const addWiggliChannel = () => {
		togglePremiumChannel(HIREME_CHANNEL_ID);
		addPremiumChannelToConfirmationStore(WIIGLI_CHANNEL);
	};

	const removeWiggliChannel = () => {
		removeChannelFromConfirmationStore(HIREME_CHANNEL_ID);
		togglePremiumChannel(HIREME_CHANNEL_ID);
	};

	const handleFreeChannelPageClick = page => {
		setFreeChannelsOffset(page * freeChannelsList.pagination.limit);
	};

	const {
		selectedRows: selectedFreeChannels
	} = freeChannelSelectionBucket.state;
	const isAllFreeChannelsCheckboxChecked = useMemo(() => {
		if (isFreeChannelsOnLoading || !freeChannelsList) return false;
		return isAllFreeChannelsSelected(
			freeChannelsList.data,
			selectedFreeChannels
		);
	}, [freeChannels, selectedFreeChannels, isFreeChannelsOnLoading]);

	const selectedContractsCounter =
		contractSelectionBucket.state.selectedRows.length;
	const isContractSelected = Boolean(selectedContractsCounter);

	const handleAllFreeChannelsCheckboxChange = () => {
		const ids = freeChannelsList.data.map(item => item.product_id);
		const channelsIds = [...ids, "my_candidates", "talent_pool"];

		if (isAllFreeChannelsCheckboxChecked) {
			removeDynamicFreeChannels();
			freeChannelSelectionBucket.actions.remove(channelsIds);
			setvalue("freeChannels", {
				talent_pool: false,
				my_candidates: false
			});
		} else {
			const page = freeChannelsList.data;

			addDynamicFreeChannels(page);
			freeChannelSelectionBucket.actions.all(channelsIds);

			setvalue("freeChannels", {
				talent_pool: true,
				my_candidates: true
			});
		}
	};

	const handleContractCheckboxChange = contract => {
		const { contract_id, product, posting_requirements, group } = contract;

		const index = products_specs.findIndex(
			item => item.contractId === contract_id
		);

		if (index !== -1) {
			products_specs.splice(index, 1);
			setvalue("products_specs", products_specs);

			toggleContract(contract_id);
			if (products_specs.length === 0) {
				setContractsGroupId(null);
				if (
					selectedChannelsCounter === 1 &&
					isPremiumChannelSelected(HIREME_CHANNEL_ID)
				) {
					removeWiggliChannel();
				}
			}
			return;
		}

		if (!Boolean(posting_requirements.length)) {
			const spec = {
				productId: product.product_id,
				contractId: contract_id,
				product_title: product.title,
				postingRequirements: []
			};
			const newProductsSpecs = [...products_specs, spec];
			setvalue("products_specs", newProductsSpecs);
			if (!isPremiumChannelSelected(HIREME_CHANNEL_ID)) {
				addWiggliChannel();
			}
			toggleContract(contract_id);
			if (selectedContractsCounter === 0)
				setContractsGroupId(group?.id || CONTRACTS_DEFAULT_GROUP_ID);
		} else {
			setvalue("open", true);
			setvalue("channel", contract);
		}
	};

	const handlePremiumChannelCheckboxChange = (e, item) => {
		e.stopPropagation();
		togglePremiumChannel(item.product_id, isContractSelected);
		addPremiumChannelToConfirmationStore(item, isContractSelected);
		if (
			products_specs.length === 0 &&
			selectedChannelsCounter === 2 &&
			isPremiumChannelSelected(HIREME_CHANNEL_ID) &&
			!e.target.checked
		) {
			removeWiggliChannel();
		}
	};

	const isContractCanBeUsedForPublish = ({ group }) => {
		const id = group?.id || CONTRACTS_DEFAULT_GROUP_ID;

		return !contractsGroupId || id === contractsGroupId;
	};

	const handlePostingRequirementValidationSuccess = ({
		group,
		contract_id
	}) => {
		setvalue("open", false);
		toggleContract(contract_id);
		if (selectedContractsCounter === 0) {
			const id = group?.id || CONTRACTS_DEFAULT_GROUP_ID;

			setContractsGroupId(id);
		}
		if (!isPremiumChannelSelected(HIREME_CHANNEL_ID)) {
			addWiggliChannel();
		}
	};

	const handleDrawerCancelClick = () => setvalue("open", false);

	const handleDrawerCloseClick = () => setvalue("open", false);

	const selectedFreeChannelsCounter =
		freeChannelSelectionBucket.state.selectedRows.length;
	const selectedChannelsCounter =
		channelSelectionBucket.state.selectedRows.length;

	return (
		<div className={s.container}>
			{showAlertInfo && isInfoMissed && (
				<div className={s.alert__info}>
					<p>
						<IconInfo />
						To publish a vacancy on paid channels, please ensure you provide
						complete location information.
					</p>
					<IconClose onClick={() => setShowAlertInfo(false)} />
				</div>
			)}
			<PublishVacancyFreeChannelsView
				isAllFreeChannelsCheckboxChecked={isAllFreeChannelsCheckboxChecked}
				handleAllFreeChannelsCheckboxChange={
					handleAllFreeChannelsCheckboxChange
				}
				selectedFreeChannelsCounter={selectedFreeChannelsCounter}
				isFreeChannelsOnLoading={isFreeChannelsOnLoading}
				freeChannelsList={freeChannelsList}
				isContractSelected={isContractSelected}
				freeChannelSelectionBucket={freeChannelSelectionBucket}
				isMyCandidatesActive={isMyCandidatesActive}
				isTalentPoolActive
				freeChannelsOffset={freeChannelsOffset}
				handleFreeChannelPageClick={handleFreeChannelPageClick}
			/>
			<PublishVacancyContractsView
				selectedContractsCounter={selectedContractsCounter}
				isLoadingContract={isLoadingContract}
				handlePageClick={handlePageClick}
				setContractOffset={setContractOffset}
				contractSelectionBucket={contractSelectionBucket}
				ref={contractDOMElement}
				data={data}
				handleContractCheckboxChange={handleContractCheckboxChange}
				isContractCanBeUsedForPublish={isContractCanBeUsedForPublish}
			/>
			<PublishVacancyPremiumChannels
				isContractSelected={isContractSelected}
				channels={premiumChannels}
				handlePageClick={handlePageClick}
				isLoading={isLoading}
				channelSelectionBucket={channelSelectionBucket}
				handlePremiumChannelCheckboxChange={handlePremiumChannelCheckboxChange}
				selectedChannelsCounter={selectedChannelsCounter}
			/>
			{openPostingRequirementsDrawer && (
				<PostingRequirementDrawer
					selectContract={toggleContract}
					onValidationSuccess={handlePostingRequirementValidationSuccess}
					onCancel={handleDrawerCancelClick}
					onClose={handleDrawerCloseClick}
				/>
			)}
		</div>
	);
};

export default ChannelsList;
