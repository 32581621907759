import React, { useState, useEffect } from "react";
import { uploadToS3 } from "config/helpers.js";
import { client } from "lib/api-client";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { useMutation } from "react-query";
import { v4 as uuid } from "uuid";
import { ReactComponent as FileIcon } from "static/icons/referral/file.svg";
import { ReactComponent as SpinnerIcon } from "static/icons/spinner.svg";
import { ReactComponent as CheckIcon } from "static/icons/check-circle.svg";
import { ReactComponent as TrashIcon } from "static/icons/referral/trash.svg";
import { bytesToSize } from "config/helpers";
import Tooltip from "common/Tippy";
import styles from "./progress.module.scss";

const preSignUrl = (file, s3Folder) =>
	client(PRE_SIGNED_URL, {
		body: {
			name: file.name,
			folder: s3Folder || "candidate",
			size: file.size,
			type: file.type,
			id: uuid()
		}
	});

function Progress({
	children,
	file,
	isExtracting,
	setFiles,
	onUrlChange,
	index,
	shouldUpload = true,
	s3Folder
}) {
	const [progress, setProgress] = useState(0);
	const [fileUrl, setFileUrl] = useState("");

	const [upload, { isLoading }] = useMutation(uploadToS3);

	const [preSign, { isLoading: preSignLoading }] = useMutation(
		() => preSignUrl(file, s3Folder),
		{
			onSuccess: response => {
				upload({
					response,
					file,
					onProgress: setProgress
				}).then(result => {
					onUrlChange?.([
						{
							path: result.url,
							file_name: file.path,
							size: file.size
						}
					]);
				});
			}
		}
	);

	useEffect(() => {
		if (shouldUpload) {
			const upload = async () => {
				const response = await preSign(file, s3Folder);
				setFileUrl(`${response?.action}/${response?.file_name}`);
			};
			upload();
		}
	}, [file, shouldUpload, s3Folder]);

	if (children) {
		return (
			<React.Fragment>
				{children({
					progress,
					fileUrl,
					isLoading: isLoading || preSignLoading
				})}
			</React.Fragment>
		);
	}

	return (
		<Render
			index={index}
			path={file?.path}
			size={file?.size}
			progress={progress}
			isExtracting={isExtracting}
			setFiles={setFiles}
			onUrlChange={onUrlChange}
		/>
	);
}

const Render = ({
	index,
	path,
	size,
	progress,
	isExtracting,
	setFiles,
	onUrlChange
}) => (
	<div key={index} className={styles.previewContainer}>
		<div className={styles.fileInfos}>
			<div className={styles.iconWrapper}>
				<FileIcon />
			</div>
			<Tooltip theme="dark" content={path} overflow="hidden">
				<div className={styles.name}>{path}</div>
			</Tooltip>
			<div>{bytesToSize(size)}</div>
		</div>
		<div className={styles.fileStatus}>
			{progress < 100 ? (
				<div className={styles.progress}>
					<div className={styles.bar}>
						<div style={{ width: `${progress}%` }}></div>
					</div>
					<div className={styles.percentage}>{progress}%</div>
				</div>
			) : (
				<div className={styles.completed}>
					{isExtracting ? (
						<div className={styles.flex}>
							<div className={styles.processing}>Processing...</div>
							<SpinnerIcon className={styles.spinner} />
						</div>
					) : (
						<>
							<span className={styles.label}>Completed</span>
							<CheckIcon width={20} height={20} color="#039855" />
							<span
								onClick={() => {
									setFiles([]);
									onUrlChange([]);
								}}
								role="button"
								className={styles.deleteIcon}
							>
								<TrashIcon />
							</span>
						</>
					)}
				</div>
			)}
		</div>
	</div>
);

export default Progress;
