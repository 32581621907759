import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuid4 } from "uuid";
import SignUpFirstStep from "./SignUpFirstStep";
import { loadingSelector } from "../../../selectors/selectors";
import { signup } from "../../../actions/signUpActions";
import { fillHelpForm } from "modules/app/actions/ListsActions";
import { SIGN_UP_EXISTING_EMAIL } from "config";
import Container from "../Container";
import BackButton from "../BackButton";
import { Flex } from "rebass";
import Header from "../Header";
import { browserHistory } from "react-router";

export const getSignUpFirstStepRoute = (type = null) => {
	return `${type ? `/sign-up/${type}` : `:type`}`;
};

export class SignUpFirstStepContainer extends Component {
	uuid = uuid4();

	handleSignup = formProps => {
		const { dispatch, params } = this.props;
		return dispatch(
			signup({ type: params.type, id: this.uuid, ...formProps, step: 1 })
		).catch(error => {
			throw error;
		});
	};

	handleClaimAccount = signUpFields => {
		const { dispatch } = this.props;
		const fields = {
			help: "",
			action: "Send",
			title: "Claim my email",
			type: SIGN_UP_EXISTING_EMAIL,
			name: `${signUpFields.firstname} ${signUpFields.lastname}`,
			email: signUpFields.email,
			subject: `Claim my email`,
			message: `I legally represent the person with the email address ${signUpFields.email}\n\nPlease get back to me to grant me the access.`
		};

		dispatch(fillHelpForm(fields));
		browserHistory.push("/report-issue?from_registration");
	};

	render() {
		const { loading } = this.props;
		return (
			<>
				<Header>
					<Flex height={"100%"} alignItems={"center"}>
						<BackButton />
					</Flex>
				</Header>
				<Container style={{ paddingTop: 18, paddingBottom: 18 }}>
					<div style={{ height: 40 }}></div>
					<SignUpFirstStep
						onSignUp={this.handleSignup}
						type={this.props.params.type}
						onClaimAccount={this.handleClaimAccount}
						isLoading={loading}
						uuid={this.uuid}
					/>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => ({
	loading: loadingSelector(state)
});

export default connect(mapStateToProps)(SignUpFirstStepContainer);
