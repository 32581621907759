import styled from "styled-components";
import { colors } from "config/styles";

export const UserSender = styled.div`
	line-height: 14px;
	display: flex;
	margin-bottom: 5px;
`;

export const Title = styled.div`
	color: ${colors.anchorsColor};
	font-weight: 500;
	font-size: 12.5px;
	text-transform: uppercase;
	line-height: 14px;
	margin-bottom: 12px;
`;

export const AvatarHolder = styled.div`
	display: inline-block;
	width: ${props => (props.viewDrawer ? "auto" : "30px")};
	vertical-align: middle;
	margin-right: 5px;
	> .avatar {
		border-radius: 0;
	}
	z-index: ${props => props.zIndex};
`;

export const MoreHolder = styled.div`
	display: inline-flex;
	width: 30px;
	height: 34px;
	margin-left: 10px;
	vertical-align: middle;
	> .avatar {
		border-radius: 0;
	}
	z-index: ${props => props.zIndex};
`;

export const UserSenderName = styled.div`
	display: inline-block;
	vertical-align: middle;
	font-size: 13.5px;
	font-weight: 400;
	text-transform: capitalize;
	color: ${colors.darkGray};
	line-height: 30px;
`;

export const AttendeePopover = styled.div`
	border-radius: 4px;
	box-shadow: 0px 2px 21.5px 0 rgba(197, 199, 206, 0.43);
	border: solid 1px rgba(225, 228, 231, 0.82);
	background-color: #ffffff;
	min-width: 200px;
	min-height: 65px;
	padding: 10px;
	z-index: 100;
	position: absolute;
	.sender-name {
		align-self: center;
	}
`;

export const ContactPersonContainer = styled.div`
	min-width: 150px;
	.contact-with-popover {
		margin-top: 10px;
	}
	.box_title {
		font-family: "basiercircle";
		font-size: 12px;
		line-height: 16px;
		color: #6d7175;
		text-transform: none;
		display: flex;
		align-items: center;
		> svg {
			minwidth: 20px;
			margin-right: 10px;
		}
	}
`;

export const MoreAvatar = styled.div`
	display: inline-flex;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #f1f2f5;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	color: #919393;
`;

export const AttendeesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${props => (props.viewDrawer ? "10px" : "0")};
`;
