import React, { useState } from "react";
import styles from "./add-timesheet-entries-drawer.module.scss";
import Drawer from "rc-drawer";
import { AddTimesheetEntriesDrawerHead } from "../AddTimesheetEntriesDrawerHead";
import { AddTimesheetEntriesDrawerFooter } from "../AddTimesheetEntriesDrawerFooter";
import { AddTimesheetEntriesDrawerBody } from "../AddTimesheetEntriesDrawerBody";
import useEntries from "modules/timesheets/hooks/useEntries";
import { useSaveEntries } from "modules/timesheets/api/useSaveEntries";
import {
	beyondMaxHours,
	formatDate,
	formatExpenses,
	formatFiles,
	formatWorkTimesList,
	getHours,
	getSaveEntriesPayload,
	validateDate
} from "modules/timesheets/utils/helper";
import { withRouter } from "react-router";
import { readTimeSheet } from "modules/timesheets/actions";
import { connect, useDispatch } from "react-redux";
import { DRAFT } from "config";
import toaster from "common/Toaster";
import _get from "lodash/get";
import { renderError } from "config/helpers";

const AddTimesheetEntriesDrawer = ({
	onClose,
	disputedWorkTime,
	month,
	timesheet,
	params,
	addedWorkTimes,
	setAddedWorkTime,
	setAddedOverTime,
	addedOverTimes,
	setAddedExpense,
	addedExpenses,
	setAttachments,
	type,
	attachments,
	showAmountInDrawer
}) => {
	const [showForm, setShowForm] = useState(false);
	const [errorBanner, setErrorBanner] = useState(null);
	const [entriesErrors, setEntriesErrors] = useState(null);
	const { timesheetId, jobId } = params;
	const { entries, clearEntries } = useEntries();
	const dispatch = useDispatch();
	const [save] = useSaveEntries({
		onSuccess: () => {
			const { worktimes, overtimes, expenses, files } = entries[timesheetId];
			if (timesheet.data.status === DRAFT) {
				toaster.success("Entries Added.");
				dispatch(readTimeSheet(jobId, timesheetId));
			} else {
				setAddedWorkTime([
					...addedWorkTimes,
					...formatWorkTimesList(worktimes, true, true, type)
				]);
				setAddedOverTime([
					...addedOverTimes,
					...formatWorkTimesList(overtimes, true, true, type)
				]);
				setAddedExpense([...addedExpenses, ...formatExpenses(expenses, true)]);
				setAttachments([...attachments, ...files]);
			}
			clearEntries(timesheet.data._id);
			onClose();
		},
		onError: error => {
			if (error.status === 422) {
				setEntriesErrors(_get(error, "errors"));
				setErrorBanner({ type: "warning", message: _get(error, "detail") });
			} else {
				renderError(error);
			}
		}
	});

	const onSave = () => {
		const { worktimes, overtimes, expenses, files } =
			entries[timesheetId] || {};

		const addedEntriesData = [
			...addedWorkTimes,
			...addedOverTimes,
			...(worktimes.length > 0
				? worktimes.map(item => ({
						...item,
						hours: getHours(item),
						date: formatDate(item.date)
				  }))
				: []),
			...(overtimes.length > 0
				? overtimes.map(item => ({
						...item,
						hours: getHours(item),
						date: formatDate(item.date),
						is_overtime: true
				  }))
				: [])
		];

		const { isValid, beyondMaxHoursErrors } = beyondMaxHours({
			month: _get(timesheet, "data.month", {}),
			disputedWorkTime,
			addedWorkTimes: addedEntriesData,
			reportings_settings: _get(timesheet, "data.reporting_settings")
		});

		if (isValid) {
			const { isWithinRange, validateDateErrors } = validateDate({
				worktimes,
				overtimes,
				expenses,
				startDate: timesheet.data.start_date,
				endDate: timesheet.data.end_date
			});
			if (isWithinRange) {
				const body = getSaveEntriesPayload(
					{
						worktimes: formatWorkTimesList(worktimes, true),
						overtimes: formatWorkTimesList(overtimes, true),
						expenses: formatExpenses(expenses),
						files: formatFiles(files)
					},
					timesheetId
				);
				save(body);
			} else {
				setErrorBanner({ type: "warning", message: "Invalid entries data" });
				setEntriesErrors(validateDateErrors);
			}
		} else {
			setErrorBanner({ type: "warning", message: "Invalid entries data" });
			setEntriesErrors(beyondMaxHoursErrors);
		}
	};

	return (
		<Drawer
			open
			width="800px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable
			onClose={onClose}
		>
			<div className={styles.container}>
				<div className={styles.root}>
					<AddTimesheetEntriesDrawerHead onClose={onClose} />
					<AddTimesheetEntriesDrawerBody
						month={month}
						disputedWorkTime={disputedWorkTime}
						addedWorkTimes={addedWorkTimes}
						showAmountInDrawer={showAmountInDrawer}
						timesheet={timesheet}
						errorBanner={errorBanner}
						setErrorBanner={setErrorBanner}
						entriesErrors={entriesErrors}
						setEntriesErrors={setEntriesErrors}
						showForm={showForm}
						setShowForm={setShowForm}
					/>
					{!showForm && (
						<AddTimesheetEntriesDrawerFooter
							onSave={onSave}
							onClose={onClose}
						/>
					)}
				</div>
			</div>
		</Drawer>
	);
};

const mapStateToProps = state => ({
	timesheet: state.timesheet
});

export default withRouter(connect(mapStateToProps)(AddTimesheetEntriesDrawer));
