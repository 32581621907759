import React, { useState, useEffect } from "react";
import { Line } from "rc-progress";
import get from "lodash/get";
import _remove from "lodash/remove";
import { HUNDRED } from "config";
import { AdditionalFilesContainer, UploaderInput } from "./styles";
import { ReactComponent as UploadDone } from "static/icons/uploaded-file.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete-icon.svg";
import { ReactComponent as PaperClipIcon } from "static/icons/paperclip.svg";
import { ReactComponent as UploadFailedIcon } from "static/icons/upload-error.svg";
import { ReactComponent as UploadProgressIcon } from "static/icons/file-grey.svg";
import { Uploader } from "common/react-hook-form-components/uploader/coumpound_uploader";
import styles from "./offer.module.scss";

function AdditionalFiles({ setValue, setFiles, files }) {
	const [acceptedFiles, setAcceptedFiles] = useState([]);
	const [rejectedFiles, setRejectedFiles] = useState([]);

	const onDrop = (accepted, rejected) => {
		const filterAcceptedFiles = accepted.filter(
			item => acceptedFiles.findIndex(elem => elem.path === item.path) === -1
		);
		setAcceptedFiles(old => [...old, ...filterAcceptedFiles]);
		setValue("files", filterAcceptedFiles);
		setRejectedFiles(rejected);
	};

	const handleDeleteAcceptedFiles = (e, file) => {
		e.preventDefault();
		const newData = _remove(acceptedFiles, n => n.path !== file.path);
		const deletedFiles = _remove(files, n => n.name !== file.path);
		setFiles(deletedFiles);
		setAcceptedFiles(newData);
		setValue("files", newData);
	};

	const handleDeleteRejectedFiles = (e, file) => {
		e.preventDefault();
		const newData = _remove(rejectedFiles, n => n.file.path !== file.path);
		setRejectedFiles(newData);
	};

	const addFileUrl = url => {
		if (files.findIndex(item => item.name === url.name) === -1)
			return setFiles(old => [...old, url]);
	};
	return (
		<AdditionalFilesContainer>
			<label>{`Additional files (Pdf,Docx)`}</label>
			<Uploader>
				{acceptedFiles.map(file => (
					<Uploader.Progress key={`${file.size}_${file.name}`} file={file}>
						{({ progress, fileUrl, isLoading }) => {
							useEffect(() => {
								if (Boolean(fileUrl))
									return addFileUrl({ name: file.name, path: fileUrl });
							}, [fileUrl]);

							return (
								<div className={styles.progress_container}>
									{progress === HUNDRED ? (
										<UploadDone />
									) : (
										<UploadProgressIcon />
									)}
									<div className={styles.progress_bar}>
										<span className={styles.file_name}> {file.name} </span>
										{progress !== 100 && (
											<Line
												percent={progress}
												strokeWidth="1"
												strokeColor="#10b373"
												trailColor="white"
											/>
										)}
									</div>
									<div className={styles.actions}>
										<button
											onClick={e => handleDeleteAcceptedFiles(e, file)}
											disabled={isLoading}
										>
											<DeleteIcon />
										</button>
									</div>
								</div>
							);
						}}
					</Uploader.Progress>
				))}
				{rejectedFiles.map((item, index) => (
					<Uploader.Progress key={index} file={item.file}>
						{() => {
							return (
								<div className={styles.progress_container}>
									<UploadFailedIcon />
									<div className={styles.progress_bar}>
										<span className={styles.file_name}> {item.file.name} </span>
										<span className={styles.error_text}>
											{get(item, "errors[0].message", "")}
										</span>
									</div>
									<div className={styles.actions}>
										<button
											onClick={e => handleDeleteRejectedFiles(e, item.file)}
										>
											<DeleteIcon />
										</button>
									</div>
								</div>
							);
						}}
					</Uploader.Progress>
				))}
				<Uploader.Input onDrop={onDrop} multiple={true}>
					{({ isDragActive }) => (
						<UploaderInput isDragActive={isDragActive}>
							<PaperClipIcon />
							<span className="text">
								Drag or <span className="bold_text">Browse</span> to choose
								another file
							</span>
						</UploaderInput>
					)}
				</Uploader.Input>
			</Uploader>
		</AdditionalFilesContainer>
	);
}

export default AdditionalFiles;
