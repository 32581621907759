import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import { browserHistory } from "react-router";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import Interactions from "modules/candidate/Profile/Pages/ProfileInteractions";
import Overview from "./Pages/Overview/Overview";
import Processes from "./Pages/Processes";
import Notes from "./Pages/Notes";
import HeaderWithPagination, {
	HeaderWithSearchCandidateNavigation,
	HeaderWithVancacyNavigation
} from "./components/Header";
import style from "./components/Header/header.module.scss";
import s from "./profile.module.scss";
import { ReactComponent as AppsIcon } from "static/icons/apps.svg";
import LayoutMenu from "./components/Header/LayoutMenu";
import Files from "./Pages/Files";
import ShortListDrawer from "modules/vacancy/components/candidatePool/ShortListDrawer";
import NoteModal from "../components/profileClientView/NoteModal";
import {
	addQueryParamToPath,
	removeURLParameter,
	searchParser
} from "config/helpers";
import {
	CANDIDATE_ACTIONS,
	CANDIDATE_TABS,
	OVERVIEW,
	PROFILE_TABS,
	VACANCY_VIEW
} from "modules/home/config";
import CandidateActionsDrawer from "modules/vacancy/components/vacancyView/CandidateAction/DrawerComponent";
import useCandidateStore from "modules/home/zustand/candidate.store.js";
import usePokeView from "modules/candidate/Profile/Store/usePokeView";
import Profile from "./Pages/Profile";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import useViewOverview, {
	viewOverviewKey
} from "modules/candidate/Profile/api/useOverview";
import _get from "lodash/get";
import useMergeProfiles from "./api/useMergeProfiles";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import { personalProfileKey } from "./api/usePersonalProfile";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import useAddProfileToPool from "./api/useAddProfileToPool";
import _keys from "lodash/keys";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import PermanentViewEvaluationsTab from "./Pages/PermanentViewEvaluationsTab/PermanentViewEvaluationsTab";
import useNotesStore from "modules/notes/notes.store";
import usePermanentId from "./Store/usePermanentId";
import { useScrollTop } from "hooks/useScrollToTop";

const CANDIDATE_VIEW = "candidateTab";

const PermanentProfile = ({
	params: { id },
	location: { pathname, search: routeSearch }
}) => {
	const scrollToTop = useScrollTop();
	const { setId: setProfileId } = usePermanentId();
	const [showLayoutMenu, setShowLayoutMenu] = useState(false);
	const [mergeProfiles, setMergeProfiles] = useState(false);
	const [profileToDelete, setProfileToDelete] = useState(null);
	const [indexProfileTab, setIndexProfileTab] = useState(0);
	const [addToPool, setAddToPool] = useState(false);
	const [action, setAction] = useState();
	const [disableNavigation, setDisableNavigation] = useState(false);
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));

	const { setItems: setBreadCrumbsItems } = useBreadCrumbs(state => state);

	const { email, source, list } = searchParser();

	const isSourceVacancyView = source === VACANCY_VIEW;

	let Header;

	if (isSourceVacancyView) {
		Header = HeaderWithVancacyNavigation;
	} else if (list === "my-candidates") {
		Header = HeaderWithSearchCandidateNavigation;
	} else {
		Header = HeaderWithPagination;
	}

	const { data, isLoading } = useViewOverview(
		{
			id,
			email
		},
		{
			onSuccess: handleFetchProfileSuccess,
			onError: () => {
				browserHistory.push("/vacancies/search");
			}
		}
	);

	function handleFetchProfileSuccess(data) {
		const { first_name, last_name } = data.personal_profile;
		const fullName = `${first_name} ${last_name}`;

		if (isSourceVacancyView) {
			createBreadCrumbsItems(fullName);
		} else {
			const items = [{ name: "Permanent" }, { name: fullName }];

			setBreadCrumbsItems(items);
		}
	}

	function createBreadCrumbsItems(name) {
		const { payload } = searchParser();
		const payloadObject = JSON.parse(payload);

		const { vacancyTitle: title, vacancyId: id } = payloadObject;

		const items = [
			{ name: "Permanent" },
			{ name: "Vacancy", path: "/vacancies/list" },
			{ name: title, path: `/vacancy/${id}` },
			{ name: name ?? "loading..." }
		];

		setBreadCrumbsItems(items);
	}

	const hasInternal = _get(data, "has_internal");
	const [displayAddNoteModal, setDisplayAddNoteModal] = useState(false);

	const { setIndexTab, indexTab } = usePokeView();

	const { setItem, candidateTab } = useCandidateStore();
	const { setIsOpen, setApplicationId } = useProfileView();

	const [useMergeProfile] = useMergeProfiles({
		onSuccess: () => {
			setMergeProfiles(false);
			toaster.success("Profiles information has been merged successfully");
			queryCache.invalidateQueries(personalProfileKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(profileOverviewKey);
			browserHistory.push(removeMergeParams());
		},
		onError: () => {
			toaster.danger("Profiles can’t be merged");
		}
	});

	const removeMergeParams = () => {
		const url = window.location.pathname + window.location.search;

		return removeURLParameter(url, ["email", "profileIdToDelete"]);
	};

	const [
		addProfileToPool,
		{ isLoading: isLoadingAddProfile }
	] = useAddProfileToPool({
		onSuccess: () => {
			setAddToPool(false);
			toaster.success("Profile has been added to the talent pool successfully");
			queryCache.invalidateQueries(personalProfileKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(profileOverviewKey);
			browserHistory.push(removeAddToPoolParams());
		},
		onError: () => {
			toaster.danger("Profile can’t be added to pool");
		}
	});

	const removeAddToPoolParams = () => {
		const url = window.location.pathname + window.location.search;
		return removeURLParameter(url, ["email", "addToTalentPool"]);
	};

	useEffect(() => {
		const { application_id, tab } = searchParser();
		let index = 0;

		if (Boolean(application_id)) {
			index = getTabIndex(CANDIDATE_TABS, "processes");
		} else if (tab) {
			index = getTabIndex(CANDIDATE_TABS, tab);
		}

		handleTabsChange(index);
		setIndexTab(index);

		if (Boolean(application_id)) {
			setApplicationId(application_id);
			setIsOpen(true);
		}

		const items = [{ name: "Permanent" }, { name: "loading..." }];
		setBreadCrumbsItems(items);
	}, []);

	useEffect(() => {
		setProfileId(id);
	}, [id]);

	useEffect(() => {
		const {
			profileIdToDelete,
			addToTalentPool,
			profileAlreadyInPool,
			profileTab,
			tab,
			action: actionParam
		} = searchParser();

		setAction(actionParam);
		setDisableNavigation(!!actionParam);

		if (profileIdToDelete || addToTalentPool || profileAlreadyInPool) {
			//TODO in the case the profile in merge state, and it moved to add to talents state, it will be nice to move params of merge from URL, we can use REDUX approach (action, payload) for merge & add to talents to clean up URL
			if (profileIdToDelete) {
				setProfileToDelete(profileIdToDelete);
				setMergeProfiles(true);
			}
			if (addToTalentPool) {
				setAddToPool(true);
			}
		}

		if (profileTab) {
			const index = getTabIndex(PROFILE_TABS, profileTab);

			setIndexProfileTab(index);
		}

		let indexTab = 0;

		if (tab) {
			indexTab = getTabIndex(CANDIDATE_TABS, tab);
		}
		setIndexTab(indexTab);
	}, [routeSearch]);

	const goToTab = index => {
		const newTab = getTabName(CANDIDATE_TABS, index);
		let newRouteSearch = addQueryParamToPath(routeSearch, "tab", newTab);

		browserHistory.replace({
			pathname,
			search: newRouteSearch
		});
	};

	const handleTabsChange = index => {
		const tab = getTabName(CANDIDATE_TABS, index);
		setItem(CANDIDATE_VIEW, tab);
		goToTab(index);
	};

	const handleOpenLayoutMenu = () => setShowLayoutMenu(old => !old);

	const handleMergeProfilesButtonClick = () => {
		handleMergeConfirmationButtonClick();
		// setDisplayMergeConfirmationDialog(true);
	};

	const handleViewFilesMenuItemClick = () => {
		const index = getTabIndex(CANDIDATE_TABS, CANDIDATE_TABS.FILES);
		handleTabsChange(index);
	};

	const handleMergeClick = profileIdToDelete => {
		setAction(CANDIDATE_ACTIONS.MERGE);
		setProfileToDelete(profileIdToDelete);
		setMergeProfiles(true);
	};

	const handleAddProfileToPoolButtonClick = () => {
		const body = {
			id
		};

		addProfileToPool(body);
	};

	const handleProfileTabChange = index => {
		const profileTab = getTabName(PROFILE_TABS, index);
		const newSeachParams = addQueryParamToPath(
			routeSearch,
			"profileTab",
			profileTab
		);

		browserHistory.replace({
			pathname,
			search: newSeachParams
		});
	};

	const handleMergeConfirmationButtonClick = () => {
		const body = {
			profile_id: id,
			removed_profile_id: profileToDelete
		};

		useMergeProfile(body);
	};

	const getTabName = (tabs, index) => {
		const key = _keys(tabs)[index];
		return tabs[key];
	};

	const getTabIndex = (tabs, tabId) => {
		const index = Object.values(tabs).indexOf(tabId);

		return index;
	};

	const handleOverviewCardClick = (tab, hash = "default") => {
		const index = getTabIndex(CANDIDATE_TABS, tab);
		handleTabsChange(index, hash);
	};

	const handleAddNote = () => {
		setDisplayAddNoteModal(true);
	};

	const onEvaluationsViewMore = () => {
		const index = getTabIndex(CANDIDATE_TABS, CANDIDATE_TABS.EVALUATIONS);
		scrollToTop();
		handleTabsChange(index);
		setIndexTab(index);
	};

	return (
		<BodyClassName className="permanentProfile">
			<div className={s.profileContainer}>
				<Header
					data={data}
					setDisplayAddNoteModal={setDisplayAddNoteModal}
					displayQuickView={true}
					isLoading={isLoading}
					candidateId={id}
					mergeProfiles={mergeProfiles && action === CANDIDATE_ACTIONS.MERGE}
					mergeProfileButtonClicked={handleMergeProfilesButtonClick}
					showAddProfileToPoolButton={
						addToPool && action === CANDIDATE_ACTIONS.ADD_TO_CANDIDATES
					}
					addProfileToPoolButtonClicked={handleAddProfileToPoolButtonClick}
					setAddToPool={setAddToPool}
					disableNavigation={disableNavigation}
				/>
				<div className={style.tabsContainer}>
					<Tabs onChange={handleTabsChange} index={indexTab}>
						<div className={style.tabsContainerHeader}>
							<div className={style.tabsSizer}>
								<TabList>
									<Tab>Overview</Tab>
									<Tab>Profile</Tab>
									<Tab>Evaluations</Tab>
									<Tab>Processes</Tab>
									<Tab>Interactions</Tab>
									<Tab>Notes</Tab>
									<Tab>Files</Tab>
								</TabList>
								{candidateTab === OVERVIEW && (
									<button
										className={style.layoutBtn}
										onClick={handleOpenLayoutMenu}
									>
										<AppsIcon /> Layout
									</button>
								)}
								{showLayoutMenu && <LayoutMenu setShow={setShowLayoutMenu} />}
							</div>
						</div>
						<div className={style.profileContainer}>
							<TabPanels>
								<TabPanel>
									<Overview
										candidateData={data}
										preferenceData={data}
										isLoading={isLoading}
										candidateId={id}
										handleTabsChange={handleOverviewCardClick}
										overviewData={data}
										onEvaluationsViewMore={onEvaluationsViewMore}
										onNoteAdd={handleAddNote}
									/>
								</TabPanel>
								<TabPanel>
									<Profile
										overviewData={data}
										id={id}
										data={data}
										viewFilesMenuItemClicked={handleViewFilesMenuItemClick}
										mergeClicked={handleMergeClick}
										indexTab={indexProfileTab}
										setIndexTab={handleProfileTabChange}
										isLoadingInternal={isLoading}
										addProfileToPoolButtonClicked={
											handleAddProfileToPoolButtonClick
										}
										isLoadingAddProfile={isLoadingAddProfile}
										setAddToPool={setAddToPool}
										setDisplayAddNoteModal={setDisplayAddNoteModal}
									/>
								</TabPanel>
								<TabPanel>
									<PermanentViewEvaluationsTab
										isTabActif={indexTab === 2}
										id={id}
										candidateData={data}
									/>
								</TabPanel>
								<TabPanel>
									<Processes id={id} />
								</TabPanel>
								<TabPanel>
									<Interactions id={id} />
								</TabPanel>
								<TabPanel>
									{indexTab === 5 ? (
										<Notes id={id} candidateData={data} addNote={addNote} />
									) : (
										<></>
									)}
								</TabPanel>
								<TabPanel>
									<Files
										candidateId={id}
										cvId={_get(data, "cv_id", "")}
										hasInternal={hasInternal}
									/>
								</TabPanel>
							</TabPanels>
						</div>
					</Tabs>
				</div>
				<ShortListDrawer />
				<NoteModal
					profileId={id}
					displayAddNoteModal={displayAddNoteModal}
					onClose={() => setDisplayAddNoteModal(false)}
				/>
				<CandidateActionsDrawer />
			</div>
		</BodyClassName>
	);
};

export default PermanentProfile;

// TODO return to candidate route instead of /permanent/:id
export const getPermanentProfileRoute = id =>
	`/permanent/view/${id !== undefined ? `${id}` : `:id`}`;
