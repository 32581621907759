import React, {
	useEffect,
	useState,
	forwardRef,
	useCallback,
	useRef,
	useMemo
} from "react";
import Tooltip from "common/Tippy";
import { useFormContext } from "react-hook-form";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./add-vacancy-description-form.module.scss";
import { ReactComponent as DeleteIconRed } from "static/icons/delete-icon-red.svg";
import { ReactComponent as StarIconGreen } from "static/icons/small-star-icon.svg";
import { Button } from "common/Button";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import AddVacancyCustomInput from "../AddVacancyCustomInput/AddVacancyCustomInput";
import Editor from "../../../../../common/Editor/index";
import { EDITOR_MAX_LENGTH } from "../../utils/constants";
import { getHTMLTextLength } from "config/helpers";
import { isLanguageProtected } from "../../utils/helper";

const AddVacancyDescriptionForm = (
	{
		onDelete,
		languageId,
		isPrimary,
		onSetPrimaryClick,
		fieldName,
		setValue,
		onLanguageChange,
		index,
		titleFocus,
		onJobTitleFocus,
		descriptionFocus = false,
		onDescriptionFocus,
		profileDescriptionFocus,
		onProfileDescriptionFocus,
		isFormSubmitted,
		triggerFormValidation,
		vacancy
	},
	ref
) => {
	const {
		watch,
		formState: { errors }
	} = useFormContext();
	const formErrors = errors.description?.[index] || {};
	const titleRef = useRef();

	const { jobTitle, jobDescription, profileDescription } = watch(
		"description"
	)?.[index];

	const [showToolbar, setShowToolbar] = useState(false);
	const [titleInput, descriptionInput, profileDescriptionInput] = ref;

	useEffect(() => {
		if (titleFocus) {
			titleRef.current.focus();
			onJobTitleFocus();
		} else if (descriptionFocus) {
			ref[1].current.focus();
			onDescriptionFocus();
		} else if (profileDescriptionFocus) {
			ref[2].current.focus();
			onProfileDescriptionFocus();
		}
	}, []);

	const onProfileDescriptionChange = useCallback(
		value => {
			setValue(`${fieldName}.profileDescription`, value);
			onLanguageChange();

			if (isFormSubmitted) {
				validateField(
					value,
					`${fieldName}.profileDescription`,
					formErrors.profileDescription
				);
			}
		},
		[
			onLanguageChange,
			isFormSubmitted,
			formErrors.profileDescription,
			validateField
		]
	);

	const onDescriptionChange = useCallback(
		value => {
			setValue(`${fieldName}.jobDescription`, value);
			onLanguageChange();

			if (isFormSubmitted) {
				validateField(
					value,
					`${fieldName}.jobDescription`,
					formErrors.jobDescription
				);
			}
		},
		[
			onLanguageChange,
			isFormSubmitted,
			validateField,
			formErrors.jobDescription
		]
	);

	const validateField = useCallback((value, name, error) => {
		const length = getHTMLTextLength(value);
		const isValid = length > 0 && length <= 10000;

		if (isValid && error) {
			triggerFormValidation(name);
		} else if (!isValid && !error) {
			triggerFormValidation(name);
		}
	}, []);

	const onTitleChange = useCallback(
		e => {
			setValue(`${fieldName}.jobTitle`, e.target.value);
			onLanguageChange();

			if (isFormSubmitted) {
				const value = e.target.value;
				const error = formErrors.jobTitle;

				if (value && error) triggerFormValidation(`${fieldName}.jobTitle`);
				else if (!value && !error)
					triggerFormValidation(`${fieldName}.jobTitle`);
			}
		},
		[onLanguageChange, formErrors.jobTitle, isFormSubmitted]
	);

	const descriptionLength = useMemo(() => getHTMLTextLength(jobDescription), [
		jobDescription
	]);

	const profileDescriptionLength = useMemo(
		() => getHTMLTextLength(profileDescription),
		[profileDescription]
	);

	const isProtected =
		vacancy?.versions && isLanguageProtected(languageId, vacancy);

	const deleteButton = (
		<Button
			variant="text"
			text="Delete"
			icon={
				<DeleteIconRed
					className={isProtected ? styles.deleteIconDisabled : ""}
					stroke="red"
				/>
			}
			className={styles.deleteButton}
			onClick={onDelete}
			disabled={isProtected}
		/>
	);

	return (
		<div className={styles.container}>
			{!isPrimary && (
				<div className={styles.buttonsContainer}>
					<Button
						variant="text"
						text="Set as original"
						icon={<StarIconGreen />}
						className={styles.setOriginalButton}
						onClick={onSetPrimaryClick}
					/>
					{isProtected ? (
						<Tooltip
							content="You cannot delete this language version because it has already been published to external channels"
							theme="dark"
						>
							{deleteButton}
						</Tooltip>
					) : (
						deleteButton
					)}
				</div>
			)}

			<CreateVacancyFieldContainer>
				<CreateVacancyLabelContainer>
					<CreateVacancyFieldLabel>Job title</CreateVacancyFieldLabel>
					<CreateVacancyInfoIcon>
						The title should be as explicit and objective as possible regarding
						the actual job title that the person selected will have within your
						company.
					</CreateVacancyInfoIcon>
				</CreateVacancyLabelContainer>
				<AddVacancyCustomInput
					ref={ref => {
						titleRef.current = ref;
						titleInput.current = ref;
					}}
					placeHolder="A descriptive title of your job"
					onChange={onTitleChange}
					value={jobTitle}
				/>
				<CreateVacancyFieldError error={formErrors.jobTitle} />
			</CreateVacancyFieldContainer>
			<CreateVacancyFieldContainer>
				<CreateVacancyLabelContainer>
					<CreateVacancyFieldLabel>Job description</CreateVacancyFieldLabel>
					<CreateVacancyInfoIcon>
						A general description of the vacancy. Feel free to detail the
						function itself, the working environment, or even the soft skills
						needed.
					</CreateVacancyInfoIcon>
				</CreateVacancyLabelContainer>
				<Editor
					ref={descriptionInput}
					placeholder="Description of the role, tasks to perform, the profile sought, ..."
					scrollClassName={styles.editor}
					showToolbaar={showToolbar}
					setShowToolbar={setShowToolbar}
					useMentionPlugin={false}
					value={jobDescription}
					onChange={onDescriptionChange}
					focusClassName={styles.editorFocused}
					focus={descriptionFocus}
				/>
				<div className={styles.errorContainer}>
					<CreateVacancyFieldError error={formErrors.jobDescription} />
					{!isNaN(descriptionLength) && (
						<div className={styles.lengthContainer}>
							{descriptionLength.toLocaleString("fr")}/
							{EDITOR_MAX_LENGTH.toLocaleString("fr")}
						</div>
					)}
				</div>
			</CreateVacancyFieldContainer>
			<CreateVacancyFieldContainer>
				<CreateVacancyLabelContainer>
					<CreateVacancyFieldLabel>Profile description</CreateVacancyFieldLabel>
				</CreateVacancyLabelContainer>
				<Editor
					ref={profileDescriptionInput}
					placeholder="Description of the profile you’re looking for..."
					scrollClassName={styles.editor}
					showToolbaar={showToolbar}
					setShowToolbar={setShowToolbar}
					useMentionPlugin={false}
					value={profileDescription}
					onChange={onProfileDescriptionChange}
					focusClassName={styles.editorFocused}
					focus={profileDescriptionFocus}
				/>

				<div className={styles.errorContainer}>
					<CreateVacancyFieldError error={formErrors.profileDescription} />
					{!isNaN(profileDescriptionLength) && (
						<div className={styles.lengthContainer}>
							{profileDescriptionLength.toLocaleString("fr")}/
							{EDITOR_MAX_LENGTH.toLocaleString("fr")}
						</div>
					)}
				</div>
			</CreateVacancyFieldContainer>
		</div>
	);
};

export default forwardRef(AddVacancyDescriptionForm);
