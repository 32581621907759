import React, { useState, useEffect, useRef, forwardRef } from "react";
import cx from "classnames";
import GlobalTooltip from "../GlobalTooltip";
import { CURRENCY_PLACEHOLDER, NUMBERS_LOCALE } from "config";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";
import styles from "./new-currency-field.module.scss";
import {
	DECIMAL_SEPARATORS_KEYS_CODES,
	NUMBER_KEYS_CODES
} from "./utils/constants";

const NewCurrencyField = (
	{
		decimalSeparator = ".",
		precision = 2, //Minimum number of digits after the decimal separator ","
		label,
		input: { value: propValue, name, onChange },
		suffix,
		icon,
		tooltipTitle,
		meta: { touched, error } = {},
		className,
		disabled,
		useGreenTheme,
		placeholder = CURRENCY_PLACEHOLDER,
		rootClassName,
		currencyLabelClassName,
		isCurrencyField = true
	},
	ref
) => {
	const [inputValue, setInputValue] = useState("");
	const normalValue = useRef();

	useEffect(() => {
		if (normalValue.current === propValue) return;

		let formattedValue = "";

		if (propValue || propValue === 0) {
			//We divise the number on 100 because propValue comes with 2 additional zeros at the end, if the propValue is 1000 then the value to display is 10,00
			formattedValue = formatValue(
				String(isCurrencyField ? propValue / 100 : propValue)
			);
		}

		setInputValue(formattedValue);
	}, [propValue]);

	const handleInputChange = e => {
		const { value } = e.target;
		setInputValue(value);

		const number = value ? convertValueToNumber(value) : "";
		onChange(number);
		normalValue.current = number;
	};

	const handleInputPress = e => {
		//We allow only numbers and decimal separators "," and "."
		const { value } = e.target;
		const pressedKey = e.which;
		const isDecimalAlreadyExist = value.includes(",") || value.includes(".");

		if (
			NUMBER_KEYS_CODES.includes(pressedKey) ||
			(DECIMAL_SEPARATORS_KEYS_CODES.includes(pressedKey) &&
				!isDecimalAlreadyExist)
		) {
			setInputValue(value);
		} else e.preventDefault();
	};

	const handleInputBlur = ({ target: { value: eventValue } }) => {
		let formattedValue = "";

		if (eventValue) {
			const value = eventValue
				.replace(",", decimalSeparator)
				.replace(/\s+/g, "");

			formattedValue = formatValue(value);
		}

		setInputValue(formattedValue);
	};

	const formatValue = value => {
		const parsedFloat = parseFloat(value).toFixed(precision);

		return Number(parsedFloat).toLocaleString(NUMBERS_LOCALE, {
			minimumFractionDigits: precision
		});
	};

	const convertValueToNumber = value => {
		let replacedValue = value.includes(",") ? value.replace(/,/g, ".") : value;

		return parseFloat(replacedValue.replace(/\s/g, "")) * 100;
	};

	const isErrorState = touched && error;

	return (
		<div
			className={cx(
				"currency-field form-group",
				styles.container,
				rootClassName,
				{
					"has-error has-feedback": isErrorState
				}
			)}
		>
			{label && (
				<label htmlFor={name}>
					{label}
					{tooltipTitle && (
						<GlobalTooltip maxWidth="400px" overlay={tooltipTitle} />
					)}
				</label>
			)}
			<div className={cx("input-box", styles.inputBox)}>
				{icon && <i className={icon} />}
				<span>
					{suffix && (
						<span className={cx("currency-label", currencyLabelClassName)}>
							{suffix}
						</span>
					)}
				</span>
				<input
					ref={ref}
					name={name}
					autoComplete="off"
					placeholder={placeholder}
					className={
						useGreenTheme
							? styles.inputGreen
							: `form-control currency-control ${className}`
					}
					value={inputValue}
					disabled={disabled}
					onChange={handleInputChange}
					onBlur={handleInputBlur}
					onKeyPress={handleInputPress}
				/>
				{isErrorState && (
					<div className="help-block inline-error">
						<ErrorIcon />
					</div>
				)}
			</div>
		</div>
	);
};

export default forwardRef(NewCurrencyField);
