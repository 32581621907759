//To understand more about VONQ validation API, chech this https://vonq.stoplight.io/docs/hapi/c04a165f376cb-hapi-campaigns-validation
import React, { useState, useMemo, useEffect, Fragment } from "react";
import { produce } from "immer";
import Drawer from "rc-drawer";
import get from "lodash/get";
import cx from "classnames";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostingRequirementStore from "../../store/usePostingRequirementStore";
import { ReactComponent as QuiteIcon } from "static/icons/quite-icon.svg";
import s from "./posting-requirement.module.scss";
import "rc-cascader/assets/index.css";
import useValidatePostingRequirements from "../../api/useValidateContractPostingRequirements";
import toast from "react-hot-toast";
import {
	formatPostingRequirementsFormValues,
	yupRules
} from "./../../helper/helper";
import PostingRequirementsFormRequiredField from "../PostingRequirementsFormRequiredField/PostingRequirementsFormRequiredField";
import { PostingRequirementsFormInput } from "../PostingRequirementsFormInput";

const PostingRequirementDrawer = ({
	onValidationSuccess,
	onCancel,
	onClose
}) => {
	const [fetchedOptionsMap, setFetchedOptionsMap] = useState(new Map());
	const [requiredFields, setRequiredFields] = useState([]);
	const [serverValidationResponse, setServerValidationResponse] = useState({
		errorMessages: {},
		hasError: false
	});
	const [dynamicFields, setDynamicFields] = useState([]);

	const { channel, products_specs, setvalue } = usePostingRequirementStore();
	const [mutate] = useValidatePostingRequirements();

	useEffect(() => {
		if (!channel?.posting_requirements) {
			return;
		}

		let fields = [];
		channel?.posting_requirements.forEach(({ name, required }) => {
			if (required) {
				fields.push(name);
			}
		});

		setRequiredFields(fields);
	}, [channel?.posting_requirements]);

	const schema = useMemo(() => {
		const fields = channel?.posting_requirements.filter(item => {
			return !item.display_rules?.show;
		});
		const fieldsSchema = fields.reduce((acc, item) => {
			if (
				!Boolean(item?.rules?.length) &&
				item.required &&
				!item.autocomplete
			) {
				acc[item.name] = yupRules.required;
			}
			if (
				Boolean(item?.rules?.length) &&
				item.required !== "1" &&
				!item.autocomplete
			) {
				if (Object.keys(yupRules).includes(item.rules[0].rule)) {
					acc[item.name] = yupRules[item.rules[0].rule](
						item.rules[0].data,
						item.required
					);
				}
			}
			if (item.required && Boolean(item?.rules?.length && !item.autocomplete)) {
				if (Object.keys(yupRules).includes(item.rules[0].rule)) {
					acc[item.name] = yupRules[item.rules[0].rule](
						item.rules[0].data,
						item.required,
						item.type === "HIER"
					);
				}
			}
			if (item.type === "HIER" && item.required && item.autocomplete) {
				acc[item.name] = yupRules.required;
			}

			if (item.type === "SELECT" && item.required) {
				acc[item.name] = yup
					.string()
					.transform(currentValue => currentValue?.value || currentValue)
					.required("* Required field");
			}
			return acc;
		}, {});

		return yup.object().shape(fieldsSchema);
	}, [channel?.posting_requirements]);

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch
	} = useForm({
		shouldUnregister: true,
		mode: "onTouched",
		resolver: yupResolver(schema)
	});

	const onSubmit = values => {
		//Submit only required & setted fields to avoid any issue form the server!
		const valuesFiltered = {};
		const vacancy = [];

		for (const key in values) {
			if (requiredFields.includes(key) || values[key]) {
				//This for dynamic fields. A field with a key "key1.key2" gets converted by ReactHookForm to key1:{key2: ""}
				const isNestedObject =
					typeof values[key] === "object" &&
					!Array.isArray(values[key]) &&
					//Date field is not a dynamic field, we should ignore dates
					typeof values[key]?.getMonth != "function";
				if (values[key]?.label && values[key]?.value) {
					valuesFiltered[key] = values[key]?.value;
				} else if (isNestedObject) {
					const nestedKey = Object.keys(values[key])[0];

					vacancy.push({
						name: `${key}.${nestedKey}`,
						value: values[key][nestedKey]
					});
				} else if (isDynamicField(key)) {
					vacancy.push({
						name: key,
						value: values[key]
					});
				} else {
					valuesFiltered[key] = values[key];
				}

				//This is a workaround to fix contract VDAB, we can remove the condition when we have a clear API
				if (key === "applicationUrl") {
					valuesFiltered[key] = values[key];
				}
			}
		}

		const postingRequirementsArray = formatPostingRequirementsFormValues(
			valuesFiltered,
			channel.posting_requirements
		);

		const body = {
			product_id: channel.product.product_id,
			contract_id: channel.contract_id,
			vacancy: vacancy,
			posting_requirements: postingRequirementsArray
		};

		const postingRequirementsObject = {};
		for (var i in postingRequirementsArray) {
			const { name, value } = postingRequirementsArray[i];
			postingRequirementsObject[name] = value;
		}

		mutate(body, {
			onSuccess: () =>
				handleValidatePostingRequirementsSuccess(
					postingRequirementsObject,
					vacancy
				),
			onError: res => {
				if (res.status === 422 && res?.errors?.posting_requirements) {
					setServerValidationResponse({
						errorMessages: res.errors.posting_requirements,
						hasError: true
					});
				} else {
					toast(res.title, { isFailed: true });
				}
			}
		});
	};

	const isDynamicField = fieldName => {
		const index = dynamicFields.findIndex(({ name }) => name === fieldName);

		return index !== -1;
	};

	const handleValidatePostingRequirementsSuccess = (
		postingRequirements,
		vacancy
	) => {
		setServerValidationResponse({
			errorMessages: {},
			hasError: false
		});

		const spec = {
			productId: channel.product.product_id,
			contractId: channel.contract_id,
			product_title: channel.product.title,
			postingRequirements: postingRequirements
		};

		const newProcustSpecs = [...products_specs, spec];

		setvalue("products_specs", newProcustSpecs);
		setvalue("vacancy", vacancy);
		onValidationSuccess(channel);
	};

	const getFieldMessageError = fieldName => {
		if (errors?.[fieldName]) {
			return errors[fieldName].message;
		} else {
			const error =
				serverValidationResponse.errorMessages[fieldName] ||
				(fieldName in serverValidationResponse.errorMessages &&
					"Error in the field");

			if (error) {
				return error.replaceAll('\\"', "");
			} else {
				return "";
			}
		}
	};

	const onFieldChange = (name, value) => {
		checkForRequiredFields(value, name);

		if (serverValidationResponse.errorMessages[name]) {
			setServerValidationResponse(({ errorMessages, ...rest }) => {
				const newErrors = { ...errorMessages };
				delete newErrors[name];

				return {
					...rest,
					errorMessages: newErrors
				};
			});
		}
	};

	const checkForRequiredFields = (option, name) => {
		setDynamicFields(state => {
			if (Array.isArray(option)) {
				return produce(state, draft => {
					const triggers = option.map(({ key }) => key);

					draft = state.filter(({ parent, trigger }) => {
						if (parent === name) {
							return triggers.includes(trigger);
						} else {
							return true;
						}
					});

					option.forEach(({ requires, key: trigger }) => {
						const field = requires?.[0]?.field;
						const index = state.findIndex(field => {
							return field.parent === name && field.trigger === trigger;
						});

						if (field && index === -1) {
							draft.push({ name: field.name, parent: name, trigger });
						}
					});

					return draft;
				});
			} else {
				const field = option?.requires?.[0]?.field;
				const index = state.findIndex(({ parent }) => parent === name);

				//Check whether the field has required field or already registers in the state as parent
				if (field || index != -1) {
					if (index === -1) {
						return [...state, { name: field.name, parent: name }];
					} else {
						return produce(state, draft => {
							if (field) {
								const newField = { name: field.name, parent: name };

								draft[index] = newField;
							} else {
								draft.splice(index, 1);
							}
						});
					}
				} else {
					return state;
				}
			}
		});
	};

	const shouldDisplayField = (displayRules, fieldToShow) => {
		//Display rules examples https://vonq.stoplight.io/docs/hapi/bfd00ddf9796d-hapi-job-post-implementation-guide
		const op = displayRules?.show?.[0]?.op;
		const fieldValue = watch(displayRules?.show?.[0]?.facet);

		if (op === "in") {
			const list = displayRules?.show?.[0]?.value;
			return list.includes(fieldValue?.value || fieldValue);
		} else if (op === "contains") {
			return (
				get(displayRules, "show").filter(el => fieldValue?.includes(el.value))
					.length > 0
			);
		} else if (op === "selected_option_show_contains") {
			const field = displayRules?.show?.[0]?.facet;
			const options = fetchedOptionsMap.get(field);

			if (!options || !fieldValue) return false;
			const option = options.find(
				({ show, key }) => show[0] === fieldToShow && key === fieldValue?.value
			);

			return !!option;
		} else {
			return fieldValue === displayRules?.show?.[0]?.value;
		}
	};

	const onFetchSuccess = (name, data) => {
		const state = produce(fetchedOptionsMap, draft => {
			draft.set(name, data);
		});
		setFetchedOptionsMap(state);
	};

	return (
		<Drawer
			open
			width={`650px`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={onClose}
			classname="addContract"
		>
			<div className={cx(s.container)}>
				<div className={s.header}>
					<label>Adding posting requirement </label>
					<button onClick={onClose}>
						<QuiteIcon />{" "}
					</button>
				</div>
				<div className={s.subHeader}>
					Add posting requirement for{" "}
					{get(channel, "product.title", "---").split(" - ")[0]}{" "}
				</div>

				{Boolean(get(channel, "posting_requirements.length", 0)) && (
					<div className={s.credentialsContainer}>
						<h6>Options</h6>
						<div className={s.fieldsContainer}>
							{get(channel, "posting_requirements", []).map(field => {
								const additionalFields = dynamicFields.filter(
									({ parent }) => parent === field.name
								);

								const displayField = field?.display_rules
									? shouldDisplayField(field.display_rules, field.name)
									: true;

								return (
									<Fragment key={field.name}>
										{displayField ? (
											<Controller
												name={field.name}
												control={control}
												render={({ field: { onChange, value } }) => (
													<PostingRequirementsFormInput
														onFetchSuccess={onFetchSuccess}
														field={field}
														onChange={onChange}
														value={value}
														errorMessage={getFieldMessageError(field.name)}
														onFieldChange={onFieldChange}
														channel={channel}
														watch={watch}
													/>
												)}
											/>
										) : null}
										{additionalFields.map((field, index) => (
											<PostingRequirementsFormRequiredField
												key={index}
												field={field}
												control={control}
												onFieldChange={onFieldChange}
												getFieldMessageError={getFieldMessageError}
											/>
										))}
									</Fragment>
								);
							})}
						</div>
					</div>
				)}
				<div className={s.actions}>
					<button className={s.primary} onClick={handleSubmit(onSubmit)}>
						Add specs
					</button>
					<button className={s.secondary} onClick={onCancel}>
						Cancel
					</button>
				</div>
			</div>
		</Drawer>
	);
};

export default PostingRequirementDrawer;
