import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { queryCache, useMutation } from "react-query";
import * as yup from "yup";
import get from "lodash/get";
import _find from "lodash/find";
import { useDispatch, useSelector } from "react-redux";
import Header from "modules/vacancy/components/vacancyView/CandidateAction/components/DrawerHeader.jsx";
import { ReactComponent as ProposeOfferIcon } from "static/icons/propose_offer_icon.svg";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import {
	CancelButton,
	ActionButton
} from "modules/vacancy/components/vacancyView/CandidateAction/components/styles.js";
import { Container, SendOfferContainer } from "./styles";
import OfferTerms from "modules/vacancy/components/vacancyView/CandidateAction/components/offer/OfferTerms.jsx";
import JobLocation from "modules/vacancy/components/vacancyView/CandidateAction/components/offer/JobLocation.jsx";
import AdditionalFiles from "modules/vacancy/components/vacancyView/CandidateAction/components/offer/AdditionalFiles";
import { SEND_OFFER, PROPOSE_ANOTHER_OFFER } from "config/api-endpoints";
import { client } from "lib/api-client";
import {
	EMPLOYMENT_TYPE,
	PAYMENTS_TYPE,
	PAYMENTS_TIME,
	SEARCH_ADDRESS
} from "config";
import Loader from "common/Loader";
import toaster from "common/Toaster";
import { fetchPoke } from "modules/vacancy/actions";
import { handleAddressObject } from "config/helpers";
import AddMailModal from "modules/vacancy-view/components/ProposeInterview/AddMailModal";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import { activeProcessesKey } from "modules/candidate/Profile/api/useActiveProcesses";
const sendOffer = payload =>
	client(SEND_OFFER, {
		body: payload
	});
const proposeAnotherOffer = payload =>
	client(PROPOSE_ANOTHER_OFFER, {
		body: payload
	});

const getAddressOnly = (data = {}) => {
	return {
		street: data.street || data.company_street,
		number: data.number || data.company_number,
		box: data.box || data.company_box,
		city: data.city || data.company_city,
		zip: data.zip || data.company_zip,
		country: data.country || data.company_country,
		longitude: data.longitude || data.company_longitude,
		latitude: data.latitude || data.company_latitude,
		iso_country: data.iso_country || data.company_iso_country
	};
};

const getIsoCountry = async address => {
	const { address: newAddress } = await handleAddressObject(address, true);
	return newAddress;
};

function ProposeOffer({
	job = {},
	offer,
	poke,
	vacancyId,
	profileId,
	onSuccess,
	isFromVacanciesTable
}) {
	const [files, setFiles] = useState([]);
	const authenticatedUser = useSelector(state => state.auth.user);
	const [address, setAddress] = useState(getAddressOnly(authenticatedUser));
	const [addressTypo, setAddressTypo] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const [showAddMailModal, setShowAddMailModal] = useState(false);
	const dispatch = useDispatch();
	const {
		drawerState: {
			application_id,
			candidateName,
			offer: applicantOffer,
			job: appliedJob,
			candidateEmail
		},
		setDrawerState
	} = useVacancyStore();

	const offerData = Object.keys(offer).length > 0 ? offer : applicantOffer;
	const jobData = Boolean(Object.keys(job).length) ? job : appliedJob;
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();

	const [mutate, { isLoading }] = useMutation(sendOffer, {
		onSuccess: (res, req) => {
			setDrawerState({ open: false });
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(application_id);
			toaster.success(
				`the offer proposition has successfully been ${
					req.log_event ? "logged" : "sent"
				}!`
			);
			queryCache.invalidateQueries("getDataPipeline");
			onSuccess();
			if (get(poke, "_id"))
				return dispatch(fetchPoke({ id: get(poke, "_id") }));
		},
		onError: err => {
			if (Boolean(err?.detail?.name)) return toaster.danger(err.detail.name);
		}
	});

	const [edit_offer, { isLoading: editOfferLoading }] = useMutation(
		proposeAnotherOffer,
		{
			onSuccess: () => {
				setDrawerState({ open: false });
				toaster.success(
					"the new offer proposition has successfully been sent!"
				);
				if (!isFromVacanciesTable) {
					setIsOpen(true);
				}
				setApplicationId(application_id);
				queryCache.invalidateQueries("getDataPipeline");
				queryCache.invalidateQueries(activeProcessesKey);
				onSuccess();
				if (get(poke, "_id"))
					return dispatch(fetchPoke({ id: get(poke, "_id") }));
			},
			onError: err => {
				if (Boolean(err?.detail?.name)) return toaster.danger(err.detail.name);
			}
		}
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		register,
		setValue,
		watch
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: true,
		defaultValues: {
			files: []
		}
	});

	useEffect(() => {
		const contract_type = _find(EMPLOYMENT_TYPE, [
			"value",
			offerData?.contract_type || jobData?.employment_type
		]);
		const salary_type = _find(PAYMENTS_TYPE, [
			"value",
			offerData?.["payment_type"] || jobData?.["payment_type"]
		]);

		const payment_periode = _find(PAYMENTS_TIME, [
			"value",
			offerData?.["payment_time"] || jobData?.["payment_time"]
		]);
		setValue("contract_type", contract_type);
		setValue("salary_type", salary_type);
		setValue("payment_periode", payment_periode);
		setValue("currency", {
			value: offerData["currency"] || jobData?.["currency"] || "EUR",
			symbol: offerData["symbol"] || jobData?.["symbol"] || "EUR"
		});
		setValue("amount", offerData.amount * 100 || jobData?.salary_range_end);
		setValue(
			"extra_benefits",
			offerData["other_benefits"] || jobData?.["other_benefits"]
		);
		setValue(
			"start_date",
			offerData["start_date"]
				? window.moment(new Date(offerData["start_date"] * 1000))
				: undefined
		);
	}, [offerData]);

	const handleSendOffer = () => {
		if (!Boolean(candidateEmail)) return setShowAddMailModal(true);
		return handleSubmit(values => onSubmit(values))();
	};

	const onSubmit = async (values, log_event) => {
		const finalAddress =
			get(values, "location_type") === SEARCH_ADDRESS &&
			(await handleAddressObject(address));

		if (finalAddress.addressTypo) {
			setAddressTypo(true);
			return setAddressError(true);
		}

		finalAddress && setAddress(get(finalAddress, "address"));

		let payloadAddress = finalAddress
			? getAddressOnly(get(finalAddress, "address"))
			: getAddressOnly(address);

		if (!payloadAddress.iso_country) {
			payloadAddress = await getIsoCountry(payloadAddress);
		}

		const payload = {
			offer_id: offerData._id ? offerData._id : undefined,
			contract_type: get(values, "contract_type.value", ""), // permanent, fixed_term, internship
			application_id: application_id,
			start_date: get(values, "start_date").unix(), // must not be in the past
			files,
			location_type: get(values, "location_type"), // full_remote, company_address, search_address
			other_benefits: get(values, "extra_benefits", ""),
			amount: parseFloat(get(values, "amount")) / 100,
			payment_time: get(values, "payment_periode.value", ""), //'monthly', 'yearly', 'hourly'
			payment_type: get(values, "salary_type.value", ""), // 'gross', 'net'
			currency: get(values, "currency.value", ""),
			symbol: get(values, "currency.symbol", ""),
			log_event,
			...payloadAddress
		};
		if (Object.keys(offerData).length > 0) return await edit_offer(payload);
		return await mutate(payload);
	};

	const formValues = watch();

	const closeProposeOffer = () => {
		if (isFromCardActions) {
			setDrawerState({ open: false });
		} else {
			setDrawerState({ open: false });
			setIsOpen(true);
			setApplicationId(application_id);
		}
	};
	const closeDrawer = () => {
		setDrawerState({
			open: false,
			component: ""
		});
	};
	return (
		<SendOfferContainer isLoading={isLoading || editOfferLoading}>
			{(isLoading || editOfferLoading) && (
				<div className="loader-container">
					<Loader />
				</div>
			)}
			<Header
				icon={<ProposeOfferIcon />}
				title="Send Offer"
				onClose={closeProposeOffer}
			/>
			<Container>
				<h4 className="box-title">
					Use the form below to let {candidateName} know about the details of
					your offer
				</h4>
				<OfferTerms
					control={control}
					errors={errors}
					register={register}
					values={formValues}
				/>
				<JobLocation
					control={control}
					errors={errors}
					address={address}
					setAddress={setAddress}
					addressTypo={addressTypo}
					addressError={addressError}
					setAddressError={setAddressError}
					authenticatedUser={authenticatedUser}
				/>
				<AdditionalFiles
					setValue={setValue}
					setFiles={setFiles}
					files={files}
				/>
				<div className="box-actions">
					<ActionButton className="btn-action" onClick={handleSendOffer}>
						Send offer
					</ActionButton>
					<CancelButton
						className="btn-cancel"
						margin="10px 0 0 0"
						onClick={handleSubmit(values => onSubmit(values, true))}
					>
						Log event
					</CancelButton>
				</div>
			</Container>
			{showAddMailModal && (
				<AddMailModal
					profileId={profileId}
					closeModal={() => setShowAddMailModal(false)}
					logInterviewHandler={handleSubmit(values => onSubmit(values, true))}
					sendInterviewHandler={handleSubmit(values => onSubmit(values))}
					activeCompany={get(authenticatedUser, "active_company")}
					labelKey="offer"
					vacancyId={vacancyId}
					closeDrawer={closeDrawer}
				/>
			)}
		</SendOfferContainer>
	);
}

export default ProposeOffer;

const schema = yup.object().shape({
	contract_type: yup
		.object()
		.nullable()
		.required("please choose a contract type"),
	currency: yup.object().required("please choose a currency"),
	amount: yup.string().required("please type a currency"),
	salary_type: yup.object().required("please choose a salary type"),
	payment_periode: yup.object().required("please choose a payment type"),
	start_date: yup.object().required("please choose a start date")
});
