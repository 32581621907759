// setup guide tours slug
export const TOUR_INTERFACE_NAVIGATION = "tour-interface-navigation";
export const TOUR_SETUP_COMPANY = "tour-setup-company";
export const TOUR_USER_AND_DEPARTEMENT = "tour-user-and-departement";
export const TOUR_EMPLOYER_BRANDING = "tour-employer-branding";
// how to tours slug
export const HOW_TO_CREATE_VACANCY = "how-to-create-vacancy";
export const HOW_TO_PUBLISH_VACANCY = "how-to-publish-vacancy";
export const HOW_TO_SOURCE_AND_INVITE_CANDIDATE =
	"how-to-source-and-invite-candidate";
export const TOUR_VACANCY_PIPELINE = "tour-vacancy-pipeline";
export const HOW_TO_WORK_WITH_NOTES = "how-to-work-with-notes";

// update user settings
export const UPDATE_SETTINGS_TYPE = "onboarding_options";
export const UPDATE_SETTINGS_ONBOARDING_STEPS = "onboarding_steps_state";
export const UPDATE_SETTINGS_ONBOARDING_TRACKED_PAGES =
	"onboarding_viewed_pages";

export const ONBOARDING_COMMON_STYLES = {
	popover: base => ({
		...base,
		padding: 0,
		width: "400px",
		maxWidth: "400px",
		boxShadow: "none",
		borderRadius: "12px",
		backgroundColor: "transparent"
	}),
	maskWrapper: base => ({
		...base,
		color: "transparent"
	})
};

export const INTERACTIVE_CONTAINER_STYLES = {
	pointerEvents: "auto",
	animation: "grayHighlight 700ms infinite"
};
