import { getWeeksInMonth } from "@internationalized/date";
import { useCalendarGrid } from "react-aria";
import { CalendarCell } from "common/DatePicker/CalendarCell";
import React from "react";
import styles from "./calendar-grid.module.scss";
import { useLocale } from "react-aria";
import cx from "classnames";

const CalendarGrid = ({ state, isMultiple, list, ...props }) => {
	const { locale } = useLocale();
	let { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

	// Get the number of weeks in the month so we can render the proper number of rows.
	let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

	return (
		<table {...gridProps}>
			<thead className={styles.tableHeader} {...headerProps}>
				<tr>
					{weekDays.map((day, index) => (
						<th
							className={cx(styles.th, { [styles.days]: isMultiple })}
							key={index}
						>
							{day}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{[...new Array(weeksInMonth).keys()].map(weekIndex => (
					<tr key={weekIndex}>
						{state
							.getDatesInWeek(weekIndex)
							.map((date, i) =>
								date ? (
									<CalendarCell
										isWeekend={i === 5 || i === 6}
										key={i}
										state={state}
										date={date}
										isMultiple={isMultiple}
										list={list}
									/>
								) : (
									<td className={styles.td} key={i} />
								)
							)}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default CalendarGrid;
