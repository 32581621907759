import React from "react";
import { Controller } from "react-hook-form";
import get from "lodash/get";
import InterviewLocation from "modules/vacancy-view/components/ProposeInterview/PlanInterview/InterviewLocation";
import { SEARCH_ADDRESS, COMPANY_ADDRESS, REMOTE } from "config";
import { LocationContainer } from "./styles";

function JobLocation({
	control,
	authenticatedUser,
	address,
	setAddress,
	addressTypo,
	addressError,
	setAddressError
}) {
	const onChangeAddress = newAddress => {
		if (
			newAddress.country !== get(address, "country") ||
			newAddress.city !== get(address, "city")
		) {
			if (
				newAddress.longitude === get(address, "city") ||
				newAddress.latitude === get(address, "latitude")
			) {
				newAddress.latitude = 0;
				newAddress.longitude = 0;
			}
		}
		setAddress(newAddress);
	};

	const changeLocationType = value => {
		if (value === SEARCH_ADDRESS) {
			onChangeAddress({
				street: "",
				number: "",
				box: "",
				city: "",
				zip: "",
				country: "",
				longitude: "",
				formatted_address: "",
				latitude: "",
				iso_country: ""
			});
		}
		if (value === COMPANY_ADDRESS || value === REMOTE) {
			onChangeAddress({
				street: authenticatedUser.company_street,
				number: authenticatedUser.company_number,
				box: authenticatedUser.company_box,
				city: authenticatedUser.company_city,
				zip: authenticatedUser.company_zip,
				country: authenticatedUser.company_country,
				longitude: authenticatedUser.company_longitude,
				latitude: authenticatedUser.company_latitude,
				iso_country: authenticatedUser.company_iso_country
			});
		}
	};
	const getAddress = () => {
		return `${
			authenticatedUser.company_street ? authenticatedUser.company_street : ""
		} ${
			authenticatedUser.company_number ? authenticatedUser.company_number : ""
		} ${
			authenticatedUser.company_street || authenticatedUser.company_number
				? ","
				: ""
		} ${authenticatedUser.company_zip ? authenticatedUser.company_zip : ""} ${
			authenticatedUser.company_city ? authenticatedUser.company_city : ""
		} ${
			authenticatedUser.company_country ? authenticatedUser.company_country : ""
		}`;
	};
	return (
		<LocationContainer>
			<Controller
				name="location_type"
				control={control}
				defaultValue={COMPANY_ADDRESS}
				render={({ field: { onChange, value } }) => (
					<InterviewLocation
						onChange={onChange}
						changeLocationType={changeLocationType}
						value={value}
						setAddress={setAddress}
						address={address}
						companyAddress={getAddress()}
						setAddressError={setAddressError}
						addressError={addressError}
						addressTypo={addressTypo}
						label="Job location"
						offer
					/>
				)}
			/>
		</LocationContainer>
	);
}

export default JobLocation;
