import Modal from "common/modal";
import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/IconClose-1.svg";
const CustomizableModel = ({
	onClose,
	children,
	title,
	actionButton,
	maxwidth = "30rem",
	size = "770px",
	displayButtons = true,
	displayCloseButton = true,
	padding = "1.5rem",
	hasCloseIcon = false,
	classNameHeader = "",
	classNameFooter = "",
	classNameContent = "",
	classNameCloseIcon = "",
	boxShadow = "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
	borderRadius = "8px",
	border = "1px solid #e5e5e5",
	textCancelButton = "Cancel"
}) => {
	return (
		<Modal
			size={size}
			maxwidth={maxwidth}
			padding={padding}
			backgroundColor={"rgba(0,0,0,0.25)"}
			borderRadius={borderRadius}
			boxShadow={boxShadow}
			border={border}
			isOnProceed
			onClose={onClose}
		>
			<div className={`${styles.modal__header} ${classNameHeader}`}>
				<h3 className={styles.modal__title}>{title}</h3>
				{hasCloseIcon && (
					<div className={classNameCloseIcon} onClick={onClose}>
						<CloseIcon />
					</div>
				)}
			</div>
			<div className={`${styles.modal__content} ${classNameContent}`}>
				{children}

				{displayButtons && (
					<div className={`${styles.modal__btn_container} ${classNameFooter}`}>
						{displayCloseButton && (
							<button className={styles.modal__btn_cancel} onClick={onClose}>
								{textCancelButton}
							</button>
						)}
						{actionButton}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default CustomizableModel;
