import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import SignUpSuccess from "./SignUpSuccess";
import {
	userEmailSelector,
	userNameSelector
} from "modules/user/selectors/selectors";
import { resendActivateEmail } from "../../../actions/signUpActions";
import { toggleHelpModal } from "modules/app/actions/ListsActions";

import { scrollTo } from "common/Functions";
import { getSignUpFirstStepRoute } from "./SignUpFirstStepContainer";
import Container from "../Container";
import BackButton from "../BackButton";
import Header from "../Header";
import { Box, Flex } from "rebass";

export const getSignUpSuccessRoute = type => {
	return `/sign-up/${type ? type : `:type`}/success`;
};

export class SignUpSuccessContainer extends Component {
	static propTypes = {
		email: PropTypes.string,
		name: PropTypes.string
	};

	componentDidMount() {
		const type = get(this.props, "params.type");
		if (this.props.email === null) {
			browserHistory.push(getSignUpFirstStepRoute(type));
		}
		scrollTo();
	}

	render() {
		return (
			<>
				<Header>
					<Flex height={"100%"} alignItems={"center"}>
						<BackButton />
					</Flex>
				</Header>
				<Box flexGrow={1} display={"flex"} alignItems={"center"}>
					<Container>
						<SignUpSuccess {...this.props} />
					</Container>
				</Box>
			</>
		);
	}
}

const mapStateToProps = state => ({
	email: userEmailSelector(state),
	name: userNameSelector(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ resendActivateEmail, toggleHelpModal }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUpSuccessContainer);
