import React, { useState } from "react";
import _get from "lodash/get";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { SENT, CLIENT } from "config";
import FloatingSideBar from "common/FloatingSideBar";
import CurrencyFormatter from "common/CurrencyFormater";
import {
	Sidebar,
	VacancyInformations,
	PermanentActionsContainer
} from "./styled";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { client } from "lib/api-client";
import { ACCEPT_OFFER, REFUSE_OFFER } from "config/api-endpoints";
import toaster from "common/Toaster";
import { fetchPoke } from "modules/vacancy/actions";
import PermanentActions from "modules/candidate/components/poke/PermanentActions.js";

const acceptOffer = ({ offer_id }) =>
	client(ACCEPT_OFFER, {
		body: {
			offer_id
		}
	});

const refuseOffer = ({ offer_id }) =>
	client(REFUSE_OFFER, {
		body: {
			offer_id
		}
	});

const PokeSidebar = ({
	poke,
	other_benefits,
	salary_range_end,
	salary_range_start,
	payroll_country,
	company_name,
	status,
	ProposeOtherInterview,
	interview,
	setDisplayAcceptInterviewConfirmation,
	toggleCancelApplicationModal,
	symbol,
	payment_time,
	payment_type,
	offer,
	stepperStatus
}) => {
	const [acceptOfferModal, setAcceptOfferModal] = useState(false);
	const [refuseOfferModal, setRefuseOfferModal] = useState(false);

	const dispatch = useDispatch();

	const [acceptOfferMutation, { isLoading: acceptLoading }] = useMutation(
		acceptOffer,
		{
			onSuccess: () => {
				dispatch(fetchPoke({ id: _get(poke, "_id") }));
				setAcceptOfferModal(false);
				toaster.success(
					"the offer proposition has successfully been accepted!"
				);
			},
			onError: err => {
				if (Boolean(err?.detail?.name))
					return toaster.danger(err?.detail?.name);
			}
		}
	);

	const [refuseOfferMutation, { isLoading: refuseLoading }] = useMutation(
		refuseOffer,
		{
			onSuccess: () => {
				dispatch(fetchPoke({ id: _get(poke, "_id") }));
				setRefuseOfferModal(false);
				toaster.success("the offer proposition has successfully been refused!");
			},
			onError: err => {
				if (Boolean(err?.detail?.name))
					return toaster.danger(err?.detail?.name);
			}
		}
	);

	const actions = {
		cancelApplication: () => {
			toggleCancelApplicationModal();
		},
		acceptInterview: () => {
			setDisplayAcceptInterviewConfirmation(true);
		},
		ProposeOtherInterview,
		refuseOffer: () => setRefuseOfferModal(true),
		acceptOffer: () => setAcceptOfferModal(true)
	};

	return (
		<>
			<FloatingSideBar>
				<>
					<Sidebar>
						<PermanentActionsContainer>
							<PermanentActions
								pokeStage={stepperStatus}
								pokeStatus={status}
								interviewStatus={_get(interview, "status")}
								offerStatus={_get(offer, "status")}
								canAccept={
									_get(interview, "last_user_interview_action.type") === CLIENT
								}
								actions={actions}
							/>
						</PermanentActionsContainer>
						<VacancyInformations>
							{status !== SENT && (
								<>
									<h5>Company name</h5>
									<span>{company_name}</span>
									<div className="divider" />
								</>
							)}
							<h5>
								{payment_time} {payment_type} salary
							</h5>
							{!salary_range_start ? (
								"NA"
							) : (
								<span>
									Between{" "}
									<CurrencyFormatter
										cent={salary_range_start}
										symbol={symbol}
									/>{" "}
									and{" "}
									<CurrencyFormatter cent={salary_range_end} symbol={symbol} />
								</span>
							)}
							<div className="divider" />
							<h5>Other benefits</h5>
							<span>{other_benefits || "None"}</span>
							<div className="divider" />
							<h5>Payroll country</h5>
							<span>{payroll_country}</span>
						</VacancyInformations>
					</Sidebar>
				</>
			</FloatingSideBar>
			<ConfirmationModal
				active={acceptOfferModal}
				modalName="accept_offer"
				onClose={() => setAcceptOfferModal(false)}
				firstButton={{
					action: () => acceptOfferMutation({ offer_id: offer._id }),
					label: "Yes"
				}}
				title="Are you sure you would like to accept this offer proposition?"
				// content="Once your application is confirmed, your profile will
				// no longer be anonymous to this company."
				type={ConfirmationTypes.confirmation}
				loading={acceptLoading}
			/>
			<ConfirmationModal
				active={refuseOfferModal}
				modalName="refuse_offer"
				onClose={() => setRefuseOfferModal(false)}
				firstButton={{
					action: () => refuseOfferMutation({ offer_id: offer._id }),
					type: "warning",
					label: "Yes"
				}}
				title="Are you sure you would like to refuse this offer proposition?"
				content="This action is definitive and irreversible"
				type={ConfirmationTypes.error}
				loading={refuseLoading}
			/>
		</>
	);
};

export default PokeSidebar;
