import { DAILY } from "config";
import { DATE_FORMAT, FREELANCER, MAX_HOURS_PER_DAY, CLIENT } from "config";
import {
	convertCalendarDateToMS,
	convertMinsToHrsMins,
	generateId,
	timestampToDateCalendar,
	utcTimeStamp
} from "config/helpers";

export const getHours = ({ time }) => {
	return time.hours() * 60 + time.minutes();
};

export const getPayload = ({ rate, type, time, comment, tag, date }) => {
	let hours = time.hours() * 60 + time.minutes();

	let body = {
		rate: rate,
		is_overtime: type.value === "overtime",
		description: comment,
		date: formatDate(date),
		hours
	};

	if (tag?.value) {
		body.tag = tag.value;
	}
	return body;
};

export const formatDate = value => {
	const date = window.moment(convertCalendarDateToMS(value));
	const current = window.moment();
	date.set({
		hour: current.get("hour"),
		minute: current.get("minute"),
		second: current.get("second")
	});

	return utcTimeStamp({
		date,
		format: DATE_FORMAT,
		manageTime: false
	});
};

export const formatExpense = ({ comment, amount, receipt = "", tag, date }) => {
	let body = {
		date: formatDate(date),
		description: comment,
		document: receipt || "",
		price: Number(amount)
	};

	if (tag?.value) {
		body.tag = tag.value;
	}
	return body;
};

export const formatExpenses = (list, isDraft) => {
	return list?.length
		? list.map(item => {
				let res = formatExpense(item);
				if (isDraft) {
					return {
						...res,
						price: item.amount,
						status: "draft",
						generatedId: item._id
					};
				} else {
					return {
						...res,
						_id: item._id
					};
				}
		  })
		: [];
};

export const getComputeAmountPayload = (list, timesheetId) => {
	return {
		timesheet_id: timesheetId,
		dispute_items: {
			worktimes: {
				edited: [],
				added: list
			},
			expenses: {
				edited: [],
				added: []
			}
		}
	};
};

export const beyondMaxHours = ({
	month,
	disputedWorkTime,
	addedWorkTimes,
	reportings_settings
}) => {
	const addedDates = new Set(
		addedWorkTimes.map(item => {
			const date = window.moment.unix(item.date);
			date.add(1, "hour");
			return date.format("YYYY-MM-DD");
		})
	);

	const hasDateIncluded = date => {
		return addedDates.has(
			window.moment
				.unix(date)
				.add(1, "hour")
				.format("YYYY-MM-DD")
		);
	};

	const worktimes = [
		...month?.worktimes.filter(
			({ _id, date }) =>
				!disputedWorkTime.some(({ worktime_id }) => worktime_id === _id) &&
				hasDateIncluded(date)
		),
		...month?.overtimes.filter(
			({ _id, date }) =>
				!disputedWorkTime.some(({ worktime_id }) => worktime_id === _id) &&
				hasDateIncluded(date)
		),
		...disputedWorkTime.filter(({ date }) => hasDateIncluded(date)),
		...addedWorkTimes
	];

	const worktimesByDate = worktimes.reduce((acc, worktime) => {
		const date = window.moment.unix(worktime.date);
		date.add(1, "hour");
		const dateStr = date.format("YYYY-MM-DD");

		if (!acc[dateStr]) {
			acc[dateStr] = [];
		}
		acc[dateStr].push(worktime);
		return acc;
	}, {});

	let beyondMaxHoursErrors = { worktimes: {}, overtimes: {} };
	let isValid = true;

	const calculTime = item => {
		let entryMinutes = item.hours;
		if (item["hours_representation"] && item.hours < 1) {
			entryMinutes = parseInt(item.hours_representation.split(":")[1], 10);
		} else if (item["hours_representation"] && item.hours >= 1) {
			let hours_rep = item.hours_representation.split(":");
			entryMinutes =
				parseInt(hours_rep[0], 10) * 60 + parseInt(hours_rep[1], 10);
		}
		return entryMinutes;
	};

	for (const data of Object.entries(worktimesByDate)) {
		let totalMinutes = 0;
		if (reportings_settings?.rate_type === DAILY) {
			const filtered_worktimes = data[1].filter(item => !item?.is_overtime);
			const filtered_overtimes = data[1].filter(item => item?.is_overtime);

			for (const item of filtered_worktimes) {
				totalMinutes += calculTime(item);
				if (totalMinutes > reportings_settings?.hours * 60) {
					beyondMaxHoursErrors.worktimes[item._id] = [
						"You've exceeded the allowed hours for this day."
					];
					isValid = false;
				}
			}
			let totalOvertimeMinutes = 0;
			for (const item of filtered_overtimes) {
				totalOvertimeMinutes += calculTime(item);
				if (totalOvertimeMinutes + totalMinutes > MAX_HOURS_PER_DAY * 60) {
					beyondMaxHoursErrors.overtimes[item._id] = [
						"You've exceeded the allowed hours for this day."
					];
					isValid = false;
				}
			}
		} else {
			for (const item of data[1]) {
				totalMinutes += calculTime(item);
				if (totalMinutes > MAX_HOURS_PER_DAY * 60) {
					if (item?.is_overtime == true) {
						beyondMaxHoursErrors.overtimes[item._id] = [
							"You've exceeded the allowed hours for this day."
						];
						isValid = false;
					} else {
						beyondMaxHoursErrors.worktimes[item._id] = [
							"You've exceeded the allowed hours for this day."
						];
						isValid = false;
					}
				}
			}
		}
	}
	return { isValid, beyondMaxHoursErrors };
};

export const validateDate = ({
	worktimes,
	overtimes,
	expenses,
	startDate,
	endDate
}) => {
	let isWithinRange = true;

	const validateItems = items => {
		let categoryErrors = {};

		for (const item of items) {
			const date = formatDate(item.date);
			if (date < startDate || date > endDate) {
				isWithinRange = false;

				if (!categoryErrors[item._id]) {
					categoryErrors[item._id] = [];
				}

				categoryErrors[item._id].push(
					"It seems that you have entered an invalid date."
				);
			}
		}

		return categoryErrors;
	};

	const validateDateErrors = {
		worktimes: validateItems(worktimes),
		overtimes: validateItems(overtimes),
		expenses: validateItems(expenses)
	};

	isWithinRange =
		Object.keys(validateDateErrors.worktimes).length === 0 &&
		Object.keys(validateDateErrors.overtimes).length === 0 &&
		Object.keys(validateDateErrors.expenses).length === 0;

	return { isWithinRange, validateDateErrors };
};

export const getSaveEntriesPayload = (
	{ worktimes, overtimes, expenses, files },
	timesheetId
) => {
	return {
		timesheet_id: timesheetId,
		entries: {
			worktimes: [...worktimes, ...overtimes],
			expenses: expenses,
			attachments: files
		}
	};
};

export const formatFiles = list => {
	if (list?.length)
		return list.map(item => {
			return {
				_id: item.id,
				name: item.file_name,
				path: item.path
			};
		});
	return [];
};

export const formatWorkTimesList = (list, isSavePayload, isDraft, type) => {
	return list?.length
		? list?.map(item => {
				const { rate, hours, is_overtime, ...rest } = getPayload(item);
				if (isDraft) {
					return {
						...rest,
						[`${
							type === CLIENT ? CLIENT : FREELANCER
						}_amount`]: item.calculatedAmount,
						status: "draft",
						generatedId: item._id,
						hours_representation: convertMinsToHrsMins(hours),
						rate,
						hours,
						is_overtime
					};
				} else if (isSavePayload) {
					return { _id: item._id, rate, hours, is_overtime, ...rest };
				} else {
					return { rate, hours, is_overtime };
				}
		  })
		: [];
};

export function convertMinutesToHoursMinutes(minutes) {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	return { hours, minutes: remainingMinutes };
}

export const validateExtraction = (res, setErrorBanner) => {
	let valid = true;
	if (!res.data.is_timesheet) {
		setErrorBanner({
			type: "warning",
			message:
				"Successful upload, the file provided does not contain a timesheet."
		});
		valid = false;
	} else if (res.data.number_of_freelancers !== "single") {
		setErrorBanner({
			type: "warning",
			message:
				"Successful upload, the file provided should contain only one freelancer."
		});
		valid = false;
	} else if (res.data.worktimes?.length + res.data.expenses?.length === 0) {
		setErrorBanner({
			type: "warning",
			message:
				"Successful upload, but no entries could be extracted from the file."
		});
		valid = false;
	}
	return valid;
};

export const convertParse = (list, type) => {
	return list.map(item => {
		const date = timestampToDateCalendar(item.date);
		const comment = item.description;
		if (type === "expense") {
			const amount = `${item.price}`;
			let tmp = {
				...item,
				date,
				amount,
				comment,
				type: { value: type },
				_id: generateId()
			};
			if (item.tag) {
				tmp.tag = { value: item.tag };
			}
			return tmp;
		} else {
			const { hours, minutes } = convertMinutesToHoursMinutes(item.hours);
			const time = window.moment({ hour: hours, minute: minutes });
			const calculatedAmount = item.amount;
			let tmp = {
				...item,
				date,
				calculatedAmount,
				comment,
				time,
				type: { value: type },
				_id: generateId()
			};
			if (item.tag) {
				tmp.tag = { value: item.tag };
			}
			return tmp;
		}
	});
};
