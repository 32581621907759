import React, { useRef } from "react";
import { useLocale } from "react-aria";
import {
	Overlay,
	usePopover,
	useOverlayTrigger,
	useDateSegment,
	useTimeField
} from "react-aria";
import { useTimeFieldState } from "@react-stately/datepicker";
import styles from "./new-time-picker.module.scss";
import { useOverlayTriggerState } from "react-stately";
import { ReactComponent as AlarmClockIcon } from "static/icons/alarm-clock.svg";
import cx from "classnames";
import PopoverContent from "./components/PopoverContent";

export default function NewTimePicker(props) {
	const ref = useRef(null);
	const popoverContentRef = useRef(null);

	const { locale } = useLocale();
	const state = useTimeFieldState({
		...props,
		locale
	});

	const { labelProps, fieldProps } = useTimeField(
		{
			...props,
			onFocus() {
				overlayState.open();
			},
			onBlur({ relatedTarget }) {
				if (
					popoverContentRef.current &&
					popoverContentRef.current.contains(relatedTarget)
				)
					return;
				overlayState.close();
			}
		},
		state,
		ref
	);

	const onOpenChange = visibility => {
		if (!visibility) {
			requestAnimationFrame(() => {
				document.activeElement.blur();
			});
		}
	};

	const overlayState = useOverlayTriggerState({
		onOpenChange
	});

	const { overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		overlayState,
		ref
	);

	return (
		<div className={styles.container}>
			{!!props.label && (
				<div {...labelProps} className={styles.label}>
					{props.label}
				</div>
			)}
			<div
				{...fieldProps}
				ref={ref}
				className={cx(styles.input, props.inputClassName, {
					[styles.greenBorder]: overlayState.isOpen
				})}
			>
				<div className={styles.segments}>
					{state.segments.map((segment, i) => (
						<DateSegment key={i} segment={segment} state={state} />
					))}
				</div>
				<div className={styles.icon}>
					<AlarmClockIcon />
				</div>
			</div>
			{overlayState.isOpen && (
				<Popover triggerRef={ref} state={overlayState}>
					<PopoverContent
						ref={popoverContentRef}
						overlayProps={overlayProps}
						state={state}
					/>
				</Popover>
			)}
		</div>
	);
}

function DateSegment({ segment, state }) {
	const ref = React.useRef(null);
	const { segmentProps } = useDateSegment(segment, state, ref);

	return (
		<div {...segmentProps} ref={ref} className={styles.segment}>
			{segment.text}
		</div>
	);
}

function Popover({ children, offset = 4, state, ...props }) {
	const popoverRef = React.useRef(null);
	const { popoverProps, underlayProps } = usePopover(
		{
			...props,
			offset,
			popoverRef,
			placement: "bottom start"
		},
		state
	);

	return (
		<Overlay>
			<div {...underlayProps} />
			<div {...popoverProps} ref={popoverRef}>
				{children}
			</div>
		</Overlay>
	);
}
