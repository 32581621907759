import styled from "styled-components";

const ToasterContainer = styled.div`
	position: fixed;
	top: 100px;
	right: 30px;
	width: 400px;
	z-index: 999999;
`;

const Toaster = styled.div`
	gap: 12px;
	display: flex;
	color: #1e293b;
	position: relative;
	align-items: flex-start;
	padding: 16px 20px;
	border-radius: 12px;
	background: #ffffff;
	margin-bottom: 15px;
	border: 1px solid #cbd5e1;
	justify-content: space-between;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
		0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

const DangerToaster = styled(Toaster)`
	border: 1px solid #fecaca;
`;

const SuccessToaster = styled(Toaster)`
	border: 1px solid #99ceca;
`;

const WarningToaster = styled(Toaster)`
	border: 1px solid #fef08a;
`;

const InfoToaster = styled(Toaster)`
	border: 1px solid #bfdbfe;
`;

const ToasterTextContainer = styled.div`
	align-items: flex-start;
	display: flex;
	gap: 12px;
`;

const ToasterText = styled.div`
	width: 300px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	font-style: normal;
	font-family: "Roboto";
	.text {
		h2 {
			color: #334155;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 0;
		}
		h3 {
			color: #64748b;
			font-family: "Work Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 0;
		}
	}
`;

const ToasterClose = styled.div`
	height: 24px;
	cursor: pointer;
	:hover svg path {
		stroke: #4b5563;
	}
`;

const getToasterType = type => {
	switch (type) {
		case "danger":
			return DangerToaster;
		case "info":
			return InfoToaster;
		case "warning":
			return WarningToaster;
		default:
			return SuccessToaster;
	}
};

export {
	ToasterContainer,
	ToasterTextContainer,
	getToasterType,
	Toaster,
	ToasterText,
	ToasterClose
};
