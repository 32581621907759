import { v4 as uuid } from "uuid";
export const LANGUAGE_LEVELS_LABELS = [
	"Novice",
	"Advanced beginner",
	"Competent",
	"Proficient",
	"Expert"
];

export const SKILLS_LEVELS_LABELS = [
	"Novice",
	"Advanced beginner",
	"Competent",
	"Proficient",
	"Expert"
];

export const VACANCY_STEP_CARDS_IDS = {
	description: "vacancyStepDescriptionCard",
	salary: "vacancyStepSalaryCard",
	additionalInfo: "vacancyStepAdditionalInfoCard",
	applicants: "vacancyStepApplicantsCard"
};

export const PROFILE_STEP = "PROFILE_STEP";
export const VACANCY_STEP = "VACANCY_STEP";
export const QUESTIONNAIRE_STEP = "QUESTIONNAIRE_STEP";

export const EDITOR_MAX_LENGTH = 10000;

export const NUMBER_KEYS_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

export const operators = [
	{
		_id: uuid(),
		value: "(",
		label: "(",
		isParentheses: true
	},
	{
		_id: uuid(),
		value: ")",
		label: ")",
		isParentheses: true
	},
	{
		_id: uuid(),
		value: "and",
		label: "AND",
		isOperator: true
	},
	{
		_id: uuid(),
		value: "or",
		label: "OR",
		isOperator: true
	}
];
