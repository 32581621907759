import {
	convertCalendarDateToMS,
	dateToTimestamp,
	utcTimeStamp
} from "config/helpers";
import _get from "lodash/get";

export const _mappingValues = (
	values,
	freelancersList,
	consultantList,
	currenciesList,
	showSupplierMode
) => {
	const {
		mission_title,
		rate,
		type,
		rate_type,
		timesheet_frequency,
		po_number,
		business_manager_id,
		departement: { value: departement }
	} = values;

	const site = values.site?.value;

	const currencyValue = values?.currency?.label;

	const currency = currenciesList?.find(({ symbol, name }) => {
		return `${symbol} - ${name}` == currencyValue;
	});

	const freelancer = freelancersList?.data?.find(
		({ id }) => id === values?.freelancer?.value
	);

	const consultant = consultantList?.consultants?.find(
		({ _id }) => _id === values?.consultant?.value
	);

	const startDate = dateToTimestamp(
		`${values.start_date.day}-${values.start_date.month}-${values.start_date.year}`
	);
	const endDate = convertCalendarDateToMS(values.end_date);

	const freelancerPayload = {
		vendor_user_id: freelancer?.profile_id,
		vendor_company_id: freelancer?.company_id
	};
	const consultancyPayload = {
		vendor_user_id: consultant?._id,
		vendor_company_id: values?.supplier?.value,
		consultancy_group_id: consultant?.group_id,
		fixed_fee: 3624
	};

	const restPayload = {
		po_number: po_number,
		title: mission_title,
		start_date: startDate,
		group_id: departement,
		timesheet_frequency,
		site_id: site,
		end_date: utcTimeStamp({ date: endDate, isStart: false }),
		rate: rate,
		type: type == "freelancer" ? "freelancer" : "consultancy",
		currency: currency?.code ?? "",
		allow_overtime: _get(values, "allowSubmission") ? true : false,
		rate_type: rate_type,
		hours: rate_type == "hourly" ? 60 : convertTimeToMinute(values),
		business_manager_id: business_manager_id?.value
	};
	const invoiceDetails = {
		company_name: values.company_name,
		company_timesheet_manager_email: values.company_timesheet_manager_email,
		accounting_manager_email: values.accounting_manager_email,
		selling_price: values.selling_price,
		company_address: values?.company_address,
		company_vat: values.company_vat,
		vat: parseFloat(values.vat) / 100
	};
	const payload = {
		...(type === "freelancer" ? freelancerPayload : consultancyPayload),
		invoice_details: showSupplierMode ? invoiceDetails : undefined,
		...restPayload
	};

	return payload;
};

const convertTimeToMinute = values => {
	const minutes =
		_get(values, "hours").hours() * 60 + _get(values, "hours").minutes();
	return minutes;
};

export const clearMultipleFields = (fieldNames, setValue) => {
	fieldNames.forEach(fieldName => {
		setValue(fieldName, {});
	});
};
