import React, { useEffect } from "react";
import loadable from "loadable-components";
import useBreadcrumbs from "common/Layouts/breadcrumbs.store";
import EvaluationsSettingsPageHeader from "./components/EvaluationsSettingsPageHeader";
import styles from "./evaluation-forms-settings.module.scss";
import EvaluationEmptyBlock from "./components/EvaluationEmptyBlock";
import { NEW_TEMPLATE_SEGMENT_ID } from "./utils/constants";
import { browserHistory } from "react-router";
import { getTemplagetTemplateFromLocalStorage } from "./utils/helpers";
import EvaluationTemplatesList from "./components/EvaluationTemplatesList";

const EvaluationTemplateForm = loadable(() =>
	import(
		/* webpackChunkName: "evaluation-forms-settings" */ "./components/EvaluationTemplateForm"
	)
);

export const getEvaluationTemplateRoute = templateId => {
	return `/settings/evaluations${
		templateId !== undefined
			? templateId
				? `/${templateId}`
				: ""
			: "(/:templateId)"
	}`;
};

export const getEvaluationFormsSettingsPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default function EvaluationFormsSettings({ params: { templateId } }) {
	const setBreadcrumbs = useBreadcrumbs(state => state.setItems);

	useEffect(() => {
		setBreadcrumbs([{ name: "Settings" }, { name: "Evaluation forms" }]);
		const template = getTemplagetTemplateFromLocalStorage();
		if (template) {
			browserHistory.replace(
				getEvaluationTemplateRoute(template._id ?? NEW_TEMPLATE_SEGMENT_ID)
			);
		}
	}, []);

	return (
		<div className={styles.container}>
			<EvaluationsSettingsPageHeader />
			<div className={styles.contentLayout}>
				<div className={styles.sidebar}>
					<EvaluationTemplatesList />
				</div>
				<div className={styles.content}>
					{templateId ? (
						<EvaluationTemplateForm templateId={templateId} />
					) : (
						<EvaluationEmptyBlock />
					)}
				</div>
			</div>
		</div>
	);
}
