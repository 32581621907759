import React from "react";
import { Route, IndexRoute, Redirect } from "react-router";
// import { Redirect } from "react-router-dom";
import loadable from "loadable-components";
import toaster from "common/Toaster";
import { FREELANCER, CLIENT, PERMANENT } from "config";
import { getCookie, getLocalUser } from "config/helpers";
// import hoistNonReactStatics from 'hoist-non-react-statics';
// local storage features
import features from "./config/features/GetFeatureFromLocalStorage";

// NORMAL LOAD due to getRoute static function
// route getters
import { getPermissions as getGdprPermissions } from "./modules/GdprList";
import { getPolicyRoute } from "./common/privacyPolicy/components/PolicyContainer";
import { getChangePasswordRoute } from "./modules/user/components/ChangePasswordContainer";
import { getNotificationsSettingsRoute } from "./modules/notifications/components/settings/NotificationsSettingsContainer";
import {
	getJobViewContainerRoute,
	getJobViewContainerPermission
} from "./modules/TempRequestDetailsModule";
import {
	getJobViewRoute,
	getVacancyViewRoute,
	getJobViewPermission
} from "./modules/RequestView/RequestView";
import { getTermsAndConditionsRoute } from "./common/termsAndConditions/components/TermsAndConditionsContainer";
import {
	getJobSearchRoute,
	getJobSearchPermission
} from "./modules/jobSearch/components/JobSearch";
import {
	getClientPokeViewRoute,
	getClientPokeViewPermission
	// getProfileSearchRoute,
	// getProfileSearchPermission
} from "./modules/vacancy/components";

import {
	getFreelancerBidsListRoute,
	getClientBidViewRoute,
	getFreelancerBidViewRoute,
	getConsultancyBidsListRoute
} from "./modules/bids/components";
import {
	getSignUpSuccessRoute,
	getSignUpFirstStepRoute,
	getSignUpSecondStepRoute
} from "./modules/user/components";
import { getResendActivationRoute } from "modules/user/components/Authentication/ResendActivation";

import {
	getTimesheetTagsRoute,
	getTimesheetTagsPermission
} from "./modules/TimesheetTags";

import {
	getJobProfileRoute,
	getJobRequestRoute,
	getJobPreviewRoute,
	getCreateVacancyRoute,
	getVacancyProfileRoute,
	getVacancyRequestRoute,
	getVacancyPreviewRoute
} from "./modules/job/components";

import {
	getResumeRoute,
	getResumePermission,
	consultantGetClientResumeRoute
} from "./modules/cv/components/ResumeContainer";
import { getIdentityChooserRoute } from "./modules/company/components/IdentityChooser";
import { getCVChooserRoute } from "./modules/company/components/cvchooser/CVChooserContainer";
import { getAccountActivatedRoute } from "./modules/company/components/AccountActivated";
import { getMyCompanyRoute, getMyCompanyPermission } from "./modules/MyCompany";

import {
	getCompanyRoute,
	getCompanyPermission
} from "./modules/company/components/MyCompany";

import { getSignInRoute } from "./modules/user/components/Authentication/Login/index";
import { getSwitchUserRoute } from "./modules/user/components/SwitchUser";
import {
	getMyConsultantsRoute,
	getMyConsultantsPermission
} from "./modules/consultancy/components/MyConsultants";

import {
	getCreateRequestStepTwoRoute,
	getCreateRequestRoute as getNewRequestRoute,
	getCreateRequestPermission as getNewRequestPermission
} from "./modules/CreateRequestModule/CreateRequestModule";

import { CONSULTANCY, COOKIE_TOKEN, getAllPermissions } from "./config";
import { getFreelancerBidsListPermission } from "./modules/bids/components/freelancerBidsList/FreelancerBidsListContainer";
import { getFreelancerBidViewPermission } from "./modules/bids/components/bidview/freelancer/FreelancerBidViewContainer";
import { getClientBidViewPermission } from "./modules/bids/components/bidview/client/ClientBidViewContainer";
import { getCreateVacancyPermission } from "./modules/job/components/CreateJob";

import {
	getMyProfileRoute,
	getMyProfilePermission
} from "./modules/company/components/MyProfile";
import {
	getUserListRoute,
	getUserListPermission
} from "./modules/company/components/UsersList";
import {
	getDocumentsManagement,
	getDocumentsManagementPermission
} from "./modules/company/components/DocumentManagement";
import { getPageCms, getPageCmsPermission } from "./modules/pageCms";
import {
	getVonqContractRoute,
	getVonqContractPermission
} from "./modules/vonqContract";

import {
	getDynamicContentRoute,
	getDynamicContentPermission as getNewDynamicContentPermission
} from "./modules/newDynamicContent/utils/getRouteConfig";
import {
	getListTimesheetsRoute,
	getListTimesheetsPermission
} from "./modules/timesheets/components/ListTimesheets";
import { getNotificationsRoute } from "./modules/notifications/components/NotificationsCenter";
import { getNotificationsClientRoute } from "./modules/notifications/components/NotificationsCenterClient";
import {
	getTimesheetRoute,
	getTimesheetPermission
} from "./modules/timesheets/components";
import { getLogoutRoute } from "./modules/user/components/Logout";
import { getConsultancyBidListPermission } from "./modules/bids/components/consultancyBidList/ConsultancyBidListContainer";
import {
	getConsultancyBidViewRoute,
	getConsultancyBidViewPermission
} from "./modules/bids/components/bidview/consultancy/ConsultancyBidViewContainer";
import {
	getPokeViewContainerRoute,
	getPokeViewContainerPermissions
} from "./modules/candidate/components/poke/PokeViewContainer";
import {
	getCandidateJobListRoute,
	getCandidateJobListPermission
} from "./modules/candidate/components/JobList";

export const VonqContract = loadable(() =>
	import(/* webpackChunkName: "pagescms" */ "./modules/vonqContract")
);

import { getDashboardRoute, getDashboardPermission } from "./modules/dashboard";
import { getHomeRoute, getHomePermission } from "./modules/home";
import {
	getVacancyListRoute,
	getVacancyListPermission
} from "./modules/vacancy/containers/VacancyListContainer";
import {
	getUserFilesPermission,
	getUserFilesRoute
} from "./modules/user/components/UserFiles";
import {
	getOfflineSearchRoute,
	getOfflineApplyRoute
} from "modules/candidate/components/search/OfflineSearch";
import { getPermanentUnsubscribeRoute } from "modules/candidate/components/unsubscribe";

import ApplicationsList, {
	getApplicationsListRoute,
	getApplicationsListPermissions
} from "modules/Applications/List";
import PokesList, {
	getPokesListRoute,
	getPokesListPermissions
} from "modules/vacancy/components/Pokeslist";

import {
	getClientMissionsListRoute,
	getClientMissionsListPermissions
} from "modules/MissionsList/MissionsList";

import ShortlistsList, {
	getShortlistslistRoute,
	getShortlistslistPermissions
} from "modules/vacancy/components/ShortlistsList";

import InterestConfirmedList, {
	getInterestConfirmedListRoute,
	getInterestConfirmedListPermissions
} from "modules/vacancy/components/ListInterestConfirmed";

import ClientBidsList, {
	getClientBidsListPermissions,
	getClientBidsListRoute
} from "modules/bids/components/ClientBidList";

import {
	getTimesheetListRoute,
	getTimesheetListPermissions,
	getTimesheetClientRoute
} from "modules/timesheetsModule/components/TimesheetsList";
import JobExpired, { getJobExpiredRoute } from "common/JobExpired";
import SuppliersManagement, {
	getSuppliersManagement,
	getSuppliersManagementPermission
} from "modules/SuppliersManagement";

import {
	getPublishJobRoute,
	getPublishJobPermission
} from "modules/publishJob";
import CreateRequest, {
	getCreateRequestPermission,
	getCreateRequestRoute
} from "modules/job/components/createRequest";

import {
	getMissionsContainerRoute,
	getMissionsContainerPermissions
} from "modules/contract/components/MissionsContainer";
import ProposeInterview from "modules/vacancy-view/components/ProposeInterview";

import { getSearchSupplierPermission } from "./modules/consultancy/search";
import { getMySuppliersPermission } from "./modules/consultancy/my-suppliers";
import { getViewSupplierPermission } from "./modules/consultancy/view-supplier";
import {
	getSupplierCompanyPermission,
	getSupplierCompanyRoute
} from "./modules/consultancy/settings/company";
import {
	getSupplierInvitationsPermission,
	getSupplierInvitationsRoute
} from "./modules/consultancy/invitations";
import ViewInvitation, {
	getSupplierInvitationPermission,
	getSupplierInvitationRoute
} from "./modules/consultancy/invitations/ViewInvitation";
import {
	getTasksSupplierPermission,
	getTasksIdRouteSupplier
} from "modules/TasksSupplier";
import {
	getTasksListRoute,
	getTasksListPermission,
	getTaskDetailsRoute
} from "./modules/TasksList/TasksList";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import SearchCandidatePool, {
	getSearchCandidatePool,
	getSearchCandidatePoolPermission
} from "modules/SearchCandidate";
import {
	getPermissions as getWherebyVirtualRoomPermission,
	getRoute as getWherebyVirtualRoomRoute
} from "modules/WhereByVirtualRoom";

import {
	getPermissions as getWherebyPermanentVirtualRoomPermission,
	getRoute as getWherebyPermanentVirtualRoomRoute
} from "modules/WherebyPermanentVirtualRoom";

import {
	getPermissions as getPermanentJobDetailsPermission,
	getPermanentJobDetailsRoute
} from "modules/permanent/jobDetailsPage";

import {
	getPermissions as getConsultantVirtualRoomPermission,
	getRoute as getConsutantVirtualRoomRoute
} from "modules/WhereByConsultantVirtualRoom";

import {
	getPermissions as getFreelancerVirtualRoomPermission,
	getRoute as getFreelancerVirtualRoomRoute
} from "modules/WherebyFreelancerVirtualRoom";
import {
	getNewCreateVacancyRoute,
	getNewVacancyRequestRoute,
	getNewCreateVacancyPermission,
	getNewVacancyProfileRoute,
	getNewVacancyQuestionnaireRoute
} from "modules/vacancy/create-vacancy/CreateVacancyModule";
import { getRecoverPasswordRoute } from "modules/user/components/Authentication/recover/RecoverPassword";
import { getGeneralSettingsRoute } from "./modules/user/components/GeneralSettings";
import { getFollowedCompaniesRoute } from "./modules/permanent/companies";

import {
	getGdprPermanentViewPermission,
	getGdprPermanentViewRoute
} from "modules/GdprPermanentView/GdprPermanentView";

import { getGdprListRoute } from "./modules/GdprList/GdprList";
import {
	getFreelancersListPermission,
	getFreelancersListRoute
} from "modules/freelancers";
import {
	getClientResumePermission,
	getClientResumeRoute
} from "modules/ConsultantFreelancerView/utils/constants";

import {
	getSetupGuidePermissions,
	getSetupGuideRoute
} from "modules/setupGuide";
import {
	getPublishRequestPermission,
	getPublishRequestRoute
} from "modules/publishRequest";

import {
	getNotePermission,
	getNoteTableRoute
} from "./modules/notes/notes-list";
import {
	getCreateMissionsRoute,
	getCreateMissionsRoutePermissions
} from "modules/CreateMission/CreateMission";
import {
	getMissionDetailsContainerPermission,
	getMissionDetailsContainerRoute
} from "modules/MissionsDetails";
import { getRequestsPermission, getRequestsRoute } from "modules/Requests";

import {
	getEvaluationFormsSettingsPermissions,
	getEvaluationTemplateRoute
} from "./modules/EvaluationFormsSettings";
import {
	getCustomFieldsManagerRoute,
	getCustomFieldsManagerRoutePermission
} from "modules/CustomFieldsManager/getRouteConfig";

export const AuthenticationLayout = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "modules/user/components/Authentication/Layout"
	)
);

//CandidateProfile
export const PermanentProfile = loadable(() =>
	import(/* webpackChunkName: "user" */ "modules/candidate/Profile")
);
export const GdprPermanentView = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "modules/GdprPermanentView/GdprPermanentView"
	)
);
// USER
export const HomePage = loadable(() =>
	import(/* webpackChunkName: "user" */ "./common/LandingPage")
);
export const RecoverPassword = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/recover/RecoverPassword"
	)
);
export const ResetPassword = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/recover/ResetPassword"
	)
);
export const ActivateAccount = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/ActivateAccount/index"
	)
);
export const SignIn = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/Login"
	)
);
export const SwitchUser = loadable(() =>
	import(/* webpackChunkName: "user" */ "./modules/user/components/SwitchUser")
);
export const FirstSignIn = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/Login/FirstLogin"
	)
);
export const SignUpFirstStepContainer = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/SignUp/SignUpFirstStepContainer"
	)
);
export const SignUpSuccessContainer = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/SignUp/SignUpSuccessContainer"
	)
);
export const SignUpSecondStepContainer = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/SignUp/SignUpSecondStepContainer"
	)
);
export const ChangePasswordContainer = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/ChangePasswordContainer"
	)
);
export const ResendActivation = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/ResendActivation"
	)
);

const WherebyFreelancerVirtualRoom = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/WherebyFreelancerVirtualRoom/WherebyFreelancerVirtualRoom"
	)
);

const WhereByConsultantVirtualRoom = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/WhereByConsultantVirtualRoom/WhereByConsultantVirtualRoom"
	)
);

const WhereByVirtualRoom = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/WhereByVirtualRoom/WhereByVirtualRoom"
	)
);

const WherebyPermanentVirtualRoom = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/WherebyPermanentVirtualRoom/WherebyPermanentVirtualRoom"
	)
);

const PermanentJobDetails = loadable(() =>
	import(/* webpackChunkName: "user" */ "./modules/permanent/jobDetailsPage")
);

export const GeneralSettings = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/GeneralSettings"
	)
);
export const NewSignUp = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/SignUp/NewSignUp"
	)
);
export const HelpPage = loadable(() =>
	import(
		/* webpackChunkName: "user" */ "./modules/user/components/Authentication/help/HelpContainer"
	)
);

export const TimesheetAccessCode = loadable(() =>
	import("./modules/TimesheetAccessCode")
);

export const ExternalTimesheetDetails = loadable(() =>
	import(
		/* webpackChunkName: "external-timesheet-details" */ "./modules/ExternalTimesheetDetails/"
	)
);

// CV
export const CompanyProfiles = loadable(() =>
	import(/* webpackChunkName: "cv" */ "./modules/cv/components/CompanyProfiles")
);

// PREEFALNCER/CONSULTANT PROFILE
export const FREELANCER_CONSULTANT_PROFILE = loadable(() =>
	import(
		/* webpackChunkName: "cv" */ "./modules/ConsultantFreelancerView/ConsultantFreelancerView"
	)
);

// PROFILE
export const ResumeContainer = loadable(() =>
	import(/* webpackChunkName: "cv" */ "./modules/cv/components/ResumeContainer")
);

// COMPANY
export const UsersList = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/UsersList"
	)
);
export const FollowedCompanies = loadable(() =>
	import(/* webpackChunkName: "company" */ "./modules/permanent/companies")
);

// DOCUMENT MANAGEMENT
export const DocumentManagement = loadable(() =>
	import(
		/* webpackChunkName: "documentmanagement" */ "./modules/company/components/DocumentManagement"
	)
);

// PAGES CMS
export const PagesCms = loadable(() =>
	import(/* webpackChunkName: "pagescms" */ "./modules/pageCms")
);

// PAGES DYNAMIC CONTENT

export const NewDynamicContent = loadable(() =>
	import(
		/* webpackChunkName: "NewDynamicContent" */ "./modules/newDynamicContent"
	)
);
// PAGES CUSTOM FIELDS MANAGER

export const CustomFieldsManager = loadable(() =>
	import(
		/* webpackChunkName: "CustomFieldsMAnager" */ "./modules/CustomFieldsManager"
	)
);

export const Company = loadable(() =>
	import(/* webpackChunkName: "company" */ "./modules/company/components")
);
export const AnswerInvitation = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/AnswerInvitation"
	)
);

export const MyCompanyClient = loadable(() =>
	import(/* webpackChunkName: "company" */ "./modules/MyCompany")
);

export const MyCompany = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/MyCompany"
	)
);

export const MyProfile = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/MyProfile"
	)
);

export const GdprList = loadable(() =>
	import(/* webpackChunkName: "company" */ "./modules/GdprList/GdprList")
);

export const SetupGuide = loadable(() =>
	import(/* webpackChunkName: "company" */ "./modules/setupGuide")
);

export const CompanyPayment = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/AccountPayment"
	)
);
export const Congratulation = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/AccountActivated"
	)
);
export const CVChooserContainer = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/cvchooser/CVChooserContainer"
	)
);
export const IdentityChooser = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/company/components/IdentityChooser"
	)
);

// Requests

export const Request = loadable(() =>
	import(/* webpackChunkName: "requests" */ "./modules/Requests")
);

//BID
export const FreelancerBidViewContainer = loadable(() =>
	import(
		/* webpackChunkName: "bid" */ "./modules/bids/components/bidview/freelancer/FreelancerBidViewContainer"
	)
);
export const FreelancerBidsListContainer = loadable(() =>
	import(
		/* webpackChunkName: "bid" */ "./modules/bids/components/freelancerBidsList/FreelancerBidsListContainer"
	)
);
export const ClientBidViewContainer = loadable(() =>
	import(
		/* webpackChunkName: "bid" */ "./modules/bids/components/bidview/client/ClientBidViewContainer"
	)
);
export const ConsultancyBidList = loadable(() =>
	import(
		/* webpackChunkName: "bid" */ "./modules/bids/components/consultancyBidList/ConsultancyBidListContainer"
	)
);
export const ConsultancyBidViewContainer = loadable(() =>
	import(
		/* webpackChunkName: "bid" */ "./modules/bids/components/bidview/consultancy/ConsultancyBidViewContainer"
	)
);
//JOB
export const VacancyListContainer = loadable(() =>
	import(/* webpackChunkName: "job" */ "modules/Vacancies")
);
export const JobProfileContainer = loadable(() =>
	import(
		/* webpackChunkName: "job" */ "./modules/job/components/jobprofile/JobProfileContainer"
	)
);
export const JobRequestContainer = loadable(() =>
	import(
		/* webpackChunkName: "job" */ "./modules/job/components/jobrequest/JobRequestContainer"
	)
);
export const JobPreview = loadable(() =>
	import(/* webpackChunkName: "job" */ "./modules/job/components/JobPreview")
);
export const JobSearch = loadable(() =>
	import(
		/* webpackChunkName: "job" */ "./modules/jobSearch/components/JobSearch"
	)
);
export const ProfileSearch = loadable(() =>
	import(
		/* webpackChunkName: "vacancy" */ "./modules/vacancy/components/newSearch"
	)
);
export const PotentialMatches = loadable(() =>
	import(
		/* webpackChunkName: "vacancy" */ "./modules/vacancy/components/potentialMatches"
	)
);

export const ClientPokeViewContainer = loadable(() =>
	import(
		/* webpackChunkName: "vacancy" */ "./modules/vacancy/components/pokeView/client/ClientPokeViewContainer"
	)
);
export const RequestView = loadable(() =>
	import(/* webpackChunkName: "job" */ "./modules/RequestView/RequestView")
);
export const VacancyView = loadable(() =>
	import(/* webpackChunkName: "job" */ "./modules/vacancy-view")
);
export const JobViewContainer = loadable(() =>
	import(/* webpackChunkName: "job" */ "./modules/TempRequestDetailsModule")
);

export const CreateJob = loadable(() =>
	import(/* webpackChunkName: "job" */ "./modules/job/components/CreateJob")
);
export const jobListContainer = loadable(() =>
	import(
		/* webpackChunkName: "job" */ "./modules/job/components/joblist/jobListContainer"
	)
);

export const CandidateJobSearch = loadable(() =>
	import(/* */ "./modules/candidate/components/search")
);
export const NotesList = loadable(() =>
	import(/* */ "./modules/notes/notes-list")
);

export const MissionsContainer = loadable(() =>
	import(
		/* webpackChunkName: "contract" */ "./modules/contract/components/MissionsContainer"
	)
);

export const CreateMission = loadable(() =>
	import(
		/* webpackChunkName: "contract" */ "./modules/CreateMission/CreateMission"
	)
);

// TIMESHEET
export const TimeSheets = loadable(() =>
	import(/* webpackChunkName: "contract" */ "./modules/timesheets/components")
);

export const TimeSheetsClient = loadable(() =>
	import(
		/* webpackChunkName: "contract" */ "./modules/timesheets/components/TimesheetClientContainer"
	)
);

export const ListTimeSheets = loadable(() =>
	import(
		/* webpackChunkName: "contract" */ "./modules/timesheets/components/ListTimesheets"
	)
);
export const TimeSheetsTags = loadable(() =>
	import(/* webpackChunkName: "contract" */ "./modules/TimesheetTags")
);
// OTHERS
export const NotificationsCenter = loadable(() =>
	import(
		/* webpackChunkName: "other" */ "./modules/notifications/components/NotificationsCenter"
	)
);

export const NotificationsClientCenter = loadable(() =>
	import(
		/* webpackChunkName: "other" */ "./modules/notifications/components/NotificationsCenterClient"
	)
);

export const OfflineJobSearch = loadable(() =>
	import(
		/* webpackChunkName: "offline search" */ "modules/candidate/components/search/OfflineSearch"
	)
);

export const App = loadable(() =>
	import(/* webpackChunkName: "other" */ "./App")
);
export const NotFound = loadable(() =>
	import(/* webpackChunkName: "other" */ "./common/NotFound")
);
export const TermsAndConditionsContainer = loadable(() =>
	import(
		/* webpackChunkName: "other" */ "./common/termsAndConditions/components/TermsAndConditionsContainer"
	)
);
export const NotificationsSettingsContainer = loadable(() =>
	import(
		/* webpackChunkName: "other" */ "./modules/notifications/components/settings/NotificationsSettingsContainer"
	)
);
export const PolicyContainer = loadable(() =>
	import(
		/* webpackChunkName: "other" */ "./common/privacyPolicy/components/PolicyContainer"
	)
);
export const Logout = loadable(() =>
	import(/* webpackChunkName: "logout" */ "./modules/user/components/Logout")
);
// consultancy

export const MyConsultants = loadable(() =>
	import(
		/* webpackChunkName: "consultant" */ "./modules/consultancy/components/MyConsultants"
	)
);

export const UserFiles = loadable(() =>
	import(
		/* webpackChunkName: "company" */ "./modules/user/components/UserFiles"
	)
);

export const PublishJob = loadable(() =>
	import(/* webpackChunkName: "company" */ "modules/publishJob")
);

export const PublishRequest = loadable(() =>
	import(/* webpackChunkName: "company" */ "modules/publishRequest")
);

// permanent
export const CandidateJobList = loadable(() =>
	import(
		/* webpackChunkName: "permanent" */ "./modules/candidate/components/JobList"
	)
);

// Freelancers Redesign
export const FreelancersList = loadable(() => import("./modules/freelancers"));

export const PokeViewContainer = loadable(() =>
	import(
		/* webpackChunkName: "permanent" */ "./modules/candidate/components/poke/PokeViewContainer"
	)
);

const Dashboard = loadable(() =>
	import(/* webpackChunkName: "consultant" */ "./modules/dashboard")
);

const Home = loadable(() =>
	import(/* webpackChunkName: "home" */ "./modules/home")
);

const MissionDetailsContainer = loadable(() =>
	import(
		/* webpackChunkName: "MissionViewContainer" */ "./modules/MissionsDetails/MissionDetailsContainer"
	)
);

// delete account
const DeleteAccount = loadable(() =>
	import(
		/* webpackChunkName: "delete" */ "modules/user/components/DeleteAccount"
	)
);

// PermanentUnsubscribe
const PermanentUnsubscribe = loadable(() =>
	import(
		/* webpackChunkName: "PermanentUnsubscribe" */ "modules/candidate/components/unsubscribe"
	)
);

const ClientMissionsList = loadable(() =>
	import(
		/* webpackChunkName: "MissionsList" */ "modules/MissionsList/MissionsList"
	)
);

const TimesheetList = loadable(() =>
	import(
		/* webpackChunkName: "TimesheetsList" */ "modules/timesheetsModule/components/TimesheetsList"
	)
);

export const CreateVacancyModule = loadable(() =>
	import(
		/* webpackChunkName: "vacancy" */ "./modules/vacancy/create-vacancy/CreateVacancyModule"
	)
);
/*
 * Suppliers
 * */
const SearchSupplier = loadable(() =>
	import(/* webpackChunkName: "suppliers" */ "./modules/consultancy/search")
);
const MySuppliers = loadable(() =>
	import(
		/* webpackChunkName: "suppliers" */ "./modules/consultancy/my-suppliers"
	)
);
const ViewSupplier = loadable(() =>
	import(
		/* webpackChunkName: "suppliers" */ "./modules/consultancy/view-supplier"
	)
);

const SupplierCompany = loadable(() =>
	import(
		/* webpackChunkName: "suppliers" */ "./modules/consultancy/settings/company"
	)
);

const ClientInvitations = loadable(() =>
	import(
		/* webpackChunkName: "suppliers" */ "./modules/consultancy/invitations"
	)
);

const CreateRequestModule = loadable(() =>
	import(
		/* webpackChunkName: "suppliers" */ "./modules/CreateRequestModule/CreateRequestModule"
	)
);

//Tasks
export const TasksList = loadable(() =>
	import(/* webpackChunkName: "tasks" */ "./modules/TasksList/TasksList")
);

export const TasksSupplier = loadable(() =>
	import(/* webpackChunkName: "tasks-supplier" */ "./modules/TasksSupplier")
);

const EvaluationFormsSettings = loadable(() =>
	import(
		/* webpackChunkName: "evaluations-settings" */ "./modules/EvaluationFormsSettings"
	)
);

const checkAuth = ({ location: { pathname, search } }, replace) => {
	if (getLocalUser() || getCookie(COOKIE_TOKEN)) return;

	replace({
		pathname: getSignInRoute(),
		state: { nextPathname: pathname, redirectTo: pathname + search }
	});
};

const checkUserType = ({ params, location }, replace) => {
	if (params && params.type) {
		if (![PERMANENT, FREELANCER, CLIENT, CONSULTANCY].includes(params.type)) {
			replace({
				pathname: getSignUpRoute(),
				state: { nextPathname: location.pathname }
			});
		}
	}
};

const isAnonymous = (nextState, replace) => {
	if (!!getLocalUser() || getCookie(COOKIE_TOKEN)) {
		replace({
			pathname: "/",
			state: { nextPathname: nextState.location.pathname }
		});
	}
};

const checkAuthAndType = (nextState, replace) => {
	const user = getLocalUser();
	if (user) {
		user.company_type = user.company_type ? user.company_type : user.type;
	}
	let permissions = nextState.routes[1].permissions.type;
	let allowed = false;
	if (!user) {
		checkAuth(nextState, replace);
	} else {
		allowed = permissions.some(
			row =>
				user.company_type === row.name &&
				(user.active_role === "admin" || row.roles.includes(user.role_name))
		);
		if (!allowed) {
			toaster.danger("You don't have enough permissions to access this page");
			replace({
				pathname: "/",
				state: { nextPathname: nextState.location.pathname }
			});
		}
	}
};

const getSignUpRoute = () => `/sign-up`;
//const redirectToSignUp = () => (window.location.href = SIGN_UP_URL);
const redirectToCandidateSearch = () =>
	(window.location.href = "/candidates/search");

export default (
	<Route path="/" component={App}>
		<IndexRoute component={HomePage} />
		<Route component={ProposeInterview} path="/propose-interview" />
		{features.isFeatureEnabled("signup") && (
			<Route
				path={getSignUpRoute()}
				onEnter={isAnonymous}
				component={AuthenticationLayout}
			>
				<IndexRoute component={NewSignUp} />
				<Route
					path={getSignUpFirstStepRoute()}
					component={SignUpFirstStepContainer}
					onEnter={checkUserType}
				/>
				<Route
					path={getSignUpSecondStepRoute()}
					component={SignUpSecondStepContainer}
					onEnter={checkUserType}
				/>
				<Route
					path={getSignUpSuccessRoute()}
					component={SignUpSuccessContainer}
				/>
				<Route path={getResendActivationRoute()} component={ResendActivation} />
			</Route>
		)}
		<Route path={"/ask-question"} component={HelpPage} />
		<Route path={"/report-issue"} component={HelpPage} />
		<Route
			path={"/access-code/:timesheetId/:accessToken"}
			component={TimesheetAccessCode}
		/>
		<Route
			path={"/external-timesheet-details/:timesheetId/:urlToken"}
			component={ExternalTimesheetDetails}
		/>
		{features.isFeatureEnabled("signin") && (
			<Route
				path="/sign-in(/:jobId)"
				component={SignIn}
				onEnter={isAnonymous}
			/>
		)}
		<Route
			path={getSwitchUserRoute()}
			component={SwitchUser}
			onEnter={isAnonymous}
		/>
		{features.isFeatureEnabled("recover_password") && (
			<Route path={getRecoverPasswordRoute()} component={RecoverPassword} />
		)}
		{features.isFeatureEnabled("signin") && (
			<Route
				path={getLogoutRoute()}
				component={Logout}
				onEnter={checkAuthAndType}
				permissions={getAllPermissions()}
			/>
		)}
		{features.isFeatureEnabled("reset_password") && (
			<Route path="/reset-password/:uuid/:token" component={ResetPassword} />
		)}
		{features.isFeatureEnabled("activate_account") && (
			<Route
				path="/activate-account/:uuid/:token/:type"
				component={ActivateAccount}
				onEnter={isAnonymous}
			/>
		)}
		{<Route path="/delete/:uuid/:token" component={DeleteAccount} />}
		{features.isFeatureEnabled("change_password") && (
			<Route
				path={getChangePasswordRoute()}
				onEnter={checkAuthAndType}
				component={ChangePasswordContainer}
				title="Change your password"
				permissions={getAllPermissions()}
			/>
		)}
		{features.isFeatureEnabled("change_password") && (
			<Route
				path={getGeneralSettingsRoute()}
				onEnter={checkAuthAndType}
				component={GeneralSettings}
				title="General settings"
				permissions={getAllPermissions()}
			/>
		)}
		{features.isFeatureEnabled("get_user_list") && (
			<Route
				path={getUserListRoute()}
				component={UsersList}
				onEnter={checkAuthAndType}
				title="Users list"
				permissions={getUserListPermission()}
			/>
		)}
		<Route
			path={getFollowedCompaniesRoute()}
			component={FollowedCompanies}
			onEnter={checkAuthAndType}
			title="Companies"
			permissions={getAllPermissions()}
		/>
		<Route
			path={getDashboardRoute()}
			permissions={getDashboardPermission()}
			onEnter={checkAuthAndType}
			component={Dashboard}
			title="Dashboard"
		/>
		<Route
			path={getHomeRoute()}
			permissions={getHomePermission()}
			onEnter={checkAuthAndType}
			component={Home}
			title="Home"
		/>
		<Route
			path={getCandidateJobListRoute()}
			component={CandidateJobList}
			onEnter={checkAuthAndType}
			title="My Jobs"
			permissions={getCandidateJobListPermission()}
		/>
		{/* freelancers redesign */}
		<Route
			path={getFreelancersListRoute()}
			permissions={getFreelancersListPermission()}
			component={FreelancersList}
			onEnter={checkAuthAndType}
			title="Freelancers"
		/>
		<Route
			path={getGdprListRoute()}
			permissions={getGdprPermissions()}
			component={GdprList}
			onEnter={checkAuthAndType}
			title="GDPR list"
		/>
		<Route
			title="Set-up guide"
			component={SetupGuide}
			onEnter={checkAuthAndType}
			path={getSetupGuideRoute()}
			permissions={getSetupGuidePermissions()}
		/>
		<Route
			path={getVacancyListRoute()}
			permissions={getVacancyListPermission()}
			onEnter={checkAuthAndType}
			component={VacancyListContainer}
			title="Vacancies"
		/>
		<Route
			path={getMyCompanyRoute()}
			component={MyCompanyClient}
			onEnter={checkAuthAndType}
			title="Legal entity"
			permissions={getMyCompanyPermission()}
		/>
		<Route
			path={getCompanyRoute()}
			component={MyCompany}
			onEnter={checkAuthAndType}
			title="Legal entity"
			permissions={getCompanyPermission()}
		/>
		<Route
			path={getSupplierCompanyRoute()}
			component={SupplierCompany}
			onEnter={checkAuthAndType}
			title="Legal entity"
			permissions={getSupplierCompanyPermission()}
		/>
		<Route
			path="/settings/profiles"
			component={CompanyProfiles}
			onEnter={checkAuthAndType}
			permissions={getAllPermissions()}
		/>
		<Route
			path={getMyProfileRoute()}
			component={MyProfile}
			onEnter={checkAuthAndType}
			title="My profile"
			permissions={getMyProfilePermission()}
		/>

		<Route
			path={getDynamicContentRoute()}
			permissions={getNewDynamicContentPermission()}
			onEnter={checkAuthAndType}
			component={NewDynamicContent}
			title="Dynamic Content"
		/>
		<Route
			path={getCustomFieldsManagerRoute()}
			permissions={getCustomFieldsManagerRoutePermission()}
			onEnter={checkAuthAndType}
			component={CustomFieldsManager}
			title="Custom Fields Manager"
		/>
		<Route
			path={getDocumentsManagement()}
			permissions={getDocumentsManagementPermission()}
			onEnter={checkAuthAndType}
			component={DocumentManagement}
			title="Document requirement management"
		/>
		<Route
			path={getVonqContractRoute()}
			permissions={getVonqContractPermission()}
			onEnter={checkAuthAndType}
			component={VonqContract}
			title="Vonq contract"
		/>
		<Route
			path={getPageCms()}
			permissions={getPageCmsPermission()}
			onEnter={checkAuthAndType}
			component={PagesCms}
			title="Employer branding"
		/>
		{features.isFeatureEnabled("create_company") && (
			<Route
				path="/company"
				component={Company}
				onEnter={checkAuthAndType}
				permissions={getAllPermissions()}
				exact
			>
				<Route path={getIdentityChooserRoute()} component={IdentityChooser} />
				<Route path={getCVChooserRoute()} component={CVChooserContainer} />
				<Route path={getAccountActivatedRoute()} component={Congratulation} />
			</Route>
		)}
		<Route
			path="/invitation-company/:uuid/:token"
			component={AnswerInvitation}
		/>
		<Route
			path="/first-login/:type"
			component={FirstSignIn}
			onEnter={isAnonymous}
		/>
		<Route
			path={getResumeRoute()}
			component={ResumeContainer}
			onEnter={checkAuthAndType}
			title="My profile"
			permissions={getResumePermission()}
		/>
		<Route
			path={getMyConsultantsRoute()}
			component={MyConsultants}
			onEnter={checkAuthAndType}
			title="My consultants"
			permissions={getMyConsultantsPermission()}
		/>
		<Route
			path={getNewRequestRoute()}
			component={CreateRequestModule}
			onEnter={checkAuthAndType}
			title="Create request"
			permissions={getNewRequestPermission()}
		/>
		<Route
			path={getCreateRequestStepTwoRoute()}
			component={CreateRequestModule}
			onEnter={checkAuthAndType}
			title="Create request"
			permissions={getNewRequestPermission()}
		/>
		<Route
			path={getClientResumeRoute()}
			component={FREELANCER_CONSULTANT_PROFILE}
			onEnter={checkAuthAndType}
			permissions={getClientResumePermission()}
		/>
		<Route
			path={consultantGetClientResumeRoute()}
			component={ResumeContainer}
			onEnter={checkAuthAndType}
			permissions={getClientResumePermission()}
		/>
		<Route
			path={getJobViewRoute()}
			component={RequestView}
			onEnter={checkAuthAndType}
			title="Request details"
			permissions={getJobViewPermission()}
		/>
		<Route
			path={getVacancyViewRoute()}
			component={VacancyView}
			onEnter={checkAuthAndType}
			title="Vacancy details"
			permissions={getJobViewPermission()}
		/>
		<Route
			path={getWherebyPermanentVirtualRoomRoute()}
			component={WherebyPermanentVirtualRoom}
			onEnter={checkAuthAndType}
			title="Virtual room"
			permissions={getWherebyPermanentVirtualRoomPermission()}
		/>
		<Route
			title="Job details"
			onEnter={checkAuthAndType}
			component={PermanentJobDetails}
			path={getPermanentJobDetailsRoute()}
			permissions={getPermanentJobDetailsPermission()}
		/>
		<Route
			path={getConsutantVirtualRoomRoute()}
			component={WhereByConsultantVirtualRoom}
			onEnter={checkAuthAndType}
			title="Virtual room"
			permissions={getConsultantVirtualRoomPermission()}
		/>
		<Route
			path={getWherebyVirtualRoomRoute()}
			component={WhereByVirtualRoom}
			onEnter={checkAuthAndType}
			title="WhereBy Virtual room"
			permissions={getWherebyVirtualRoomPermission()}
		/>
		<Route
			path={getFreelancerVirtualRoomRoute()}
			component={WherebyFreelancerVirtualRoom}
			onEnter={checkAuthAndType}
			title="Virtual room"
			permissions={getFreelancerVirtualRoomPermission()}
		/>
		<Route
			path={getPermanentProfileRoute()}
			component={PermanentProfile}
			onEnter={checkAuthAndType}
			title="Canddidate profile"
			permissions={getJobViewPermission()}
		/>
		<Route
			path={getGdprPermanentViewRoute()}
			component={GdprPermanentView}
			onEnter={checkAuthAndType}
			title="GDPR profile"
			permissions={getGdprPermanentViewPermission()}
		/>
		<Route
			path={getJobViewContainerRoute()}
			component={JobViewContainer}
			onEnter={checkAuthAndType}
			permissions={getJobViewContainerPermission()}
		/>
		<Route
			path={getClientMissionsListRoute()}
			component={ClientMissionsList}
			onEnter={checkAuthAndType}
			permissions={getClientMissionsListPermissions()}
			title="Opened missions"
		/>

		<Route
			path={getCreateMissionsRoute()}
			component={CreateMission}
			onEnter={checkAuthAndType}
			permissions={getCreateMissionsRoutePermissions()}
			title="Opened missions"
		/>
		<Route
			path={getMissionsContainerRoute()}
			component={MissionsContainer}
			onEnter={checkAuthAndType}
			permissions={getMissionsContainerPermissions()}
			title="Opened missions"
		/>
		<Route
			onEnter={checkAuthAndType}
			path={getRequestsRoute()}
			component={Request}
			title="Requests"
			permissions={getRequestsPermission()}
		/>
		<Route
			path={getFreelancerBidsListRoute()}
			component={FreelancerBidsListContainer}
			onEnter={checkAuthAndType}
			title="Bids list"
			permissions={getFreelancerBidsListPermission()}
		/>
		<Route
			path={getFreelancerBidViewRoute()}
			component={FreelancerBidViewContainer}
			onEnter={checkAuthAndType}
			title="My bid"
			permissions={getFreelancerBidViewPermission()}
		/>
		<Route
			path={getConsultancyBidViewRoute()}
			component={ConsultancyBidViewContainer}
			onEnter={checkAuthAndType}
			title="My bid"
			permissions={getConsultancyBidViewPermission()}
		/>
		<Route
			path={getPokeViewContainerRoute()}
			component={PokeViewContainer}
			onEnter={checkAuthAndType}
			permissions={getPokeViewContainerPermissions()}
		/>
		<Route
			path={getClientBidViewRoute()}
			component={ClientBidViewContainer}
			onEnter={checkAuthAndType}
			title="My bid"
			permissions={getClientBidViewPermission()}
		/>
		<Route
			path={getConsultancyBidsListRoute()}
			component={ConsultancyBidList}
			onEnter={checkAuthAndType}
			permissions={getConsultancyBidListPermission()}
		/>
		<Route
			path={getJobSearchRoute()}
			component={JobSearch}
			onEnter={checkAuthAndType}
			title="Search jobs"
			permissions={getJobSearchPermission()}
		/>
		{/* <Route
			path={getProfileSearchRoute()}
			component={ProfileSearch}
			onEnter={checkAuthAndType}
			title="Search Profiles"
			permissions={getProfileSearchPermission()}
		/> */}
		<Route
			path={getSearchCandidatePool()}
			permissions={getSearchCandidatePoolPermission()}
			onEnter={checkAuthAndType}
			component={redirectToCandidateSearch}
			title="Search Candidates"
		/>
		<Route
			path={"/candidates/search"}
			permissions={getSearchCandidatePoolPermission()}
			onEnter={checkAuthAndType}
			component={SearchCandidatePool}
			title="Search Candidates"
		/>
		<Route
			path={getNoteTableRoute()}
			permissions={getNotePermission()}
			onEnter={checkAuthAndType}
			component={NotesList}
			title="Notes"
		/>
		<Route
			path={getClientPokeViewRoute()}
			component={ClientPokeViewContainer}
			onEnter={checkAuthAndType}
			permissions={getClientPokeViewPermission()}
		/>
		<Route
			path={getCreateRequestRoute()}
			component={CreateRequest}
			onEnter={checkAuthAndType}
			permissions={getCreateRequestPermission()}
		>
			<IndexRoute component={JobProfileContainer} active={0} />
			<Route
				path={getJobProfileRoute()}
				component={JobProfileContainer}
				active={0}
			/>
			<Route
				path={getJobRequestRoute()}
				component={JobRequestContainer}
				active={1}
			/>
			<Route path={getJobPreviewRoute()} component={JobPreview} active={2} />
		</Route>
		<Route
			path={getCreateVacancyRoute()}
			component={CreateJob}
			onEnter={checkAuthAndType}
			permissions={getCreateVacancyPermission()}
		>
			<IndexRoute component={JobProfileContainer} active={0} />
			<Route
				path={getVacancyProfileRoute()}
				component={JobProfileContainer}
				active={0}
			/>
			<Route
				path={getVacancyRequestRoute()}
				component={JobRequestContainer}
				active={1}
			/>
			<Route
				path={getVacancyPreviewRoute()}
				component={VacancyView}
				active={3}
			/>
		</Route>
		{/* create vacancy new version */}
		<Route
			path={getNewCreateVacancyRoute()}
			component={CreateVacancyModule}
			onEnter={checkAuthAndType}
			permissions={getNewCreateVacancyPermission()}
		/>
		<Route
			path={getNewVacancyRequestRoute()}
			component={CreateVacancyModule}
			onEnter={checkAuthAndType}
			permissions={getNewCreateVacancyPermission()}
		/>
		<Route
			path={getNewVacancyProfileRoute()}
			component={CreateVacancyModule}
			onEnter={checkAuthAndType}
			permissions={getNewCreateVacancyPermission()}
		/>
		<Route
			path={getNewVacancyQuestionnaireRoute()}
			component={CreateVacancyModule}
			onEnter={checkAuthAndType}
			permissions={getNewCreateVacancyPermission()}
		/>
		<Route
			path={getTimesheetRoute()}
			component={TimeSheets}
			onEnter={checkAuthAndType}
			permissions={getTimesheetPermission()}
			title="Timesheet"
		/>
		<Route
			path={getTimesheetTagsRoute()}
			onEnter={checkAuthAndType}
			permissions={getTimesheetTagsPermission()}
			title="Timesheet tags"
			component={TimeSheetsTags}
		/>
		<Route
			path={getTasksListRoute()}
			onEnter={checkAuthAndType}
			permissions={getTasksListPermission()}
			title="Tasks"
			component={TasksList}
		/>
		<Route
			title="Task details"
			component={TasksList}
			onEnter={checkAuthAndType}
			path={getTaskDetailsRoute()}
			permissions={getTasksListPermission()}
		/>

		<Route
			path={getTasksIdRouteSupplier()}
			onEnter={checkAuthAndType}
			permissions={getTasksSupplierPermission()}
			title="Tasks"
			component={TasksSupplier}
		/>
		<Route
			path={getSuppliersManagement()}
			component={SuppliersManagement}
			onEnter={checkAuthAndType}
			permissions={getSuppliersManagementPermission()}
			title="Suppliers Management"
		/>
		<Route
			path={getNotificationsRoute()}
			component={NotificationsCenter}
			onEnter={checkAuthAndType}
			permissions={getAllPermissions()}
			title="Notifications center"
		/>
		<Route
			path={getNotificationsClientRoute()}
			component={NotificationsClientCenter}
			onEnter={checkAuthAndType}
			permissions={getAllPermissions()}
			title="Notifications center"
		/>
		<Route
			path={getListTimesheetsRoute()}
			component={ListTimeSheets}
			onEnter={checkAuthAndType}
			permissions={getListTimesheetsPermission()}
			title="Timesheets"
		/>
		<Route
			path={getTermsAndConditionsRoute()}
			component={TermsAndConditionsContainer}
		/>
		<Route path={getPolicyRoute()} component={PolicyContainer} />
		<Route
			path={getNotificationsSettingsRoute()}
			component={NotificationsSettingsContainer}
			onEnter={checkAuthAndType}
			permissions={getAllPermissions()}
		/>
		<Route
			path={getUserFilesRoute()}
			component={UserFiles}
			onEnter={checkAuthAndType}
			title="Files"
			permissions={getUserFilesPermission()}
		/>
		<Route
			path={getPublishJobRoute()}
			component={PublishJob}
			onEnter={checkAuthAndType}
			title="Publish vacancy"
			permissions={getPublishJobPermission()}
		/>
		<Route
			path={getPublishRequestRoute()}
			component={PublishRequest}
			onEnter={checkAuthAndType}
			title="Publish request"
			permissions={getPublishRequestPermission()}
		/>
		<Route
			path={getOfflineSearchRoute()}
			component={OfflineJobSearch}
			title={"Search"}
		/>
		<Route
			path={getOfflineApplyRoute()}
			component={OfflineJobSearch}
			title={"Search"}
		/>
		<Route
			path={getPermanentUnsubscribeRoute()}
			component={PermanentUnsubscribe}
			title="unsubscribe"
		/>
		<Route
			path={getApplicationsListRoute()}
			component={ApplicationsList}
			title="Applications"
			permissions={getApplicationsListPermissions()}
			onEnter={checkAuthAndType}
		/>
		<Route
			path={getPokesListRoute()}
			component={PokesList}
			title="Pokes"
			permissions={getPokesListPermissions()}
			onEnter={checkAuthAndType}
		/>
		<Route
			path={getClientBidsListRoute()}
			component={ClientBidsList}
			title="Bids"
			onEnter={checkAuthAndType}
			permissions={getClientBidsListPermissions()}
		/>
		<Route
			path={getShortlistslistRoute()}
			component={ShortlistsList}
			title="Sourced"
			permissions={getShortlistslistPermissions()}
			onEnter={checkAuthAndType}
		/>
		<Route
			path={getInterestConfirmedListRoute()}
			component={InterestConfirmedList}
			permissions={getInterestConfirmedListPermissions()}
			title="Interest confirmed"
			onEnter={checkAuthAndType}
		/>
		<Route
			path={getTimesheetListRoute()}
			component={TimesheetList}
			title="Timesheets"
			permissions={getTimesheetListPermissions()}
			onEnter={checkAuthAndType}
		/>
		<Route
			path={getTimesheetClientRoute()}
			component={TimeSheetsClient}
			onEnter={checkAuthAndType}
			permissions={getTimesheetListPermissions()}
			title="Timesheet"
		/>
		<Route
			path={getMissionDetailsContainerRoute()}
			component={MissionDetailsContainer}
			title="Missions"
			onEnter={checkAuthAndType}
			permissions={getMissionDetailsContainerPermission()}
		/>
		<Route
			path={"/supplier/search"}
			component={SearchSupplier}
			title={"Search suppliers"}
			onEnter={checkAuthAndType}
			permissions={getSearchSupplierPermission()}
		/>
		<Route
			path={"/supplier/added"}
			component={MySuppliers}
			title={"My suppliers"}
			onEnter={checkAuthAndType}
			permissions={getMySuppliersPermission()}
		/>
		<Route
			path={"/supplier/:id"}
			component={ViewSupplier}
			onEnter={checkAuthAndType}
			permissions={getViewSupplierPermission()}
		/>
		<Route
			path={getSupplierInvitationsRoute()}
			component={ClientInvitations}
			onEnter={checkAuthAndType}
			permissions={getSupplierInvitationsPermission()}
		/>
		<Route
			path={getSupplierInvitationRoute()}
			component={ViewInvitation}
			onEnter={checkAuthAndType}
			permissions={getSupplierInvitationPermission()}
		/>
		<Route
			path={getEvaluationTemplateRoute()}
			component={EvaluationFormsSettings}
			onEnter={checkAuthAndType}
			permissions={getEvaluationFormsSettingsPermissions()}
		/>
		<Route path={getJobExpiredRoute()} component={JobExpired} />
		<Route path="/404" component={NotFound} />
		<Redirect from="*" to="/404" />
	</Route>
);
