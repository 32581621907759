import { createCalendar } from "@internationalized/date";
import { useCalendar } from "react-aria";
import { useCalendarState } from "@react-stately/calendar";
import React from "react";
import { CalendarGrid } from "../CalendarGrid";
import { CalendarGridHeader } from "../CalendarGridHeader";
import styles from "./calendar.module.scss";
import { useLocale } from "react-aria";

import cx from "classnames";

const Calendar = props => {
	const { locale } = useLocale();

	let state = useCalendarState({
		...props,
		locale,
		createCalendar
	});

	let ref = React.useRef();
	let { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(
		props,
		state,
		ref
	);

	return (
		<div
			{...calendarProps}
			ref={ref}
			className={cx(styles.calendar, {
				[styles.fullWidth]: props.isMultiple
			})}
		>
			<CalendarGridHeader
				prevButtonProps={prevButtonProps}
				nextButtonProps={nextButtonProps}
				title={title}
				classNames={{
					h40: styles.h40,
					whiteButton: styles.whiteButton,
					bigText: styles.bigText
				}}
			/>
			<div className={props.className}>
				{props.children}
				<CalendarGrid
					isMultiple={props.isMultiple}
					state={state}
					list={props.list}
				/>
			</div>
		</div>
	);
};

export default Calendar;
