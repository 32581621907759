//TO DO Get "Next Profile ID"
import React, { useState, useRef } from "react";
import useNotesStore from "modules/notes/notes.store";
import { v4 as uuid } from "uuid";
import useOnClickOutside from "hooks/useOnClickOutside";
import { queryCache } from "react-query";
import { browserHistory } from "react-router";
import get from "lodash/get";
import { ArrowIcon } from "./icons";
import usePoolStore from "modules/SearchCandidate/hooks/zustandPool";
import useShortList from "modules/SearchCandidate/components/ShortListDrawer/useShortList";
import styles from "./header.module.scss";
import useSendVacancy from "modules/SearchCandidate/components/SendVacancy/useSendVacancy";
import { CANDIDATE } from "config";
import HidePermanentlyModal from "common/ProfileActions/HidePermanentlyModal";
import DeleteModal from "modules/vacancy/components/candidatePool/ModalDeleteProfil";
import toaster from "common/Toaster";
import useDeleteProfile from "../../api/useDeleteProfile";
import HeaderButton from "../../../../SearchCandidate/HeaderButton";
import { HOW_TO_SOURCE_AND_INVITE_CANDIDATE } from "config/onboarding";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const ProfileActions = ({
	profileId,
	firstName,
	lastName,
	candidate,
	canDelete,
	canPoke,
	mergeProfiles,
	mergeProfileButtonClicked,
	showAddProfileToPoolButton,
	addProfileToPoolButtonClicked
}) => {
	const { setSelectData, setProfileId, setView } = usePoolStore();
	const [
		displayHidePermanentlyModal,
		setDisplayHidePermanentlyModal
	] = useState(false);

	const [deleteProfileModal, setDeleteProfileModal] = useState(false);
	const { setIsOpen } = useSendVacancy();
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();
	const { setIsOpen: openDrawerShortList } = useShortList();
	const menuActionRef = useRef(null);
	const [menuAction, setMenuAction] = useState(false);

	useOnClickOutside(menuActionRef, () => {
		setMenuAction(false);
	});

	const openTaskDrawer = () => {
		openDrawerTask(true);
		setEntity(CANDIDATE);
		setSelectedEntityOption({
			label: `${firstName} ${lastName}`,
			value: profileId
		});
	};

	const [mutateProfil, { isLoading }] = useDeleteProfile({
		onSuccess: () => {
			toaster.success("profile Deleted");
			queryCache.invalidateQueries("my-candidates-profiles");
			browserHistory.push("/vacancies/search");
		},
		onError: error => {
			toaster.danger(
				get(error, "detail.name") ? error.detail.name : error.detail
			);
		}
	});

	const deletPendingProfile = () => mutateProfil({ profile_ids: [profileId] });

	const sendVacancy = () => {
		setSelectData({
			mode: "select",
			selected_candidates: [profileId],
			preview_mode: {
				first_name: firstName,
				last_name: lastName
			},
			total_item: 1
		});
		setView("candidate");
		setProfileId(profileId);
		setIsOpen(true);
	};
	const hidePermanently = () => setDisplayHidePermanentlyModal(true);
	const deleteProfile = () => setDeleteProfileModal(true);
	const addToVacancy = () => {
		setSelectData({
			mode: "select",
			selected_candidates_for_ShortList: [profileId],
			total_item: 1
		});
		openDrawerShortList(true);
		setProfileId(profileId);
		queryCache.invalidateQueries("getVacanciesShortList");
	};

	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));
	const showPopup = () => {
		addNote({
			id: uuid(),
			payload: { candidate },
			canSelectCandidate: true
		});
	};

	return (
		<div className={styles.blockCta}>
			<div className={styles.actionsMenu}>
				{!mergeProfiles && !showAddProfileToPoolButton && (
					<>
						<button
							className={styles.moreActionBtn}
							onClick={() => setMenuAction(true)}
						>
							More actions
							<ArrowIcon />
						</button>
						{menuAction && (
							<div className={styles.subMenu} ref={menuActionRef}>
								<ul>
									{canPoke && (
										<li>
											<button onClick={sendVacancy}>Send vacancy</button>
										</li>
									)}
									<li>
										<button onClick={openTaskDrawer}>Add task</button>
									</li>
									<li>
										<button onClick={showPopup}>Add note</button>
									</li>
									<li>
										<button onClick={hidePermanently}>Hide permanently</button>
									</li>
									{canDelete && (
										<li>
											<button onClick={deleteProfile}>Delete</button>
										</li>
									)}
								</ul>
							</div>
						)}
					</>
				)}
			</div>
			{mergeProfiles && (
				<button
					className={styles.secondaryButton}
					onClick={mergeProfileButtonClicked}
				>
					Merge profiles
				</button>
			)}
			{showAddProfileToPoolButton && (
				<button
					className={styles.secondaryButton}
					onClick={addProfileToPoolButtonClicked}
				>
					Add to my candidates
				</button>
			)}
			{!mergeProfiles && !showAddProfileToPoolButton && (
				<>
					<HeaderButton
						onClick={addToVacancy}
						text="Add to vacancy"
						className="buttonAddNotes"
						data-onboarding-step={`${HOW_TO_SOURCE_AND_INVITE_CANDIDATE}-5`}
					/>
					<HidePermanentlyModal
						active={displayHidePermanentlyModal}
						closeModal={() => setDisplayHidePermanentlyModal(false)}
						profile_id={profileId}
					/>
					{deleteProfileModal && (
						<DeleteModal
							closeModal={() => setDeleteProfileModal(false)}
							deletPendingProfile={deletPendingProfile}
							profileId={[profileId]}
							isLoading={isLoading}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default ProfileActions;
