import { scroller } from "react-scroll";
import axios from "axios";
import _get from "lodash/get";
import _includes from "lodash/includes";
import _values from "lodash/values";
import _isObject from "lodash/isObject";
import _pickBy from "lodash/pickBy";
import _flatMapDeep from "lodash/flatMapDeep";
import _isNumber from "lodash/isNumber";
import {
	DATE_FORMAT,
	RESPONSE_OK,
	GOOGLE_API_KEY,
	NO_DATA_PLACEHOLDER,
	HIREME_FEE,
	FIXED_FEE
} from "../config";

import { getLocalUser } from "config/helpers";
import moment from "moment-timezone";

export const prepareForSelect = sectors => {
	if (sectors && sectors.length) {
		return sectors.reduce((list, sector) => {
			list.push({
				value: sector._id,
				label: sector.name,
				children: sector.children || null
			});
			return list;
		}, []);
	}
};

export const prettyBytes = num => {
	if (typeof num !== "number" || isNaN(num))
		throw new TypeError("Expected a number");
	let exponent;
	let unit;
	const neg = num < 0;
	const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	if (neg) num = -num;
	if (num < 1) return (neg ? "-" : "") + num + " B";
	exponent = Math.min(
		Math.floor(Math.log(num) / Math.log(1000)),
		units.length - 1
	);
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
	unit = units[exponent];
	return (neg ? "-" : "") + num + " " + unit;
};
/*to use scrollToFirstError :
	wrap your component with `Element` and add name={name} scrollto="scroll-to-error"
	also add onSubmitFail: errors => scrollToFirstError(errors) to reduxForm
	*/
export function scrollToFirstError(errorList) {
	// to get the right order in the dom
	const domErrors = document.querySelectorAll(`[scrollto="scroll-to-error"]`);
	// error fields names gotten from backend
	const errorNamesList = getErrorFieldNames(errorList);

	if (!errorNamesList || !errorNamesList.length || !domErrors.length) return;

	for (let errorIndex in domErrors) {
		const elementName =
			domErrors[errorIndex].getAttribute &&
			domErrors[errorIndex].getAttribute("name");
		if (elementName && _includes(errorNamesList, elementName)) {
			scroller.scrollTo(elementName, { offset: -50, smooth: true });
			break;
		}
	}
}

export function scrollToElement(name, elementHeight = 300) {
	const fixedHFHeight = 395; /* 60px fixed header height + 315px fixed footer height*/
	const DocSH = document.documentElement.scrollHeight;
	const WinH = window.innerHeight;
	let offset = (DocSH - WinH - elementHeight + fixedHFHeight) / 2;
	offset = offset < fixedHFHeight ? fixedHFHeight / 2 : offset;
	scroller.scrollTo(name, { offset, smooth: true });
}

function getErrorFieldNames(obj, name = "") {
	if (!obj) return;
	const errorArr = [];
	errorArr.push(
		Object.keys(obj)
			.map(key => {
				const next = obj[key];
				if (next) {
					if (typeof next === "string") {
						return name + key;
					}
					if (next.map) {
						errorArr.push(
							next
								.map((item, index) =>
									getErrorFieldNames(item, `${name}${key}[${index}].`)
								)
								.filter(o => o)
						);
					}
				}
				return null;
			})
			.filter(o => o)
	);

	return _flatMapDeep(errorArr);
}

export function phoneDataFunction(phone_country, phone_number) {
	let phoneData = "";
	let countryPhone = phone_country ? phone_country.value : "BE";
	phone_number = phone_number.replace(/\s/g, "");
	phoneData = `${countryPhone} ${phone_number}`;
	return phoneData;
}

export const mergeFeatures = globalFeatures => {
	const user = getLocalUser();
	if (!user) return globalFeatures;
	const userFeatures = _get(user, "features", []).map(feature =>
		feature.replace("feature_", "")
	);

	Object.keys(globalFeatures.features).forEach(key => {
		if (key !== "timestamp")
			globalFeatures.features[key].active = userFeatures.indexOf(key) !== -1;
	});
	return globalFeatures;
};

export function isDateValid(date, isTimestamp = false, format = DATE_FORMAT) {
	if (isTimestamp) return window.moment.unix(date).isValid();
	return window.moment(date, format, true).isValid();
}

export const formatDate = (date, isTimestamp = true, format = DATE_FORMAT) => {
	const _isDateValid = isDateValid(date, isTimestamp, format);
	if (_isDateValid) {
		if (isTimestamp) return window.moment.unix(date).format(format);
		return window.moment(date).format(format);
	}
	return NO_DATA_PLACEHOLDER;
};

export function parsePhoneNumber(phone) {
	if (!phone) return;
	const phoneNumber = phone.includes(" ") ? phone.split(" ") : [];
	return phoneNumber.length ? phoneNumber[1] : phone;
}

export function objectCleaner(data) {
	return _pickBy(data, _isNotEmpty);
}

function _isNotEmpty(value) {
	return _isNumber(value)
		? false
		: value !== undefined && value !== null && value !== "";
}

export function capitalize(value) {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function toEuro(cent) {
	return parseFloat(cent) / 100;
}

export function geolocate() {
	return new Promise((resolve, reject) => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(pos => {
				axios(
					`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&key=${GOOGLE_API_KEY}`
				)
					.then(response => {
						if (response && response.status === RESPONSE_OK) {
							const address = response.data.results[0];
							resolve({
								name: address.formatted_address,
								latLng: {
									lat: address.geometry.location.lat,
									lon: address.geometry.location.lng
								}
							});
						}
					})
					.catch(error => reject(error));
			});
		} else {
			reject("Geolocation is not supported by your browser");
		}
	});
}

export function scrollTo(xpos = 0, ypos = 0) {
	window.scrollTo(xpos, ypos);
}

export const excerpt = (text = "", max = 10) => {
	return text.length > max ? `${text.substring(0, max)}...` : text;
};

export function hiremeFee(hourlyRate) {
	return hourlyRate / HIREME_FEE - hourlyRate;
}

export function getHourlyCost(hourly_rate, vmsSettings, reportingSetting) {
	const isFeeFixed = _get(vmsSettings, "fee_type") === FIXED_FEE;
	const margin = (100 - _get(vmsSettings, "margin")) / 100;
	const cost = isFeeFixed
		? hourly_rate + _get(vmsSettings, `${reportingSetting.rate_type}_amount`)
		: hourly_rate / margin;
	return cost;
}
export function getFileExtension(filename) {
	return (
		filename &&
		filename
			.split(".")
			.pop()
			.toLowerCase()
	);
}

export function getFileName(value) {
	const fileChunks = (value || "").split(".");
	const len = fileChunks.length;
	if (len === 1) return fileChunks[0];
	return fileChunks.slice(0, len - 1).join("");
}

export function isEmpty(obj) {
	if (_isObject(obj)) {
		return !_values(obj).some(val => _isNotEmpty(val));
	}
	return false;
}

export function isAddressComplete(
	obj,
	exceptions = ["box", "longitude", "latitude"]
) {
	if (_isObject(obj)) {
		return !Object.keys(obj).some(
			val => !_isNotEmpty(obj[val]) && !exceptions.includes(val)
		);
	}
	return false;
}

export const dateCalendar = date => {
	if (
		moment.unix(date).isBetween(moment().startOf("day"), moment().endOf("day"))
	) {
		return moment.unix(date).fromNow();
	}
	if (
		moment.unix(date).isBetween(
			moment()
				.subtract(1, "days")
				.startOf("day"),
			moment()
				.subtract(1, "days")
				.endOf("day")
		)
	) {
		return "Yesterday";
	}
	if (
		moment
			.unix(date)
			.isBetween(moment().startOf("isoWeek"), moment().endOf("isoWeek"))
	) {
		return moment.unix(date).format("dddd, Do YYYY");
	}
	if (moment.unix(date).isBefore(moment().startOf("isoWeek"))) {
		return moment.unix(date).format("DD/MM/YYYY");
	}
};
