import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import SignUpSecondStep from "./SignUpSecondStep";
import {
	loadingSelector,
	userEmailSelector,
	userNameSelector
} from "../../../selectors/selectors";
import { signup } from "../../../actions/signUpActions";
import { fillHelpForm } from "modules/app/actions/ListsActions";
import { SIGN_UP_EXISTING_EMAIL } from "config";
import Container from "../Container";
import BackButton from "../BackButton";
import Header from "../Header";
import { Flex } from "rebass";

export const getSignUpSecondStepRoute = (type = null) => {
	let route = `/sign-up/${type ? type : `:type`}/vat`;
	return route;
};

export class SignUpSecondStepContainer extends Component {
	componentDidMount() {
		const { userId } = this.props;
		if (!userId) browserHistory.goBack();
	}

	handleSignup = formProps => {
		const { dispatch, params, userId } = this.props;
		return dispatch(
			signup({ type: params.type, id: userId, ...formProps })
		).catch(error => {
			throw error;
		});
	};

	handleClaimAccount = ({ vat }) => {
		const { dispatch, email, name } = this.props;
		var fields = {};
		fields = {
			help: "",
			action: "Send",
			email,
			name,
			title: "Claim this VAT/Registration number",
			type: SIGN_UP_EXISTING_EMAIL,
			subject: `Claim this VAT/Registration number`,
			message: `I legally represent the company/legal entity with VAT/registration number ${vat}.\n\nPlease get back to me to grant me access to the account.`
		};

		dispatch(fillHelpForm(fields));
	};

	render() {
		const { loading } = this.props;
		return (
			<>
				<Header>
					<Flex height={"100%"} alignItems={"center"}>
						<BackButton />
					</Flex>
				</Header>
				<Container style={{ paddingTop: 18, paddingBottom: 18 }}>
					<div style={{ height: 40 }}></div>
					<SignUpSecondStep
						onSignUp={this.handleSignup}
						type={_get(this.props, "params.type")}
						onClaimAccount={this.handleClaimAccount}
						isLoading={loading}
					/>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => ({
	loading: loadingSelector(state),
	userId: state.signup.userId,
	email: userEmailSelector(state),
	name: userNameSelector(state)
});

export default connect(mapStateToProps)(SignUpSecondStepContainer);
