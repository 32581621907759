import { yupResolver } from "@hookform/resolvers/yup";
import { timestampToDateCalendar } from "config/helpers";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const useEditMissionForm = ({
	startDate,
	endDate,
	price,
	po_number,
	description,
	canUserEdit,
	invoiceDetails,
	sellingPrice,
	isSupplierMode
}) => {
	const schema = yup.object().shape({
		rate: yup.mixed().required(`Please define a rate.`),
		end_date: yup
			.mixed()
			.required("Please define an end date")
			.test(
				"minValue",
				"Ensure the end date is after the start date.",
				function(end_date) {
					const { start_date } = this.parent;
					if (end_date < start_date) return false;
					return true;
				}
			),
		showSupplierMode: yup.boolean(),
		vat: yup
			.number()
			.nullable()
			.transform((value, originalValue) => {
				return originalValue === "" ? null : value;
			})
			.notRequired()
			.test("is-valid-vat", "The Vat must be between 0 and 100", value => {
				if (value === null || value === undefined) return true;
				const vatPercentage = value / 100;

				return vatPercentage >= 0 && vatPercentage <= 100;
			}),
		company_name: yup.string().when("showSupplierMode", {
			is: true,
			then: yup.string().required("Company name is required")
		}),
		po_number: yup.string(),
		company_timesheet_manager_email: yup
			.string()
			.email("Invalid email")
			.when("showSupplierMode", {
				is: true,
				then: yup.string().required("Timesheet Manager Email is required")
			}),
		accounting_manager_email: yup
			.string()
			.email("Invalid email")
			.when("showSupplierMode", {
				is: true,
				then: yup.string().required("Accounting Manager Email is required")
			}),
		...(!!isSupplierMode && {
			selling_price: yup.number().when("showSupplierMode", {
				is: true,
				then: yup
					.number()
					.min(1, "Selling price must be different from 0")
					.typeError("You must enter a number")
			})
		})
	});
	return useForm({
		defaultValues: {
			start_date: timestampToDateCalendar(startDate),
			end_date: timestampToDateCalendar(endDate),
			rate: price,
			company_name: invoiceDetails?.company_name,
			vat: invoiceDetails?.vat || "",
			company_vat: invoiceDetails?.company_vat || "",
			company_address: invoiceDetails?.company_address || "",
			company_timesheet_manager_email:
				invoiceDetails?.company_timesheet_manager_email,
			accounting_manager_email: invoiceDetails?.accounting_manager_email,
			po_number: po_number,
			selling_price: invoiceDetails?.selling_price || sellingPrice,
			description
		},
		resolver: canUserEdit ? yupResolver(schema) : undefined
	});
};
