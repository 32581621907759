import * as React from "react";
import { $isCodeHighlightNode } from "@lexical/code";
import {
	INSERT_UNORDERED_LIST_COMMAND,
	INSERT_ORDERED_LIST_COMMAND
} from "@lexical/list";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
	$getSelection,
	$isRangeSelection,
	$isTextNode,
	COMMAND_PRIORITY_LOW,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND
} from "lexical";
import { createPortal } from "react-dom";
import { getDOMRangeRect } from "../../utils/getDOMRangeRect";
import { setFloatingElemPosition } from "../../utils/setFloatingElemPosition";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { ReactComponent as ULIcon } from "static/icons/ul-icon.svg";
import { ReactComponent as OLIcon } from "static/icons/ol-icon.svg";
import { ReactComponent as LinkIcon } from "static/icons/link-to-icon.svg";
import "./index.css";
function TextFormatFloatingToolbar({
	editor,
	anchorElem,
	isBold,
	isItalic,
	isUnderline,
	isStrikethrough,
	isLink,
	setIsLinkEditMode
}) {
	const popupCharStylesEditorRef = useRef(null);
	const updateTextFormatFloatingToolbar = useCallback(() => {
		const selection = $getSelection();
		const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
		const nativeSelection = window.getSelection();
		if (popupCharStylesEditorElem === null) {
			return;
		}
		const rootElement = editor.getRootElement();
		if (
			selection !== null &&
			nativeSelection !== null &&
			!nativeSelection.isCollapsed &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
			setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorElem);
		}
	}, [editor, anchorElem]);
	useEffect(() => {
		const scrollerElem = anchorElem.parentElement;
		const update = () => {
			editor.getEditorState().read(() => {
				updateTextFormatFloatingToolbar();
			});
		};
		window.addEventListener("resize", update);
		if (scrollerElem) {
			scrollerElem.addEventListener("scroll", update);
		}
		return () => {
			window.removeEventListener("resize", update);
			if (scrollerElem) {
				scrollerElem.removeEventListener("scroll", update);
			}
		};
	}, [editor, updateTextFormatFloatingToolbar, anchorElem]);
	useEffect(() => {
		editor.getEditorState().read(() => {
			updateTextFormatFloatingToolbar();
		});
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateTextFormatFloatingToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateTextFormatFloatingToolbar();
					return false;
				},
				COMMAND_PRIORITY_LOW
			)
		);
	}, [editor, updateTextFormatFloatingToolbar]);

	const insertLink = useCallback(() => {
		if (!isLink) {
			setIsLinkEditMode(true);
			editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
		} else {
			setIsLinkEditMode(false);
			editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
		}
	}, [editor, isLink, setIsLinkEditMode]);

	return (
		<div ref={popupCharStylesEditorRef} className="floating-text-format-popup">
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
				}}
				className={"popup-item spaced " + (isBold ? "active" : "")}
				aria-label="Format text as bold"
			>
				<i className="format bold" />
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
				}}
				className={"popup-item spaced " + (isItalic ? "active" : "")}
				aria-label="Format text as italics"
			>
				<i className="format italic" />
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
				}}
				className={"popup-item spaced " + (isUnderline ? "active" : "")}
				aria-label="Format text to underlined"
			>
				<i className="format underline" />
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
				}}
				className={"popup-item spaced " + (isStrikethrough ? "active" : "")}
				aria-label="Format text with a strikethrough"
			>
				<i className="format strikethrough" />
			</button>
			<button
				onClick={() => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)}
				className={"popup-item spaced " + (isStrikethrough ? "active" : "")}
				aria-label="Format text with a strikethrough"
			>
				<ULIcon className="format strikethrough" />
			</button>
			<button
				onClick={() => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)}
				className={"popup-item spaced " + (isStrikethrough ? "active" : "")}
				aria-label="Format text with a strikethrough"
			>
				<OLIcon className="format strikethrough" />
			</button>
			<button
				onClick={insertLink}
				className={"popup-item spaced " + (isLink ? "active" : "")}
				aria-label="Format text with a strikethrough"
			>
				<LinkIcon className="format strikethrough" />
			</button>
		</div>
	);
}
function useFloatingTextFormatToolbar(
	editor,
	anchorElem,
	setShowToolbar,
	showToolbaar,
	setIsLinkEditMode
) {
	const [isText, setIsText] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);
	const [isStrikethrough, setIsStrikethrough] = useState(false);
	const [isLink, setIsLink] = useState(false);

	const updatePopup = useCallback(() => {
		editor.getEditorState().read(() => {
			// Should not to pop up the floating toolbar when using IME input
			if (editor.isComposing()) {
				return;
			}
			const selection = $getSelection();
			const nativeSelection = window.getSelection();
			const rootElement = editor.getRootElement();
			if (
				nativeSelection !== null &&
				(!$isRangeSelection(selection) ||
					rootElement === null ||
					!rootElement.contains(nativeSelection.anchorNode))
			)
				if (!$isRangeSelection(selection)) {
					return;
				}

			const node = getSelectedNode(selection);

			// Update text format
			setIsBold(selection.hasFormat("bold"));
			setIsItalic(selection.hasFormat("italic"));
			setIsUnderline(selection.hasFormat("underline"));
			setIsStrikethrough(selection.hasFormat("strikethrough"));
			// Update links
			if (
				nativeSelection !== null &&
				(!$isRangeSelection(selection) ||
					rootElement === null ||
					!rootElement.contains(nativeSelection.anchorNode))
			) {
				setIsText(false);
				return;
			}

			// Update links
			const parent = node.getParent();
			if ($isLinkNode(parent) || $isLinkNode(node)) {
				setIsLink(true);
			} else {
				setIsLink(false);
			}

			if (
				!$isCodeHighlightNode(selection.anchor.getNode()) &&
				selection.getTextContent() !== ""
			) {
				setIsText($isTextNode(node));
				setShowToolbar($isTextNode(node));
			} else {
				setIsText(false);
			}
		});
	}, [editor]);
	useEffect(() => {
		document.addEventListener("selectionchange", updatePopup);
		return () => {
			document.removeEventListener("selectionchange", updatePopup);
		};
	}, [updatePopup]);
	useEffect(() => {
		return editor.registerUpdateListener(() => {
			updatePopup();
		});
	}, [editor, updatePopup]);
	if (!isText || !showToolbaar) {
		return null;
	}
	return createPortal(
		React.createElement(TextFormatFloatingToolbar, {
			editor: editor,
			anchorElem: anchorElem,
			isBold: isBold,
			isItalic: isItalic,
			isUnderline: isUnderline,
			isStrikethrough: isStrikethrough,
			isLink: isLink,
			setIsLinkEditMode: setIsLinkEditMode
		}),
		anchorElem
	);
}
export default function FloatingTextFormatToolbarPlugin({
	anchorElem = document.body,
	setShowToolbar,
	showToolbaar,
	setIsLinkEditMode
}) {
	const [editor] = useLexicalComposerContext();
	return useFloatingTextFormatToolbar(
		editor,
		anchorElem,
		setShowToolbar,
		showToolbaar,
		setIsLinkEditMode
	);
}
