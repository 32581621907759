import React from "react";
import { Link } from "react-router";
const RecoverSuccess = ({ isFromVacancy }) => {
	return (
		<p className="description">
			{`We've sent you an e-mail with a link and instructions to
			update your password${
				isFromVacancy
					? ", note that we'll take over your application by following this process."
					: "."
			}`}
			If needed, don&#39;t hesitate to{" "}
			<Link className="link contact-team-link" to="/ask-question">
				contact our support team!
			</Link>
		</p>
	);
};

export default RecoverSuccess;
