import React from "react";
import Modal from "common/modal";
import _get from "lodash/get";
import { displayAttendeesDetails } from "common/displayAttendees";
import {
	Content,
	InterviewWrapper,
	PropositionBox,
	BoxInterview,
	FlexBox,
	DescriptionContainer,
	InterviewCanceledLabel
} from "./styled";
import { formatDate } from "common/Functions";
import {
	TIME_FORMAT_SYMBOL,
	DATA_MMMM_D_YYYY,
	VIDEO_CONFERENCE,
	DONE,
	ONE_HUNDRED_AND_FIFTY,
	CANCELLED
} from "config";
import AddressComposer from "common/AddressComposer";
import Description from "common/Description";

const InterviewDetailsModal = ({ closeModal, data }) => {
	const cancelledBy = _get(data, "cancelled_by", null);
	const cancelInterviewAt = _get(data, "cancelled_at");
	const isVideoConf = data.location_type === VIDEO_CONFERENCE;
	const { first_name, last_name } = data && data.last_user_interview_action;

	const cancelDay =
		cancelledBy !== null
			? formatDate(data.cancelled_at, true, DATA_MMMM_D_YYYY)
			: formatDate(cancelInterviewAt, true, DATA_MMMM_D_YYYY);
	const cancelAt =
		cancelledBy !== null
			? formatDate(data.cancelled_at, true, TIME_FORMAT_SYMBOL)
			: formatDate(cancelInterviewAt, true, TIME_FORMAT_SYMBOL);
	return (
		<Modal
			onClose={closeModal}
			title={"Interview details"}
			fixed
			firstButton={{
				label: "Close",
				type: "outlined",
				action: closeModal
			}}
			showSecondButton={false}
		>
			<div className="section" style={{ position: "relative" }}>
				{cancelledBy !== null ? (
					<InterviewCanceledLabel>{`Interview cancelled by ${data.cancelled_by.first_name} ${data.cancelled_by.last_name} on ${cancelDay} ${cancelAt}`}</InterviewCanceledLabel>
				) : (
					data.status === CANCELLED && (
						<InterviewCanceledLabel>{`Interview cancelled by ${first_name} ${last_name} on ${cancelDay} ${cancelAt}`}</InterviewCanceledLabel>
					)
				)}

				<div className="section-proposition">
					<InterviewWrapper className="interview-modal">
						<PropositionBox fullWidth>
							<div className="title BoxWrapper interview-modal">
								{data.status === DONE
									? `CONFIRMED DATE & TIME`
									: `Your proposition`}
							</div>
							<div className="BoxWrapper">
								<BoxInterview>
									<div className="icon-date-location">
										<i className="icon-date-time" />
									</div>
									<div className="contentBox">
										<h3 className="titleBox">Date & time</h3>
										<div className="detailsInterview">
											<span className="dateInterview">
												{formatDate(
													data.new_interview_start_date,
													true,
													DATA_MMMM_D_YYYY
												)}
											</span>
											<span className="timeInterview">
												{formatDate(
													data.new_interview_start_date,
													true,
													TIME_FORMAT_SYMBOL
												)}{" "}
												<i className="icon-arrow-right-new" />{" "}
												{formatDate(
													data.new_interview_end_date,
													true,
													TIME_FORMAT_SYMBOL
												)}
											</span>
										</div>
									</div>
								</BoxInterview>
								<BoxInterview>
									<div>
										{isVideoConf ? (
											<FlexBox>
												<div className="icon-date-location">
													<i className="icon-location-virtual-room video-interview-icon" />
												</div>

												<div className="contentBox videoConference">
													<h3 className="titleBox">
														Location - video conference
													</h3>
													<span>Virtual Meeting Room</span>
												</div>
											</FlexBox>
										) : (
											<FlexBox>
												<div className="icon-date-location">
													<i className="icon-adresse-propose" />
												</div>
												<div className="contentBox">
													<h3 className="titleBox">Location</h3>
													<Content>
														<AddressComposer
															addressData={data}
															noClass={true}
														/>
													</Content>
												</div>
											</FlexBox>
										)}
									</div>
								</BoxInterview>
							</div>
						</PropositionBox>
					</InterviewWrapper>
					{data.description && (
						<DescriptionContainer marginBottom>
							<div className="title">Message</div>
							<Description
								parseMarkdown={false}
								description={data.description}
								max={ONE_HUNDRED_AND_FIFTY}
							/>
						</DescriptionContainer>
					)}
					<DescriptionContainer>
						<div className="title">Attendees</div>
						<div></div>
						{displayAttendeesDetails(
							_get(data, "attendees_users"),
							_get(data, "user_sender")
						)}
					</DescriptionContainer>
				</div>
			</div>
		</Modal>
	);
};

export default InterviewDetailsModal;
