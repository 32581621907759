/* eslint-disable react/display-name */
//TODO remove null from profile description and job description, we added null to avoid page crash
import React, { useCallback, useEffect, useState, useRef } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { AddVacancyApplicantsCard } from "../AddVacancyApplicantsCard";
import { AddVacancyDescriptionCard } from "../AddVacancyDescriptionCard";
import { AddVacancySalaryCard } from "../AddVacancySalaryCard";
import { AddVacancyAdditionalInfoCard } from "../AddVacancyAdditionalInfoCard";
import styles from "./create-vacancy-vacancy-step.module.scss";
import { Button } from "common/Button";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import { useCreateVacancy } from "modules/vacancy/create-vacancy/api/useCreateVacancy";
import { useVacancyForm } from "modules/vacancy/create-vacancy/hooks/useVacancyForm";
import toaster from "common/Toaster";
import { useGetCountriesList } from "modules/vacancy/create-vacancy/api/useGetCountriesList";
import { useEditVacancyVacancyInformations } from "modules/vacancy/create-vacancy/hooks/useEditVacancayVacancyInformations";
import LeaveHook from "common/LeaveHook";
import { VACANCY_STEP } from "../../utils/constants";
import { CreateVacancyStepper } from "../CreateVacancyStepper";
import { SaveButton } from "common/SaveButton";
import { CreateVacancyVacancyStepLoading } from "../CreateVacancyVacancyStepLoading";
import { VacancyStepMenu } from "../VacancyStepMenu";
import _get from "lodash/get";
import { initializeVacancyForm } from "../../utils/helper";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useGetOpeningReasonsList } from "../../api/useGetOpeningReasonsList";

const ORDERED_FIELDS = [
	"versions",
	"education_level",
	"employment_type",
	"positions_number",
	"number_of_colleagues",
	"payroll_country",
	"salary_range_start",
	"salary_range_end",
	"currency",
	"payment_time",
	"payment_type",
	"extra_benefits",
	"other_benefits",
	"due_date",
	"type",
	"office_days",
	"street",
	"number",
	"street",
	"zip",
	"city",
	"country",
	"latitude",
	"longitude",
	"featured_video",
	"employment_type"
];

const ADDRESS_FIELDS = [
	"street",
	"number",
	"street",
	"zip",
	"city",
	"country",
	"latitude",
	"longitude"
];

const FIELDS_MAP = {
	versions: "description",
	education_level: "applicantEducationLevel",
	expected_duration: "duration",
	positions_number: "numberOfPositions",
	number_of_colleagues: "numberColleagues",
	expected_duration_period: "durationType",
	//salary data
	applicants_location: "applicantLocation",
	payroll_country: "payrollCountry",
	salary_range_start: "salaryMin",
	salary_range_end: "salaryMax",
	currency: "currency",
	payment_time: "paymentTime",
	payment_type: "type",
	extra_benefits: "extraBenefits",
	other_benefits: "otherBenefits",
	//additionInfo data
	featured_video: "featuredVideo",
	employment_type: "employmentType",
	due_date: "dueDate",
	featured_image: "featuredImage"
};

const CreateVacancyVacancyStep = ({
	onPreviousClick: onPreviousClickProp,
	vacancyToEdit,
	actif,
	onNext: onNextProp,
	onSuccess: onSuccessProp,
	idFromUrl,
	vacancyId
}) => {
	const [isEdit, setIsEdit] = useState(!!idFromUrl);
	const [isFormIntialized, setIsFormIntialized] = useState(false);
	const [isUploadingImage, setIsUploadingImage] = useState(false);
	const [isFormSaving, setIsFormSaving] = useState(false);
	const isFormSubmitted = useRef(false);

	const { data: openingReasonsData } = useGetOpeningReasonsList({
		enabled: !workflowId && !actif
	});
	const methods = useVacancyForm();
	const [
		editVacancy,
		isEditVacancyLoading
	] = useEditVacancyVacancyInformations();

	const { workflowId } = useOnboardingStore();
	const { data: countriesList } = useGetCountriesList({ enabled: !workflowId });

	const {
		handleSubmit: handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitted },
		watch,
		control,
		getValues,
		clearErrors,
		setValue,
		setError,
		trigger: triggerFormValidation
	} = methods;

	const { append, remove } = useFieldArray({
		control: control,
		name: "description",
		shouldUnregister: false
	});

	const [isDescriptionDirty, setIsDescriptionDirty] = useState(false);

	const [createVacancy, isCreateVacancyLoading] = useCreateVacancy();

	useEffect(() => {
		if (vacancyToEdit?.versions && countriesList?.length > 0) {
			initializeVacancyForm(vacancyToEdit, reset, {
				countriesList
			});
			setIsFormIntialized(true);
		} else if (vacancyToEdit && !vacancyToEdit?.versions) {
			setIsFormIntialized(true);
		}
	}, [vacancyToEdit, countriesList]);

	const onPublish = values => {
		setIsFormSaving(true);

		if (isEdit) {
			if (isDirty || isDescriptionDirty) {
				editVacancy(values, vacancyToEdit?.id || vacancyId, {
					onSuccess: onSuccessProp,
					onError: onPublishError
				});
			} else {
				onNextProp();
			}
		} else {
			createVacancy(values, vacancyId, {
				onSuccess: () => {
					setIsEdit(true);
					onSuccessProp();
				},
				onError: onPublishError
			});
		}
	};

	const onPublishError = e => {
		setIsFormSaving(false);
		if (e && e.status === 422) {
			let errorMsg = _get(e, "detail");
			if (errorMsg) {
				const fieldToScroll = getFirstRegisteredInput(Object.keys(errorMsg));
				let isAddressErrorSetted = false;

				Object.keys(errorMsg).forEach(name => {
					const isErrorInAddress = ADDRESS_FIELDS.includes(name);

					if (isAddressErrorSetted && isErrorInAddress) return;
					let id = isErrorInAddress ? "vacancyAddress" : FIELDS_MAP[name];

					setError(
						id,
						{
							type: "manual",
							message: _get(errorMsg, name)[0]
						},
						{
							shouldFocus: fieldToScroll === name
						}
					);

					if (isErrorInAddress) isAddressErrorSetted = true;
				});
			}
		} else {
			toaster.danger("An error has occurred");
		}
	};

	const getFirstRegisteredInput = fields => {
		return ORDERED_FIELDS.find(item => fields.includes(item));
	};

	const onPrevious = () => {
		onPreviousClickProp();
	};

	const onLanguageChange = useCallback(() => {
		setIsDescriptionDirty(true);
	}, []);

	const onImageSubmitStart = useCallback(() => {
		setIsUploadingImage(true);
	}, []);

	const onImageSubmitFinish = useCallback(() => {
		setIsUploadingImage(false);
	}, []);

	/* eslint-disable no-unused-vars */
	const descriptionList = watch("description").map(
		({ jobDescription, profileDescription, ...rest }) => {
			return rest;
		}
	);

	const usedLanguages = (descriptionList || []).map(
		({ languageObject }) => languageObject.language
	);

	if (!actif) return null;

	return idFromUrl && !isFormIntialized ? (
		<CreateVacancyVacancyStepLoading />
	) : (
		<>
			<VacancyStepMenu display={actif} />
			<CreateVacancyStepper
				onBack={onPrevious}
				actifStep={VACANCY_STEP}
				onQuestionnaireClick={onNextProp}
			/>
			<div className={styles.cardsList}>
				<LeaveHook
					dirty={(isDirty || isDescriptionDirty) && !isFormSaving && actif}
				>
					<FormProvider {...methods}>
						<div className={styles.cardsContainer}>
							<AddVacancyDescriptionCard
								onLanguageChange={onLanguageChange}
								descriptionError={errors.description}
								setValue={setValue}
								control={control}
								getValues={getValues}
								clearErrors={clearErrors}
								descriptionList={descriptionList}
								append={append}
								remove={remove}
								usedLanguages={usedLanguages}
								isFormSubmitted={isFormSubmitted.current}
								triggerFormValidation={triggerFormValidation}
								vacancy={vacancyToEdit}
							/>
							<AddVacancyApplicantsCard
								control={control}
								errors={errors}
								applicantEducationLevel={watch("applicantEducationLevel")}
								employmentType={watch("employmentType")}
							/>
							<AddVacancySalaryCard
								countriesList={countriesList}
								errors={errors}
								control={control}
								setValue={setValue}
								clearErrors={clearErrors}
								payrollCountry={watch("payrollCountry")}
								currency={watch("currency")}
								salaryMin={watch("salaryMin")}
								triggerFormValidation={triggerFormValidation}
								isSubmitted={isSubmitted}
							/>
							<AddVacancyAdditionalInfoCard
								control={control}
								setValue={setValue}
								vacancyAddress={watch("vacancyAddress")}
								vacancyAddressType={watch("vacancyAddressType")}
								errors={errors}
								remoteEligibility={watch("remoteEligibility")}
								onImageSubmitStart={onImageSubmitStart}
								onImageSubmitFinish={onImageSubmitFinish}
								openingReasonsData={openingReasonsData}
								vacancyToEdit={vacancyToEdit}
								openingReason={watch("openingReason")}
							/>
						</div>
						<div className={styles.buttonsContainer}>
							<Button
								text="Previous"
								onClick={onPrevious}
								icon={<ArrowLeftIcon />}
								variant="text"
								className={styles.previousButton}
							/>

							<SaveButton
								leftIcon={null}
								text="Next"
								onClick={() => {
									// isFormSubmitted.current = true; // TODO
									handleSubmit(onPublish)();
								}}
								className={styles.publishButton}
								isLoading={isCreateVacancyLoading || isEditVacancyLoading}
								isDisabled={
									isCreateVacancyLoading ||
									isEditVacancyLoading ||
									isUploadingImage
								}
							/>
						</div>
					</FormProvider>
				</LeaveHook>
			</div>
		</>
	);
};

export default CreateVacancyVacancyStep;
