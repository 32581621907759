//Example editor from Facebook team https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/Editor.tsx
//Lexical demo https://playground.lexical.dev/
import React, { forwardRef, memo, useState } from "react";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import MarkdownShortcutPlugins from "./plugin/MarkdownShortcutPlugin";
import FloatingTextFormatToolbarPlugin from "./plugin/FloatingTextFormatToolbarPlugin";
import MyCustomAutoFocusPlugin from "./plugin/MyCustomAutoFocusPlugin";
import MentionsPlugin from "./plugin/MentionsPlugin";
import ContentEditable from "./ui/ContentEditable";
import Theme from "./themes/Theme";
import EditorNodes from "./nodes/EditorNodes";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $generateNodesFromDOM } from "@lexical/html";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { $getRoot } from "lexical";
import "./editor.css";
import cx from "classnames";
import { URL_MATCHERS_LIST, validateUrl } from "./utils/helpers";
import { removeTags } from "config/helpers";
import FloatingLinkEditorPlugin from "./plugin/FloatingLinkEditorPlugin";
import { MaxLengthPlugin } from "./plugin/MaxLengthPlugin";

const Placeholder = ({ placeholder }) => {
	return <div className="editor-placeholder">{placeholder}</div>;
};

const Editor = (
	{
		placeholder,
		idVacancy,
		onChange: onChangeProp,
		showToolbaar,
		setShowToolbar,
		setMentions,
		id,
		steps,
		mentions = [],
		addMention,
		oldUsers,
		scrollClassName,
		contentEditorClassName,
		useMentionPlugin = true,
		value: propValue,
		focusClassName,
		focus = true,
		editorTextLength,
		editorMaxLength = 1000,
		textLengthClassName = "",
		limitText
	},
	ref
) => {
	const [isLinkEditMode, setIsLinkEditMode] = useState(false);
	const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

	const onRef = _floatingAnchorElem => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const initialConfig = {
		nodes: [...EditorNodes],
		onError: error => {
			throw error;
		},
		theme: Theme,
		// display comment from editor
		editorState: editor => {
			const parser = new DOMParser();
			if (propValue) {
				const dom = parser.parseFromString(
					propValue ? propValue : "",
					"text/html"
				);
				if (dom !== null) {
					const nodes = $generateNodesFromDOM(editor, dom);
					const rootNode = $getRoot();
					rootNode.append(...nodes);
				}
			}
		}
	};

	const onChange = (_, editor) => {
		editor.update(() => {
			const html = $generateHtmlFromNodes(editor, null);
			const comment = removeTags(html).trim().length > 0 ? html : "";

			if (propValue !== comment) {
				onChangeProp(comment);
			}
		});
	};

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<div className="editor-container">
				<div className="editor-inner" ref={onRef}>
					<RichTextPlugin
						contentEditable={
							<ContentEditable
								steps={steps}
								scrollClassName={scrollClassName}
								className={contentEditorClassName}
								focusClassName={focusClassName}
								ref={ref}
							/>
						}
						placeholder={<Placeholder placeholder={placeholder} />}
					/>

					{!!editorTextLength && (
						<div className={cx("editor-text-length", textLengthClassName)}>
							{`${editorTextLength} / ${editorMaxLength}`}
						</div>
					)}
					<OnChangePlugin onChange={onChange} ignoreSelectionChange />
					{useMentionPlugin && (
						<MentionsPlugin
							setMentions={setMentions}
							mentions={mentions}
							idVacancy={idVacancy}
							id={id}
							addMention={addMention}
							oldUsers={oldUsers}
							content={propValue}
						/>
					)}
					<ListPlugin />
					{limitText && <MaxLengthPlugin maxLength={editorMaxLength} />}
					<AutoLinkPlugin matchers={URL_MATCHERS_LIST} />
					<LinkPlugin validateUrl={validateUrl} />
					{floatingAnchorElem && (
						<>
							<FloatingLinkEditorPlugin
								anchorElem={floatingAnchorElem}
								isLinkEditMode={isLinkEditMode}
								setIsLinkEditMode={setIsLinkEditMode}
							/>
							<FloatingTextFormatToolbarPlugin
								showToolbaar={showToolbaar}
								setShowToolbar={setShowToolbar}
								setIsLinkEditMode={setIsLinkEditMode}
							/>
						</>
					)}
					<MyCustomAutoFocusPlugin focus={focus} />
					<MarkdownShortcutPlugins />
					<TabIndentationPlugin />
					<HistoryPlugin />
				</div>
			</div>
		</LexicalComposer>
	);
};

export default memo(forwardRef(Editor));
