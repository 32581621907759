import React, { memo, useRef, useState, forwardRef } from "react";
import cx from "classnames";
import { DropDownInput } from "common/DropDownInput";
import {
	OverlayContainer,
	useButton,
	useOverlayPosition,
	useOverlayTrigger
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import icon from "static/icons/check-icon.svg";
import styles from "./custom-drop-down.module.scss";
import Popover from "./Popover/Popover";

const BOOLEAN_OPTIONS = [
	{
		label: "Yes",
		value: true
	},
	{
		label: "No",
		value: false
	}
];

const CustomDropDown = forwardRef(
	(
		{
			value,
			options: optionsProp,
			onChange,
			className,
			disabled = false,
			placeHolder,
			withBooleanOptions,
			inputClassName,
			chevronIcon,
			optionClassName,
			labelClassName
		},
		ref
	) => {
		const [displayPaper, setDisplayPaper] = useState(false);
		const rootElement = useRef();
		let state = useOverlayTriggerState({});

		let triggerRef = React.useRef();
		let overlayRef = React.useRef();

		let { triggerProps, overlayProps } = useOverlayTrigger(
			{ type: "dialog" },
			state,
			triggerRef
		);

		let { overlayProps: positionProps } = useOverlayPosition({
			targetRef: triggerRef,
			overlayRef,
			placement: "bottom start",
			offset: 5,
			isOpen: displayPaper
		});

		let { buttonProps } = useButton(
			{
				onPress: () => handleInputClick()
			},
			triggerRef
		);

		function handleInputClick() {
			setDisplayPaper(displayPaper => !displayPaper);
		}

		const handleOptionClick = value => {
			onChange(value);
			setDisplayPaper(false);
		};

		const handleClose = () => {
			setDisplayPaper(false);
		};

		const options = withBooleanOptions ? BOOLEAN_OPTIONS : optionsProp;

		const valueObject = (options || []).find(item => item.value === value);
		const displayEmptyIcon = !!value || typeof value === "boolean";

		return (
			<div ref={rootElement} className={cx(styles.container, className)}>
				<DropDownInput
					value={valueObject?.label}
					arrowDirection={displayPaper ? "down" : "top"}
					className={cx(styles.input, inputClassName)}
					isDisabled={disabled}
					{...buttonProps}
					{...triggerProps}
					ref={elt => {
						triggerRef.current = elt;
						if (ref) ref.current = elt;
					}}
					placeHolder={placeHolder}
					chevron={chevronIcon}
				/>

				{displayPaper && !disabled && (
					<OverlayContainer>
						<Popover
							{...overlayProps}
							{...positionProps}
							ref={overlayRef}
							isOpen={displayPaper}
							onClose={handleClose}
							paperStyle={{ minWidth: `${triggerRef.current.offsetWidth}px` }}
						>
							<div className={styles.optionsWrapper}>
								{(options || []).map(
									({ value: itemValue, label, icon: parentIcon }) => {
										return (
											<div
												key={itemValue}
												onClick={() => handleOptionClick(itemValue)}
												className={cx(styles.option, optionClassName)}
											>
												{!parentIcon ? (
													<div>
														{value === itemValue ? (
															<div className={styles.iconWrapper}>
																<img className={styles.icon} src={icon} />
															</div>
														) : (
															displayEmptyIcon && (
																<div className={styles.emptyIcon} />
															)
														)}
													</div>
												) : (
													<div className={styles.iconWrapper}>
														<img className={styles.icon} src={parentIcon} />
													</div>
												)}

												<div className={cx(styles.label, labelClassName)}>
													{label}
												</div>
											</div>
										);
									}
								)}
							</div>
						</Popover>
					</OverlayContainer>
				)}
			</div>
		);
	}
);
CustomDropDown.displayName = "CustomDropDown";
export default memo(CustomDropDown);
