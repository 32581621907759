import React from "react";
import styles from "./footer.module.scss";
import { ConfirmationModal } from "common/modal";
import { Button } from "common/Button";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";

const Footer = ({ children, onClose, onClickLabel, onClick }) => (
	<div className={styles.buttons}>
		{children ? (
			children
		) : (
			<>
				<Button
					text="Cancel"
					onClick={onClose}
					variant="outlined"
					rootClassName={styles.cancel}
				/>
				<Button
					text={onClickLabel}
					className={styles.deleteButton}
					onClick={onClick}
					icon={<TrashIcon className={styles.trashIcon} />}
				/>
			</>
		)}
	</div>
);

export default Footer;
ConfirmationModal.Footer = Footer;
