import { client } from "lib/api-client";
import { GENERATE_PO_NUMBER } from "config/api-endpoints";
import { useMutation } from "react-query";

const generatePoNumber = () => {
	return client(GENERATE_PO_NUMBER, {
		body: {}
	});
};

export const useGeneratePoNumber = options => {
	return useMutation(generatePoNumber, options);
};
