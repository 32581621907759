import React from "react";
import { Controller } from "react-hook-form";
import _get from "lodash/get";
import { useQuery } from "react-query";
import { OfferTermsContainer } from "./styles";
import { ReactComponent as InfoOutline } from "static/icons/info-circle.svg";
import Select from "common/react-hook-form-components/Select";
import CurrencyField from "common/NewCurrencyField";
import DatePicker from "common/DatePickerField.js";
import CurrenciesSymbolSelect from "common/CurrenciesSymbolSelect";
import {
	PREFERRED_COUNTRIES,
	EMPLOYMENT_TYPE,
	PAYMENTS_TYPE,
	PAYMENTS_TIME
} from "config";
import { client } from "lib/api-client";
import { GET_CURRENCIES } from "config/api-endpoints";
import FormField from "common/FormField";

const getCurrencies = () =>
	client(GET_CURRENCIES, {
		body: {}
	});

function OfferTerms({ control, errors, values }) {
	const { data: currencies } = useQuery(["currencies"], getCurrencies, {
		refetchOnWindowFocus: false
	});

	return (
		<OfferTermsContainer>
			<label className="offer_terms_label">
				Offer terms <InfoOutline />{" "}
			</label>
			<form className="offer_terms_form">
				<div className="first_row">
					<Controller
						control={control}
						name="contract_type"
						render={({ field: { onChange, value, name } }) => (
							<Select
								className="contract_type"
								label="Contract type"
								options={EMPLOYMENT_TYPE}
								onChange={onChange}
								value={value}
								name={name}
								placeholder="Contract type"
								error={_get(errors, "contract_type")}
							/>
						)}
					/>
					<Controller
						control={control}
						name="currency"
						render={({ field: { onChange, value, name, onBlur } }) => {
							return (
								<CurrenciesSymbolSelect
									className="currency"
									label="Currency"
									input_classes="currency"
									input={{
										onChange,
										value,
										name,
										onBlur
									}}
									meta={{
										touched: Boolean(_get(errors, "currency.message")),
										error: _get(errors, "currency.message")
									}}
									placeholder="Currency"
									preferredCountries={PREFERRED_COUNTRIES}
									tabIndex={0}
									all={true}
									currenciesData={currencies || []}
								/>
							);
						}}
					/>

					<Controller
						control={control}
						name="amount"
						render={({ field: { onChange, value, name } }) => (
							<CurrencyField
								className="amount"
								label="Amount"
								suffix={_get(values, "currency.symbol", "$")}
								input={{
									onChange,
									value,
									name
								}}
								meta={{
									touched: Boolean(_get(errors, "amount.message")),
									error: _get(errors, "amount.message")
								}}
							/>
						)}
					/>
					<Controller
						control={control}
						name="salary_type"
						render={({ field: { onChange, value, name } }) => (
							<Select
								className="salary_type"
								label="Salary type"
								onChange={onChange}
								options={PAYMENTS_TYPE}
								value={value}
								name={name}
								placeholder="Salary type"
								error={_get(errors, "salary_type")}
							/>
						)}
					/>
					<Controller
						control={control}
						name="payment_periode"
						render={({ field: { onChange, value, name } }) => (
							<Select
								className="payment_periode"
								label="Payment period"
								onChange={onChange}
								options={PAYMENTS_TIME}
								value={value}
								name={name}
								placeholder="Payment period"
								error={_get(errors, "payment_periode")}
							/>
						)}
					/>
				</div>
				<div className="second_row">
					<Controller
						control={control}
						name="extra_benefits"
						render={({ field: { onChange, value, name } }) => (
							<FormField
								className="extra_benefits"
								type="text"
								component={FormField}
								maxLength="500"
								label="Extra benefits"
								input={{
									onChange,
									value,
									name
								}}
								meta={{
									touched: Boolean(_get(errors, "extra_benefits.message")),
									error: _get(errors, "extra_benefits.message")
								}}
							/>
						)}
					/>
					<Controller
						control={control}
						name="start_date"
						render={({ field: { onChange, value, name } }) => (
							<DatePicker
								className="start_date"
								label="Start date"
								placeholder="Start date"
								minDate={window.moment().toDate()}
								input={{
									onChange,
									value,
									name
								}}
								meta={{
									touched: Boolean(_get(errors, "start_date.message")),
									error: _get(errors, "start_date.message")
								}}
							/>
						)}
					/>
				</div>
			</form>
		</OfferTermsContainer>
	);
}

export default OfferTerms;
