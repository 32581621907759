import { TYPES } from "./missionsListUtils";
import { OPERATORS } from "common/FilterDrawer/utils";
import mission from "static/icons/missions-details.svg";
import preferenceIcon from "static/icons/preferences-icon.svg";
import coupleIcon from "static/icons/person-icon.svg";
import {
	getDepartmentsList,
	getOnboarded,
	getProfileType,
	getRateType,
	getStatus,
	getTimesheet_frequency
} from "./missionsListHelper";
export const COLUMNS = [
	{
		id: 1,
		label: "Mission details",
		icon: mission,
		children: [
			{
				label: "Mission reference",
				name: "mission_reference",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Business manager",
				name: "business_manager",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Mission title",
				name: "mission_title",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Start date",
				name: "start_date",
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "End date",
				name: "end_date",
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Status",
				name: "status",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: true,
					queryFn: getStatus
				}
			},
			{
				label: "Progress",
				name: "progress",
				type: TYPES.progress,
				operator: OPERATORS.eq
			},
			{
				label: "PO Number",
				name: "po_number",
				type: TYPES.text,
				operator: OPERATORS.contains
			}
		]
	},
	{
		id: 2,
		label: "Consultant details",
		icon: coupleIcon,
		children: [
			{
				label: "Profile type",
				name: "profile_type",
				type: TYPES.singleOption,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: true,
					queryFn: getProfileType
				}
			},
			{
				label: "External Manager",
				name: "external_manager",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Accounting Manager",
				name: "accounting_manager",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Supplier Company Name",
				name: "supplier_company_name",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Selling Price",
				name: "selling_price",
				type: TYPES.number,
				operator: OPERATORS.is
			}
		]
	},
	{
		id: 3,
		label: "Preferences",
		icon: preferenceIcon,
		children: [
			{
				label: "Department",
				name: "department",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: true,
					queryFn: getDepartmentsList
				}
			},
			{
				label: "Timesheet frequency",
				name: "timesheet_frequency",
				type: TYPES.singleOption,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: true,
					queryFn: getTimesheet_frequency
				}
			},
			{
				label: "Extensions",
				name: "extensions",
				type: TYPES.number,
				operator: OPERATORS.eq
			},
			{
				label: "Onboarded",
				name: "onboarded",
				type: TYPES.boolean,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: true,
					queryFn: getOnboarded
				}
			},
			{
				label: "Rate type",
				name: "rate_type",
				type: TYPES.singleOption,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: true,
					queryFn: getRateType
				}
			},
			{
				label: "Rate",
				name: "rate",
				type: TYPES.number,
				operator: OPERATORS.eq
			},
			{
				label: "Cost",
				name: "cost",
				type: TYPES.number,
				operator: OPERATORS.eq
			}
		]
	}
];
