import React from "react";
import Drawer from "rc-drawer";
import _get from "lodash/get";
import { DrawerContainer } from "./style";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import RejectApplication from "../components/Rejection";
import CancelInterview from "../components/CancelInterview";
import ProposeOffer from "modules/vacancy/components/vacancyView/CandidateAction/components/offer";
import {
	OFFER,
	REJECT_APPLICATION,
	CANCEL_INTERVIEW,
	UPLOAD_CANDIDATE_FILES
} from "config";
import ProposeInterview from "modules/vacancy-view/components/ProposeInterview";
import UploadCandidateFiles from "modules/candidate/Profile/Pages/Files/uploadFiles";
import { PHONE_SCREENING, ADD_INTERVIEW } from "config";

const CandidateActionsDrawer = ({
	job,
	poke,
	onSuccess,
	isFromVacanciesTable = false
}) => {
	const { drawerState, setDrawerState } = useVacancyStore();
	const renderComponent = () => {
		switch (_get(drawerState, "component")) {
			case OFFER:
				return (
					<ProposeOffer
						vacancyId={_get(job, "id", _get(job, "_id"))}
						profileId={_get(drawerState, "profile_id")}
						job={job}
						offer={_get(poke, "offer", {})}
						poke={poke}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				);
			case ADD_INTERVIEW:
				return (
					<ProposeInterview
						isProposeInterview
						candidateName={_get(drawerState, "candidateName")}
						vacancyId={_get(
							drawerState,
							"vacancy_id",
							_get(job, "id", _get(job, "_id"))
						)}
						companyType={_get(drawerState, "companyType")}
						profileId={_get(drawerState, "profileId")}
						isCandidateHasMail={_get(drawerState, "isCandidateHasMail")}
						applicationId={_get(drawerState, "applicationId")}
						isApplicantHasAnOffer={_get(drawerState, "isApplicantHasAnOffer")}
						interviewId={_get(drawerState, "interviewId")}
						interviewStatus={_get(drawerState, "interviewStatus")}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				);
			case PHONE_SCREENING:
				return (
					<ProposeInterview
						isPhoneScreen
						candidateName={_get(drawerState, "candidateName")}
						vacancyId={_get(job, "id", _get(job, "_id"))}
						companyType={_get(drawerState, "companyType")}
						profileId={_get(drawerState, "profileId")}
						isCandidateHasMail={_get(drawerState, "isCandidateHasMail")}
						applicationId={_get(drawerState, "applicationId")}
						isApplicantHasAnOffer={_get(drawerState, "isApplicantHasAnOffer")}
						interviewId={_get(drawerState, "interviewId")}
						phone={_get(drawerState, "phone")}
						interviewStatus={_get(drawerState, "interviewStatus")}
					/>
				);
			case REJECT_APPLICATION:
				return (
					<RejectApplication
						clearSelection={_get(drawerState, "clearSelection")}
						poke={poke}
						job={job}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				);
			case CANCEL_INTERVIEW:
				return (
					<CancelInterview
						poke={poke}
						applicationId={_get(drawerState, "applicationId")}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				);
			case UPLOAD_CANDIDATE_FILES:
				return <UploadCandidateFiles />;

			default:
				return;
		}
	};

	return (
		<DrawerContainer>
			<Drawer
				open={_get(drawerState, "open")}
				width={`calc(100% - 100px)`}
				height={"calc(100% - 64px)"}
				maskStyle={{ background: "0" }}
				contentWrapperStyle={{
					background: "red",
					position: "absolute",
					bottom: "0"
				}}
				placement={"right"}
				style={{ zIndex: 1000 }}
				level={"root"}
				maskClosable={true}
				onClose={() => setDrawerState({ open: false })}
				wrapperClassName="candidate-actions"
			>
				{renderComponent()}
			</Drawer>
		</DrawerContainer>
	);
};

export default CandidateActionsDrawer;
