import create from "zustand";
import { persist } from "zustand/middleware";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";
import { produce } from "immer";
import { get } from "lodash";

const useNotesStore = create(
	persist(
		set => ({
			notes: [],
			activeNote: undefined,
			addNote: note =>
				set(state => ({ notes: [note, ...state.notes], activeNote: note.id })),
			deleteNote: id =>
				set(state => ({
					notes: _filter(state.notes, note => note.id !== id),
					activeNote: state.activeNote === id ? undefined : state.activeNote
				})),
			updateNote: data =>
				set(state => {
					const noteIndex = _findIndex(state.notes, {
						id: data.id || data.idNote
					});

					const newNotes = produce(state.notes, draft => {
						draft[noteIndex] = {
							...draft[noteIndex],
							payload: { ...draft[noteIndex]?.payload, ...data.payload }
						};
					});

					return {
						notes: newNotes
					};
				}),
			setActiveNote: id =>
				set(state => ({
					activeNote: id === state.activeNote ? undefined : id
				})),
			clearStore: () =>
				set(() => ({
					notes: [],
					activeNote: undefined
				})),
			setMentions: (mentions, noteId) => {
				set(({ notes }) => {
					const noteIndex = notes.findIndex(({ id }) => noteId === id);
					const newNotes = produce(notes, draft => {
						draft[noteIndex].payload = {
							...get(draft[noteIndex], "payload", {}),
							mentions
						};
					});
					return {
						notes: newNotes
					};
				});
			},
			setVisibility: (visibility, noteId) => {
				set(({ notes }) => {
					const noteIndex = notes.findIndex(({ id }) => noteId === id);

					const newNotes = produce(notes, draft => {
						if (draft[noteIndex].payload) {
							draft[noteIndex].payload.visibility = visibility;
						} else
							draft[noteIndex].payload = {
								visibility
							};
					});
					return {
						notes: newNotes
					};
				});
			},
			setCustomPrivacy: (data, noteId) => {
				set(({ notes }) => {
					const noteIndex = notes.findIndex(({ id }) => noteId === id);

					const newNotes = produce(notes, draft => {
						if (draft[noteIndex].payload) {
							draft[noteIndex].payload.visible = data.visible;
							draft[noteIndex].payload.not_visible = data.not_visible;
						} else
							draft[noteIndex].payload = {
								visible: data.visible,
								not_visible: data.not_visible
							};
					});

					return {
						notes: newNotes
					};
				});
			},
			addMention: (mention, noteId) => {
				set(({ notes }) => {
					const noteIndex = notes.findIndex(({ id }) => noteId === id);

					const newNotes = produce(notes, draft => {
						const mentions = draft[noteIndex].payload?.mentions || [];

						if (draft[noteIndex].payload) {
							draft[noteIndex].payload.mentions = [...mentions, mention];
						} else {
							draft[noteIndex].payload = {
								mentions: [mention]
							};
						}
					});

					return {
						notes: newNotes
					};
				});
			}
		}),
		{
			name: "notes-draft"
		}
	)
);

export default useNotesStore;
