import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, initialize, formValueSelector } from "redux-form";
import { Helmet } from "react-helmet";
import { userSelector } from "../../selectors/ProfileSelector";
import PhoneNumberField from "common/PhoneNumberField";
import FormField from "common/FormField";
import { updateClientProfileInfo } from "../../actions";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";
import ProfileImage from "../avatar/profileAvatar";
import styles from "./ProfileClient.module.scss";
import { Box, Flex } from "rebass";
import { PROFILE, SUPER_ADMIN, ROLES, ADMIN } from "config";
import { ReactComponent as UploadIcon } from "static/icons/upload-icon.svg";
import { ReactComponent as EditIcon } from "static/icons/pen.svg";
import BodyClassName from "react-body-classname";
import cx from "classnames";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";
import { browserHistory } from "react-router";
import { useTour } from "@reactour/tour";
import {
	SELECTORS_TOUR_SETUP_COMPANY,
	STEPS_TOUR_SETUP_COMPANY,
	STEPS_TOUR_SETUP_PROFILE
} from "modules/user-onboarding/workflows/setup-company";
import {
	INTERACTIVE_CONTAINER_STYLES,
	TOUR_SETUP_COMPANY
} from "config/onboarding";
import _get from "lodash/get";
import Banner from "./Banner";

const useTourData = () => {
	const tourFirstStepRef = useRef(null);
	const {
		metaData,
		workflowId,
		cleanStore,
		setMetaData,
		setIsLoading,
		activeOverlay,
		setActiveOverlay
	} = useOnboardingStore();
	const {
		setIsOpen,
		setCurrentStep,
		setSteps,
		currentStep,
		isOpen
	} = useTour();

	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_SETUP_COMPANY,
		[workflowId]
	);

	// logged user
	const user = useSelector(state => state.auth.user);
	const isAdminUser = useMemo(() => user?.active_role === ADMIN, [user]);

	useEffect(() => {
		if (tourFirstStepRef.current && isActiveSetupCompanyTour) {
			// init steps
			if (isAdminUser) setSteps(STEPS_TOUR_SETUP_COMPANY);
			else setSteps(STEPS_TOUR_SETUP_PROFILE);
			// start onboarding
			setCurrentStep(0);
			setIsOpen(true);
		}
	}, [tourFirstStepRef, isActiveSetupCompanyTour, isAdminUser]);

	const closeTour = () => {
		if (isActiveSetupCompanyTour) {
			setIsOpen(false);
			setActiveOverlay(true);
		}
	};

	// persist onboarding data ( user role is member )
	const { persistData } = useOnboardingSaveData({
		onTourClose: () => {
			setIsOpen(false); // close tour
			setSteps([]); // clean steps
			if (metaData?.showStartRecrutmentModal) {
				setMetaData({ ...metaData, showModal: true });
			} else {
				cleanStore(); // clean onboarding store
				browserHistory.push("/settings/setup-guide");
			}
		}
	});

	const goNextTourStep = () => {
		if (isActiveSetupCompanyTour) {
			setActiveOverlay(false);
			if (isAdminUser) browserHistory.push("/settings/my-company");
			else {
				setIsLoading(true);
				persistData();
			}
		}
	};

	return {
		closeTour,
		goNextTourStep,
		tourFirstStepRef,
		toasterDuration: isActiveSetupCompanyTour ? 0.5 : 5, // toaster duration
		selectors: {
			first: SELECTORS_TOUR_SETUP_COMPANY[0],
			second: SELECTORS_TOUR_SETUP_COMPANY[1]
		},
		selectorsClassNames: {
			first:
				isActiveSetupCompanyTour && isOpen && currentStep === 0
					? " highlighted"
					: "",
			second:
				isActiveSetupCompanyTour && isOpen && currentStep === 1
					? " highlighted"
					: ""
		},
		interactiveContainerStyles:
			isActiveSetupCompanyTour && activeOverlay
				? {
						padding: "8px",
						borderRadius: "12px",
						...INTERACTIVE_CONTAINER_STYLES
				  }
				: {}
	};
};

const selector = formValueSelector("member-profile");
let ProfileClient = ({ route: { title }, handleSubmit, reset, ...props }) => {
	const {
		selectors,
		closeTour,
		goNextTourStep,
		toasterDuration,
		tourFirstStepRef,
		selectorsClassNames,
		interactiveContainerStyles
	} = useTourData();

	const dispatch = useDispatch();
	const user = useSelector(state => userSelector(state));
	const uploader = useRef(null);
	const formContainer = useRef(null);
	const [editMode, setEditMode] = useState(false);
	const formFields = useSelector(state =>
		selector(
			state,
			"phone_country",
			"phone",
			"birth_date",
			"first_name",
			"last_name",
			"user_function",
			"email"
		)
	);

	useEffect(() => {
		dispatch(
			initialize("member-profile", {
				first_name: user.first_name,
				last_name: user.last_name,
				phone: user.phone,
				email: user.email,
				user_function: user.user_function
			})
		);
	}, []);

	const roleRender = () => {
		const userGroupeRoles = user?.groups || [];
		if (!user.is_member && user.role_name === ADMIN) {
			return (
				<div className={`${styles.role_tag} ${styles.owner}`}>
					<span className={styles.role}>Super Admin -</span>Owner
				</div>
			);
		} else if (user.role_name === SUPER_ADMIN) {
			return (
				<div className={styles.role_tag}>
					<span className={styles.role}>Super Admin -</span>All
				</div>
			);
		} else if (userGroupeRoles.length > 0) {
			return userGroupeRoles.map(department => {
				const roleLabel = _get(
					ROLES.find(role => role.name === department.role_name),
					"label",
					""
				);
				return (
					<div key={department._id} className={styles.role_tag}>
						<span className={styles.role}>{roleLabel} -</span>
						{department.name}
					</div>
				);
			});
		}
		return "";
	};
	const roleDescription = useMemo(() => {
		return !user.is_member && user.role_name === ADMIN
			? "As a Super Admin, you have complete control over all company departments."
			: user.role_name === SUPER_ADMIN
			? "As a Super Admin, you have complete control over all company departments."
			: false;
	}, [user]);

	const editProfile = formFields => {
		dispatch(
			updateClientProfileInfo(
				{
					phone: formFields.phone,
					user_function: formFields.user_function
				},
				"member-profile",
				toasterDuration
			)
		).then(() => {
			dispatch(
				initialize("member-profile", {
					first_name: formFields.first_name,
					last_name: formFields.last_name,
					phone: formFields.phone,
					email: formFields.email,
					user_function: formFields.user_function
				})
			);
			setEditMode(false);
			setTimeout(goNextTourStep, 550);
		});
	};

	const onCancel = () => {
		reset();
		setEditMode(false);
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<BodyClassName className={styles.edit_profile}>
				<div className={styles.profile_container}>
					<Breadcumb parentItem="Settings" currentItem="My Profile" />
					<h1 className={styles.title}>My profile</h1>
					<div className={styles.divider} />
					<Flex sx={{ gap: 24 }}>
						<Box className={styles.intro} width={1 / 3}>
							<h2 className={styles.heading}>Edit profile picture</h2>
							<p className={styles.description}>
								Upload a profile picture of yourself, or the character you
								always wanted to be. Your avatar will be displays all over the
								platform
							</p>
						</Box>
						<Flex sx={{ gap: 24 }} alignItems={"center"} width={2 / 3}>
							<div className={styles.avatar_container}>
								<ProfileImage ref={uploader} user={user} type={PROFILE} />
							</div>
							<Box flexGrow={1} className={styles.avatar_label}>
								<div className={styles.label}>Upload new image</div>
								<div className={styles.helper}>Max file size- 5mb</div>
							</Box>
							<button
								id={selectors.first}
								ref={tourFirstStepRef}
								onClick={() => uploader.current.open()}
								className={styles.upload_btn + selectorsClassNames.first}
							>
								<UploadIcon />
								<span>Upload image</span>
							</button>
						</Flex>
					</Flex>
					<div className={styles.divider} />
					<Flex sx={{ gap: 24 }} ref={formContainer}>
						<Box className={styles.intro} width={1 / 3}>
							<h2 className={styles.heading}>Personal information</h2>
							<p className={styles.description}>
								Informations you add, will be visible all over the platform.
							</p>
						</Box>
						<Box width={2 / 3} style={interactiveContainerStyles}>
							<Flex justifyContent={"flex-end"}>
								{editMode ? (
									<Flex sx={{ gap: 10 }}>
										<button
											onClick={handleSubmit(editProfile)}
											className={cx(
												styles.submit_btn,
												!props.dirty && styles.submit_btn_disabled
											)}
										>
											Save changes
										</button>
										<button
											className={styles.cancel_btn}
											onClick={() => {
												onCancel();
												goNextTourStep();
											}}
										>
											Cancel
										</button>
									</Flex>
								) : (
									<button
										id={selectors.second}
										onClick={() => {
											closeTour();
											setEditMode(true);
										}}
										className={styles.mode_btn + selectorsClassNames.second}
									>
										<EditIcon />
										<span>Edit profile</span>
									</button>
								)}
							</Flex>
							<Box height={24} />
							{editMode ? (
								<>
									<Flex sx={{ gap: 24 }}>
										<Box width={1 / 2} className={styles.form_field}>
											<Field
												label="First name"
												name="first_name"
												component={FormField}
												readOnly
											/>
										</Box>
										<Box width={1 / 2} className={styles.form_field}>
											<Field
												label="Last Name"
												name="last_name"
												component={FormField}
												readOnly
											/>
										</Box>
									</Flex>
									<Flex sx={{ gap: 24 }}>
										<Box width={1 / 2} className={styles.form_field}>
											<Field
												label="Mobile number"
												normalize={value => value.formattedValue}
												name="phone"
												component={PhoneNumberField}
												placeholder=" "
											/>
										</Box>
										<Box width={1 / 2} className={styles.form_field}>
											<Field
												label="Email"
												name="email"
												component={FormField}
												readOnly
											/>
										</Box>
									</Flex>
									<Flex sx={{ gap: 24 }}>
										<Box width={1 / 2} className={styles.form_field}>
											<Field
												label="Job title"
												name="user_function"
												component={FormField}
											/>
										</Box>
									</Flex>
								</>
							) : (
								<>
									<Flex sx={{ gap: 24 }}>
										<Box width={1 / 2} className={styles.info}>
											<div className={styles.key}>First name</div>
											<div className={styles.value}>
												{formFields.first_name}
											</div>
										</Box>
										<Box width={1 / 2} className={styles.info}>
											<div className={styles.key}>Last Name</div>
											<div className={styles.value}>{formFields.last_name}</div>
										</Box>
									</Flex>
									<Flex sx={{ gap: 24 }}>
										<Box width={1 / 2} className={styles.info}>
											<div className={styles.key}>Mobile number</div>
											<div className={styles.value}>{formFields.phone}</div>
										</Box>
										<Box width={1 / 2} className={styles.info}>
											<div className={styles.key}>Email</div>
											<div className={styles.value}>{formFields.email}</div>
										</Box>
									</Flex>
									<Flex sx={{ gap: 24 }}>
										<Box width={1 / 2} className={styles.info}>
											<div className={styles.key}>Job title</div>
											<div className={styles.value}>
												{formFields.user_function}
											</div>
										</Box>
									</Flex>
								</>
							)}
						</Box>
					</Flex>
					<div className={styles.divider} />
					<Flex sx={{ gap: 24 }} ref={formContainer}>
						<Box className={styles.intro} width={1 / 3}>
							<h2 className={styles.heading}>User roles</h2>
							<p className={styles.description}>
								Discover Your Assignments. Explore all your roles across
								different vacancies with ease
							</p>
						</Box>
						<Flex width={2 / 3} sx={{ rowGap: "12px" }} flexDirection="column">
							<div className={styles.role_container}>{roleRender()}</div>
							{roleDescription && <Banner>{roleDescription}</Banner>}
						</Flex>
					</Flex>
				</div>
			</BodyClassName>
		</>
	);
};

ProfileClient = reduxForm({
	form: "member-profile",
	touchOnBlur: false
})(ProfileClient);

export default ProfileClient;
