/* eslint-disable no-prototype-builtins */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import Container from "../Container";
import SubContainer from "../SubContainer";
import FormField from "common/FormField";
import { reduxForm, Field, setSubmitFailed, change } from "redux-form";
import { searchParser } from "config/helpers";
import { emailRegex, FREELANCER, FIRST_LOGIN } from "config";
import { createToken } from "modules/user/actions/authActions";
import _get from "lodash/get";
import { Link } from "react-router";
import { ReactComponent as EmailIcon } from "static/icons/email.svg";
import { ReactComponent as EyeIcon } from "static/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "static/icons/eye-off.svg";
import { Box } from "rebass";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";

class LoginForm extends Component {
	state = {
		showPassword: false
	};

	componentDidUpdate(prevProps) {
		if (
			_get(this.props, "newEmail") &&
			_get(prevProps.newEmail) !== _get(this.props, "newEmail")
		) {
			this.props.dispatch(change("signin", "email", this.props.newEmail));
		}
	}

	createToken = formProps => {
		const searchParams = searchParser();
		if (
			!formProps.hasOwnProperty("email") ||
			!formProps.hasOwnProperty("password")
		) {
			return this.props.dispatch(
				setSubmitFailed("signin", "email", "password")
			);
		}
		if (this.props.type === FREELANCER) {
			this.props.showModal(FIRST_LOGIN);
		}
		return this.props.dispatch(
			createToken(
				formProps,
				this.props.shouldRedirect,
				this.props.redirectTo,
				null,
				searchParams
			)
		);
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const { handleSubmit, headings, inAction } = this.props;
		return (
			<Box flexGrow={1} display={"flex"} alignItems={"center"} paddingY={20}>
				<Container>
					<SubContainer>
						{!!headings ? (
							headings
						) : (
							<>
								<h1 className="heading">Log in</h1>
								<div className="description">
									Please enter your credentials to login.
								</div>
							</>
						)}
						<form className="form" onSubmit={handleSubmit(this.createToken)}>
							<div className="form-field">
								<Field
									classes="inner-icon left-icon"
									svgPrefixIcon={
										<span className="icon">
											<EmailIcon />
										</span>
									}
									name="email"
									label="Email"
									placeholder="Enter your email"
									normalize={this.normalizeEmail}
									type="text"
									component={FormField}
								/>
							</div>
							<div className="form-field">
								<Field
									classes="inner-icon right-icon"
									svgSuffixIcon={
										<span
											role="button"
											className="icon"
											onClick={() =>
												this.setState(state => ({
													...state,
													showPassword: !state.showPassword
												}))
											}
										>
											{this.state.showPassword ? <EyeOffIcon /> : <EyeIcon />}
										</span>
									}
									name="password"
									label="Password"
									placeholder="••••••••"
									type={this.state.showPassword ? "text" : "password"}
									component={FormField}
								/>
							</div>
							<div style={{ marginBottom: 20 }}>
								<Link className="link" to="/recover-password">
									Forgot password?
								</Link>
							</div>
							<button
								onClick={handleSubmit(this.createToken)}
								className="form-btn"
								disabled={inAction}
							>
								{inAction ? <AnimatedLoaderIcon width={24} /> : "Log in"}
							</button>
						</form>
						<div className="description sign-up-cta">
							{`Don't have an account?`}{" "}
							<Link className="link" to="/sign-up">
								Sign up
							</Link>
						</div>
					</SubContainer>
				</Container>
			</Box>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Please enter your email.";
	} else if (!emailRegex.test(formProps.email)) {
		errors.email = "Please enter a valid email.";
	}
	if (!formProps.password || formProps.password.trim() === "") {
		errors.password = "Please enter your password.";
	}
	return errors;
};

LoginForm.defaultProps = {
	shouldRedirect: true
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			change
		},
		dispatch
	);

const mapStateToProps = state => ({
	inAction: state.app.axiosHelper.inAction
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "signin",
		validate,
		touchOnBlur: false
	})
)(LoginForm);
