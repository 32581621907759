//TODO We need to find a solution for Pagination, every time we import icon and className
//TODO We should pick _get or get from lodash
/*eslint-disable*/
import React, { Component } from "react";
import { connect } from "react-redux";
import BodyClassName from "react-body-classname";
import { Box } from "rebass";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import { initialize } from "redux-form";
import { Helmet } from "react-helmet";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import ModalUI from "./ModalUI";
import {
	CREATE_USER,
	RESEND_INVITE,
	RESEND_INVITATON,
	EDIT_PROFILE,
	ASSIGN_ROLE,
	BLOCK_USER,
	UNBLOCK_USER,
	TRANSFERT_OWNERSHIP,
	INVITE_PREVIEW,
	PROJECT_MANAGER,
	DEPARTMENTS,
	EDIT_DEPARTMENT,
	ADMIN,
	DELETE_DEPARTMENT,
	SUPER_ADMIN,
	DELETE_INVITATON,
	DELETE_INVITE,
	USER
} from "config";
import Intro from "common/dashboard/Intro";
import {
	handleStep,
	resendInvite as resendInviteAction,
	accessChanged,
	getUsers,
	getDepartmentsList,
	showCreateUserModal,
	closeModal,
	createDepartment,
	toggleDepartmentModal,
	deleteInvite,
	transfertOwnership,
	toggleDepartmentModalSuccess,
	moveToStep
} from "../../actions/usersListActions";
import toaster from "common/Toaster";
import GlobalTooltip from "common/GlobalTooltip";
import { SwitcherContainer, Switcher } from "common/styled/CommonStyled";
import UserListView from "./UserListView";
import DepartmentsListView from "./DepartmentsListView";
import DepartmentModal from "./DepartmentModal";
import { toggleGetStartedDialog } from "../../../user/actions/authActions";
import DeleteDepartmentModal from "../modals/DeleteDepartmentModal";
import { SearchInput } from "common/SearchInput";
import styles from "./users-list.module.scss";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import Pagination from "common/NewPagination";
import { SELECTORS_TOUR_USER_AND_DEPARTEMENT } from "modules/user-onboarding/workflows/create-users-departments";
import TourButton from "./ButtonRender";
import DepartmentSuccess from "./ModalSuccessCreateDepartmentFromVacancyForm";
import GetStartedDialog from "modules/onboarding/GetStartedDialog/index ";

export const getUserListRoute = () => {
	return "/settings/users(/:type)";
};

export const getUserListPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const AddUser = ({ isManager, showModal }) => (
	<GlobalTooltip
		key={"create-user"}
		active={isManager}
		placement="top"
		overlay="As a Project Manager, you can’t create users."
	>
		<TourButton
			onClick={showModal}
			disabled={isManager}
			text="Create User"
			icon={<AddIcon />}
			variant="outlined"
			id={SELECTORS_TOUR_USER_AND_DEPARTEMENT[0]}
			className={styles.addButton}
		/>
	</GlobalTooltip>
);

const AddDepartment = ({ isNotSuperAdmin, toggleDepartmentModal }) => (
	<GlobalTooltip
		key="create-department"
		active={isNotSuperAdmin}
		placement="top"
		overlay="Only a super-admin can create departments."
	>
		<TourButton
			onClick={toggleDepartmentModal}
			disabled={isNotSuperAdmin}
			text="Create Department"
			icon={<AddIcon />}
			variant="outlined"
			className={styles.addButton}
			id={SELECTORS_TOUR_USER_AND_DEPARTEMENT[1]}
		/>
	</GlobalTooltip>
);

class UsersList extends Component {
	state = {
		showWelcome: true,
		deleteDepartmentModal: false,
		selectedDepartment: {},
		vacancyId: null,
		offset: 0,
		search: "",
		debounceValue: "",
		showGetStartedDialog: true
	};

	debouncedSubmit = _debounce(
		val => this.setState({ debounceValue: val }),
		500
	);

	componentDidMount() {
		const {
			params: { type },
			location: { query }
		} = this.props;
		this.loadData(type);

		if (query.openDepartmentModal) {
			this.props.toggleDepartmentModal();
			this.setState({
				vacancyId: query.vacancyId || "creation"
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const prevType = _get(prevProps, "params.type", "");
		const type = _get(this.props, "params.type", "");

		if (
			prevState.debounceValue !== this.state.debounceValue ||
			prevState.offset !== this.state.offset ||
			prevType !== type
		) {
			this.loadData(type);
		}
	}

	handleNextStep = (currentStep, isTourMode, nextStep, payload, isEdit) => {
		const { search, offset } = this.state;
		if (!payload) payload = this.props.user;

		return this.props.dispatch(
			handleStep(currentStep, isTourMode, nextStep, payload, isEdit, {
				search,
				offset
			})
		);
	};

	closeModal = () => {
		const { isEdit, step, user, dispatch } = this.props;
		dispatch(closeModal());
		if (!isEdit && (step === ASSIGN_ROLE || step === INVITE_PREVIEW)) {
			toaster.warning(
				`${user.first_name} ${user.last_name}'s account creation has been cancelled.`
			);
		}
	};

	showModal = (e, step = CREATE_USER, user = null, isEdit) => {
		this.props.dispatch(showCreateUserModal(step, user, isEdit));
	};

	handleMenuClick = (e, data) => {
		switch (e.key) {
			case RESEND_INVITATON:
				return this.showModal(e, RESEND_INVITE, data);
			case EDIT_PROFILE:
				return this.showModal(e, ASSIGN_ROLE, data, true);
			case BLOCK_USER:
				return this.showModal(e, BLOCK_USER, data);
			case UNBLOCK_USER:
				return this.showModal(e, UNBLOCK_USER, data);
			case TRANSFERT_OWNERSHIP:
				return this.showModal(e, TRANSFERT_OWNERSHIP, data);
			case EDIT_DEPARTMENT:
				return this.props.toggleDepartmentModal(data);
			case DELETE_DEPARTMENT:
				return this.openDeleteDepartmentModal(data);
			case DELETE_INVITATON:
				return this.showModal(e, DELETE_INVITE, data);
			default:
				break;
		}
	};

	resendInvite = user => {
		const payload = {
			id: user.invitation_id,
			group_id: _get(user, "group_roles_name.0._id", ""),
			role_name: _get(user, "group_roles_name.0.role_name", "")
		};
		this.props.dispatch(
			resendInviteAction(payload, {
				search: this.state.search,
				offset: this.state.offset
			})
		);
	};

	deleteInvite = user => {
		const payload = {
			id: user.invitation_id
		};
		this.props.dispatch(
			deleteInvite(payload, {
				search: this.state.search,
				offset: this.state.offset
			})
		);
	};

	onTranfertOwnership = (user, password) => {
		const payload = {
			transfer_user_id: user._id,
			password
		};
		this.props.dispatch(
			transfertOwnership(payload, {
				search: this.state.search,
				offset: this.state.offset
			})
		);
	};

	onAccessChanged = user => {
		const { dispatch } = this.props;
		dispatch(
			accessChanged(user, true, {
				search: this.state.search,
				offset: this.state.offset
			})
		);
	};

	loadData = type => {
		const { dispatch } = this.props;
		const { debounceValue, offset } = this.state;

		if (type === USER) dispatch(getUsers({ search: debounceValue, offset }));
		if (!type || type === DEPARTMENTS)
			dispatch(getDepartmentsList({ search: debounceValue, offset }));
	};

	switchView = type => {
		browserHistory.push(`/settings/users${type ? "/" + type : ""}`);
	};

	openDeleteDepartmentModal = dep => {
		this.setState({ deleteDepartmentModal: true, selectedDepartment: dep });
	};
	closeDeleteDepartmentModal = () => {
		this.setState({ deleteDepartmentModal: false });
	};

	onDepartmentClick = () => {
		this.setState({
			offset: 0,
			search: "",
			debounceValue: ""
		});
		this.switchView();
	};

	onUserClick = () => {
		this.setState({
			offset: 0,
			search: "",
			debounceValue: ""
		});
		this.switchView(USER);
	};

	onSearch = e => {
		this.setState({
			search: e.target.value,
			offset: 0
		});
		this.debouncedSubmit(e.target.value);
	};

	closeGetStartedDialog = () => {
		const { toggleGetStartedDialog } = this.props;
		toggleGetStartedDialog();
	};
	onGetStarted = () => {
		this.closeGetStartedDialog();
		browserHistory.push("/settings/setup-guide?type=first_login");
	};

	render() {
		const data = {
			size: 6,
			total: 100,
			offset: 0,
			data: []
		};
		const {
			route: { title },
			users,
			departments,
			step,
			user,
			exists,
			showModal,
			modalLoading,
			connectedUser,
			modalSuccess,
			isEdit,
			createDepartment,
			departmentModal,
			initializeForm,
			showSuccessModalDepartment,
			params: { type },
			onboardingAlreadyStarted
		} = this.props;

		let size;
		let total;
		let offset;

		if (type === USER && users?.users_list?.[0]?.total) {
			({ size, total, offset } = users?.users_list?.[0]);
		} else if (!type && departments?.groups_list?.total) {
			({ size, total, offset } = departments?.groups_list);
		}

		const isManager = connectedUser.role_name === PROJECT_MANAGER;

		const isNotSuperAdmin =
			connectedUser.role_name === SUPER_ADMIN ? false : connectedUser.is_member;

		const isAdminIn =
			!isManager &&
			connectedUser.groups.reduce((acc, group) => {
				if (group.role_name === ADMIN) acc.push(group.id);
				return acc;
			}, []);

		return (
			<BodyClassName className="company gray-bg">
				<div id="users-list">
					{onboardingAlreadyStarted && connectedUser.is_first_login && (
						<GetStartedDialog
							onGetStarted={this.onGetStarted}
							onClose={this.closeGetStartedDialog}
						/>
					)}
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<Box p={"20px 0px"} mb={20}>
						<Intro
							icon="fa fa-clipboard"
							title="Users & Departments"
							isClient={true}
							className={styles.intro}
						>
							<p>
								This section allows you to create departments, invite users, and
								assign roles to specific users relative to their
								responsibilities within your company. <br />
								Start by creating a department to be able to invite users and
								post requests.
							</p>
						</Intro>
					</Box>
					<Box p={"0 15px"} id="users-list" className="full-container">
						<SwitcherContainer bordred paddingTop="0">
							<Switcher
								checked={!type || type === DEPARTMENTS}
								onClick={this.onDepartmentClick}
							>
								Departments
							</Switcher>
							<Switcher checked={type === USER} onClick={this.onUserClick}>
								Users
							</Switcher>
						</SwitcherContainer>

						{type === USER && (
							<>
								{total > 0 && (
									<div key="search" className={styles.searchContainer}>
										<SearchInput
											inputClassName={styles.searchInput}
											placeholder="Find a user"
											onChange={this.onSearch}
											value={this.state.search}
										/>
										<AddUser
											isManager={isManager}
											showModal={() => this.showModal()}
										/>
									</div>
								)}
								<UserListView
									key={"user-list-view"}
									onMenuSelect={this.handleMenuClick}
									list={users?.users_list?.[0]?.data}
									connectedUser={connectedUser}
									isManager={isManager}
									isAdminIn={isAdminIn}
									AddButton={() => (
										<AddUser
											isManager={isManager}
											showModal={() => this.showModal()}
										/>
									)}
								/>
							</>
						)}

						{(!type || type === DEPARTMENTS) && (
							<>
								{total > 0 && (
									<div key="search" className={styles.searchContainer}>
										<SearchInput
											inputClassName={styles.searchInput}
											placeholder="Find a department"
											onChange={this.onSearch}
											value={this.state.search}
										/>
										<AddDepartment
											isNotSuperAdmin={isNotSuperAdmin}
											toggleDepartmentModal={this.props.toggleDepartmentModal}
										/>
									</div>
								)}
								<DepartmentsListView
									key="departments-list-view"
									list={departments?.groups_list?.data}
									onMenuSelect={this.handleMenuClick}
									connectedUser={connectedUser}
									AddButton={() => (
										<AddDepartment
											isNotSuperAdmin={isNotSuperAdmin}
											toggleDepartmentModal={this.props.toggleDepartmentModal}
										/>
									)}
								/>
							</>
						)}
					</Box>

					{showSuccessModalDepartment && (
						<DepartmentSuccess
							vacancyId={this.state.vacancyId}
							isEdit={isEdit}
							onClose={this.props.toggleDepartmentModalSuccess}
							onBack={this.props.toggleDepartmentModalSuccess}
							createdDepartment={createDepartment}
						/>
					)}
					{departmentModal.show && (
						<DepartmentModal
							onClose={() => {
								setTimeout(() => {
									this.props.toggleDepartmentModal();
								}, 200);
							}}
							onBack={this.props.toggleDepartmentModal}
							usersList={departments?.users_company}
							deptList={departments?.groups_list?.data}
							connectedUser={connectedUser}
							createDepartment={createDepartment}
							data={departmentModal}
							initializeForm={initializeForm}
							vacancyId={this.state.vacancyId}
							createdDepartment={departments?.groups_list?.data[0]}
							search={this.state.search}
							offset={offset}
							isOpen={departmentModal.show}
							toggleDepartmentModalSuccess={
								this.props.toggleDepartmentModalSuccess
							}
							showSuccessModalDepartment={showSuccessModalDepartment}
						/>
					)}
					<DeleteDepartmentModal
						active={this.state.deleteDepartmentModal}
						onClose={this.closeDeleteDepartmentModal}
						selected={this.state.selectedDepartment}
						reloadList={() => this.loadData(DEPARTMENTS)}
					/>
					{showModal && (
						<ModalUI
							isOpen={showModal}
							loading={modalLoading}
							step={step}
							isEdit={isEdit}
							user={user}
							users={users}
							exists={exists}
							onClose={() => {
								setTimeout(() => {
									this.closeModal();
								}, 200);
							}}
							onResend={this.resendInvite}
							onDeleteInvitation={this.deleteInvite}
							onAccessChanged={this.onAccessChanged}
							onTranfertOwnership={this.onTranfertOwnership}
							modalSuccess={modalSuccess}
							connectedUser={connectedUser}
							adminName={`${connectedUser.first_name} ${connectedUser.last_name}`}
							handleStep={this.handleNextStep}
							currentUserGroupRolesName={
								users?.user_connected?.group_roles_name
							}
							toggleDepartmentModal={this.props.toggleDepartmentModal}
							moveToStep={this.props.moveToStep}
						/>
					)}

					{total > 0 && (
						<div style={{ position: "relative" }}>
							<Pagination
								previousLabel={<PrevIcon />}
								nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
								total={total}
								size={size}
								handlePageClick={page => {
									this.setState({ offset: page * size });
								}}
								offset={offset}
								forcePage={Math.ceil(_get(data, "offset") / size)}
								pageRangeDisplayed={total > 1000 ? 6 : undefined}
								marginPagesDisplayed={total > 1000 ? 0 : undefined}
								className={styles.pagination}
								forceSelected={true}
							/>
						</div>
					)}
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		connectedUser: state.auth.user,
		showWelcome: state.auth.showWelcome,
		onboardingAlreadyStarted: state.auth.onboardingAlreadyStarted,
		departments: state.usersList.departments,
		users: state.usersList.users,
		step: state.usersList.step,
		user: state.usersList.user,
		loading: state.usersList.loading,
		exists: state.usersList.exists,
		showModal: state.usersList.showModal,
		modalLoading: state.usersList.modalLoading,
		modalSuccess: state.usersList.modalSuccess,
		isEdit: state.usersList.isEdit,
		departmentModal: state.usersList.departmentModal,
		showSuccessModalDepartment: state.usersList.showSuccessModalDepartment
	};
};

const mapDispatchToProps = dispatch => {
	return {
		moveToStep: (step, user) => dispatch(moveToStep(step, user)),
		toggleGetStartedDialog: () => dispatch(toggleGetStartedDialog()),
		toggleDepartmentModalSuccess: () =>
			dispatch(toggleDepartmentModalSuccess()),
		getUsers: () => dispatch(getUsers()),
		createDepartment: (
			payload,
			isModeTour,
			showSuccessModalDepartment,
			isEdit,
			options
		) =>
			dispatch(
				createDepartment(
					payload,
					isModeTour,
					showSuccessModalDepartment,
					isEdit,
					options
				)
			),
		toggleDepartmentModal: department =>
			dispatch(toggleDepartmentModal(department)),
		initializeForm: (formName, formProps) =>
			dispatch(initialize(formName, formProps)),
		dispatch
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
