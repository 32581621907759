import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_BUSINESS_MANAGER } from "config/api-endpoints";

const getManagers = department => {
	return client(FILTER_SETTINGS_BUSINESS_MANAGER, {
		body: {
			tag: "department_users",
			group_id: department?.value || ""
		}
	});
};

export const GET_MANAGERS_QUERY_KEY = "GET_MANAGERS_QUERY_KEY";

export const useGetBusinessManagerList = ({ department }) => {
	const res = useQuery(
		[GET_MANAGERS_QUERY_KEY, department],
		() => getManagers(department),
		{
			refetchOnWindowFocus: false,
			retry: 0
		}
	);

	return res;
};
