/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import classes from "modules/SearchCandidate/components/TableContainer/table-container.module.scss";
import Portal from "common/Portal";
import DotHorzIcons from "common/AgTable/icons/DotsHor";
import s from "common/AgTable/components/MenuColumn/menu-column.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";
import Menu from "common/AgTable/components/Menu/Menu";
import get from "lodash/get";
import { Item } from "@react-stately/collections";
import { usePopper } from "react-popper";
import { useToggle } from "react-use";
import style from "./styles.module.scss";
import { ReactComponent as EditIcon } from "static/icons/drawer-notes/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "static/icons/drawer-notes/DeleteIcon.svg";
import { v4 as uuid } from "uuid";

import { getMentions, parseMentions } from "config/helpers";
import useNotesStore from "modules/notes/notes.store";
import { NOTES_FILTER_ID } from "../utils";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import DeleteModal from "../DeleteModal/DeleteModal";
import useGetNotesList from "../../hooks/useGetNotesList";
import { queryCache } from "react-query";

const SelectColumn = ({ data }) => {
	const [showMenu, setShowMenu] = useToggle(false);
	const referenceElement = useRef();
	const [showModal, setShowModal] = useState(false);
	const [noteOwner, setNoteOwner] = useState(false);
	const popperElement = useRef();
	const refetch = useGetNotesList();

	useEffect(() => {
		const currentUser = JSON.parse(localStorage.getItem("current_user"));
		if (currentUser.id == data["createdBy"]._id) {
			setNoteOwner(true);
		}
	}, []);
	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "right-start",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [-9, 10]
					}
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top", "right"]
					}
				}
			]
		}
	);
	const { getState } = useFilters();
	const {
		data: { data: notes }
	} = getState(NOTES_FILTER_ID);

	const { addNote } = useNotesStore(
		({ addNote, notes, activeNote, setActiveNote }) => ({
			addNote,
			notes,
			activeNote,
			setActiveNote
		})
	);

	const actionButtons = [
		{
			name: "Edit",
			key: "edit",
			icon: <EditIcon />
		},
		{
			name: "Delete",
			key: "delete",
			icon: <DeleteIcon />
		}
	];
	const editNote = () => {
		const description = data.Content;

		// TODO use helper isHTML
		const isDescriptionInHtml = /<\/?[a-z][\s\S]*>/i.test(description);

		let mentions = [];

		if (isDescriptionInHtml) {
			mentions = data.mentioned_users;
		} else {
			// To support old notes in text format, the format of mention is this ?##Maxim Cornet,2678344a-371b-4f40-a748-6f67bdaf25f7##?
			const result = getMentions(description);

			mentions = (result || []).map(mention => {
				const content = mention.replace("?##", "").replace("##?", "");
				const value = content.split(",");
				return value[1];
			});
		}

		addNote({
			id: uuid(),
			isEditNote: true,
			canSelectCandidate: true,
			payload: {
				vacancy: {
					_id: get(data, "job_id", ""),
					name: get(data, "job.title", "")
				},
				candidate: {
					value: get(data, "user._id", ""),
					label: `${get(data, "user.first_name")} ${get(
						data,
						"user.last_name"
					)}`,
					profile_id: get(data, "user._id", "")
				},
				type: {
					value:
						get(data, "Note type", "") !== null
							? get(data, "Note type", "")
									?.replace(/ /g, "_")
									.toLowerCase()
							: "",
					label: get(data, "Note type", "")
				},
				comment: isDescriptionInHtml
					? description
					: `<p><span>${parseMentions(description)}</span></p>`,
				note_id: get(data, "id", ""),
				mentions: [],
				oldUsers: mentions,
				visibility: get(data, "visibility"),
				visible: get(data, "visible"),
				not_visible: get(data, "not_visible")
			}
		});
	};

	useOnClickOutside(popperElement, () => {
		setShowMenu(false);
	});
	const handleClickMenu = data => {
		switch (data) {
			case "delete":
				return setShowModal(true);
			case "edit":
				return editNote();
		}
	};

	return (
		<div className={`${classes.selectIconsContainer}`}>
			{noteOwner && (
				<button
					className={classes.dotsHorizontal}
					ref={referenceElement}
					onClick={setShowMenu}
				>
					<DotHorzIcons
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
				</button>
			)}
			<Portal>
				<div
					style={{ ...styles.popper, display: showMenu ? "block" : "none" }}
					{...attributes.popper}
					ref={popperElement}
					className={classes.popperContainer}
				>
					<Menu aria-label="Actions" onAction={handleClickMenu}>
						{actionButtons.map(item => {
							return (
								<Item key={get(item, "key")}>
									{get(item, "icon")}
									<div
										className={s.headerItem}
										data-onboarding-step={item["data-onboarding-step"]}
									>
										{get(item, "name")}
									</div>
								</Item>
							);
						})}
					</Menu>
				</div>
				<div className={style.deleteModal}>
					{showModal && (
						<DeleteModal
							onClose={() => setShowModal(false)}
							title={"Delete Note"}
							body={"Are you sure you want to delete this note ?"}
							payload={{
								profile_id: get(data, "user._id", ""),
								note_id: get(data, "id", ""),
								note_type:
									get(data, "Note type", "") !== null
										? get(data, "Note type", "")
												?.replace(/ /g, "_")
												?.toLowerCase()
										: ""
							}}
						/>
					)}
				</div>
			</Portal>
		</div>
	);
};

export default SelectColumn;
