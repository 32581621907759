import React from "react";
import styles from "./field-error.module.scss";
import { ReactComponent as InfoIcon } from "static/icons/info-icon-red.svg";
import cx from "classnames";

const FieldError = ({ message, className }) => {
	return (
		<div className={cx(styles.container, className)}>
			<InfoIcon />
			<div>{message}</div>
		</div>
	);
};

export default FieldError;
