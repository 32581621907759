export const FETCH_POKE_DATA = "FETCH_POKE_DATA";
export const FETCH_PERMANENT_PROFILE = "FETCH_PERMANENT_PROFILE";
export const FETCH_MY_JOBS_SUCCESS = "FETCH_MY_JOBS_SUCCESS";
export const RESET_CANDIDATE_PROFILE = "RESET_CANDIDATE_PROFILE";
export const SET_PREVIOUS_PATH = "SET_PREVIOUS_PATH";
export const DISPLAY_INTERVIEW_INVITATION = "DISPLAY_INTERVIEW_INVITATION";
export const ACCEPT_INTERVIEW_SUCCESS = "ACCEPT_INTERVIEW_SUCCESS";
export const DISPLAY_PROPOSE_MODAL = "DISPLAY_PROPOSE_MODAL";
export const EDIT_INTERVIEW_SUCCESS = "EDIT_INTERVIEW_SUCCESS";
export const TOGGLE_MODAL_REMOVE_INTEREST =
	"@candidate/TOGGLE_MODAL_REMOVE_INTEREST";
export const TOGGLE_CANCEL_INTERVIEW_MODAL =
	"@candidate/TOGGLE_CANCEL_INTERVIEW_MODAL";
export const SET_LIST_ATTENDEES = "SET_LIST_ATTENDEES";
export const TOGGLE_CANCEL_APPLICATION_MODAL =
	"TOGGLE_CANCEL_APPLICATION_MODAL";
export const UPDATE_UPLOADING_CV = "@candidate/UPDATE_UPLOADING_CV";
export const UPDATE_UPLOADING_CV_ERROR = "@candidate/UPDATE_UPLOADING_CV_ERROR";
export const UPDATE_CV_FILE = "@candidate/UPDATE_CV_FILE";
