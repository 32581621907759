import styled, { css } from "styled-components";
import { colors } from "config/styles";
import { Button } from "common/styled/buttons";

export const PokeMessage = styled.div`
	text-align: center;
	color: ${colors.darkGray};
	font-size: 14px;
	margin-top: 40px;
	line-height: 1.74;
	font-weight: 400;
	strong {
		font-weight: 500;
	}
	.declined {
		margin-bottom: 60px;
	}
	.additionalCommentWrapper {
		margin-bottom: 5px;
		.additionalComments {
			margin-top: 0;
		}
	}
	button {
		outline: 0;
		margin-top: 0;
		line-height: 36px;
		border: 0;
		background-color: #ffffff;
		font-size: 13px;
		font-weight: bold;
		color: #293d58;
		-webkit-transition: all 0.3s ease-out;
		-moz-transition: all 0.3s ease-out;
		-ms-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
		i {
			color: #ff6849;
			margin-left: 16px;
			font-size: 10px;
			-webkit-transition: 0.6s ease-out;
			-moz-transition: 0.6s ease-out;
			transition: 0.6s ease-out;
			display: inline-block;
			transform: rotate(89deg);
			&.toggle-down {
				transform: rotate(-92deg);
				display: inline-block;
			}
		}
	}
`;

export const Sidebar = styled.div`
	border-radius: 3px;
	background-color: ${colors.white};
	padding: 25px 20px;
	padding-bottom: 30px;
	border: 1px solid ${colors.sectionBorderColor};
	.actions {
		button {
			border-width: 2px;
			border-style: solid;
			border-image: initial;
			&.sidebar-confirm-button {
				margin-bottom: 10px;
				&.primary-action {
					border-color: ${colors.primaryColor};
				}
			}
			&.sidebar-decline-button {
				margin-bottom: 10px;
				border-color: ${colors.darkBorderColor};
			}
		}
	}
`;

export const VacancyInformations = styled.div`
	color: ${colors.anchorsColor};
	font-weight: 400;
	margin-top: ${props => (props.marginTop ? props.marginTop : "20px")};
	.price-formatted {
		font-weight: bold !important;
	}
`;

export const FlagSection = styled.div`
	background-color: red;
	img {
		width: 100px;
	}
`;

export const Divider = styled.div`
	height: 4px;
	width: 50px;
	background-color: ${colors.divider};
	margin: 20px 0;
`;

export const LinkButton = styled.button`
	border: none;
	background-color: transparent;
	text-decoration: underline;
	padding: 0;
	margin: 0;
	color: ${colors.primaryColor};
	&:hover {
		text-decoration: none;
	}
`;
export const ClientName = styled.div`
	font-size: 14px;
	margin: 2% 0 0% 0%;
	color: #58626b;
	font-weight: 500;
`;

export const DescriptionDetail = styled.div`
	font-size: 13px;
	font-weight: 400;
	.read-more {
		text-decoration: underline;
		color: #8a95a1;
		font-weight: bold;
		cursor: pointer;
		margin-left: 5px;
	}
`;
export const InterviewDesc = styled.div`
	line-height: 22px;
	font-size: 13px;
	font-weight: 400;
	padding-top: 10px;
	.description-title {
		color: #58626b;
		font-weight: 500;
		font-size: 12.5px;
		text-transform: uppercase;
	}
`;
export const BodyContainer = styled.div`
	display: flex;
	.section-proposition {
		width: 100%;
		display: inline-block;
	}
	.interview-desc {
		width: 40%;
		line-height: 22px;
		font-size: 13px;
		font-weight: 400;
		.description {
			word-break: break-word;
		}
		.description-title {
			font-weight: bold;
		}
	}
	.timeDate-container {
		.icon-date-location {
			align-self: center;
			font-size: 25px;
			color: #ff6849;
			width: 15%;
			margin-left: 15px;
		}
		.icon-date-adress {
			align-self: center;
		}
	}
	.adress-container {
		svg {
			width: 50px;
			height: 50px;
			align-self: center;
			path {
				fill: #ff6849;
			}
		}
	}
	.adress-grid {
		display: inline-grid;
		font-size: 12px;
		span {
			height: 15px;
		}
	}
	.timeDate-container,
	.location-container {
		margin-right: 15px;
		border-radius: 4px;
		box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
		border: solid 0.5px #e1e4e7;
		background-color: #ffffff;
		display: flex;
		width: 32%;
		max-height: 120px;
		height: 100px;

		.location-divider {
			display: grid;
			align-self: center;
			height: fit-content;
		}
		.adress-divider {
			display: grid;
			margin-top: 5px;
			align-self: normal;
			height: fit-content;
		}

		.location-title,
		.timeDate-title {
			font-weight: bold;
			font-size: 13px;
			height: 22px;
		}

		.adress-container .location-title {
			height: 5px;
		}

		.location-infos {
			font-size: 14px;
			font-weight: 400;
		}
		.timeDate-adress {
			height: fit-content;
			align-self: center;
		}
		.timeDate-infos {
			display: grid;

			.timeDate-values {
				display: flex;
			}
			.date-value {
				margin-right: 10px;
				font-size: 13px;
				font-weight: 400;
			}
			.time-value {
				margin-left: 10px;
				font-size: 13px;
				font-weight: 400;
			}
		}

		.video_conf_icon {
			width: 55px;
		}

		svg {
			width: 45px;
			height: auto;
			margin: 5px;

			path {
				fill: #ff6849;
			}
		}
	}
`;
export const StyledDivider = styled.div`
	border-left: 2px solid #e4e8ec;
	height: 15px;
	margin-top: 10px;
`;

export const FloatedBtn = styled(Button)`
	float: right;
	margin-left: 15px;
	width: 175px;
`;
export const RadioBtnResult = styled.div`
	margin-bottom: 30px;
`;
export const BtnContainer = styled.div`
	float: right;
	margin-top: 20px;
`;

export const LocationContainer = styled.div`
	.location-border {
		border-left: solid 3px #ff6849;
		height: 90px;
	}
	.fixed-height {
		height: 65px;
	}
	.adress-var {
		font-weight: 400;
	}
`;

export const InfoBox = styled.div`
	border-radius: 3px;
	border: 1px solid ${colors.skillsIntroBorder};
	background-color: ${colors.skillsIntroBg};
	color: ${colors.skillsIntroColor};
	padding: ${props => props.padding || "15px 20px"};
	margin: ${props => props.margin || "30px auto 0"};
	font-weight: 400;
	font-size: ${props => (props.size ? `${props.size}px` : `14px`)};
	max-width: 800px;
	line-height: 1.5;
	display: ${props => (props.noDisplay ? "none" : "flex")};
	flex-direction: ${props => (props.column ? "column" : "row")};
	align-items: center;
	width: fit-content;
	clear: both;

	p,
	h3,
	button {
		color: ${colors.skillsIntroColor} !important;
	}
	p {
		font-weight: 400 !important;
	}
	h3 {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 500 !important;
		margin-bottom: 5px;
	}
	button {
		border: none;
		background-color: transparent;
		font-weight: 500 !important;
		&:hover {
			text-decoration: underline;
		}
	}
`;

export const InfoBoxIcon = styled.i`
	font-size: 20px;
	margin-right: 15px;
`;

export const VideoBlock = styled.div`
	background-color: ${colors.white};
	min-height: 50px;
	border: 1px solid ${colors.sectionBorder};
	color: ${colors.linksColor};
	margin-bottom: 5px;
	display: flex;
	font-weight: 500;
	img {
		width: 20px;
	}
`;

export const Placeholder = styled.span`
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.79;
	letter-spacing: normal;
	text-align: left;
	color: ${colors.placeholderColor};
	align-self: ${props => (props.center ? "center" : "unset")};
`;

export const PokeNotesTableDetails = styled.table`
	width: 100%;
	border: 0;
	table-layout: fixed;
	margin: ${props => props.margin || 0};
	border-spacing: ${props => props.spacing || 0};
	border-collapse: ${props => (props.collapse ? "collapse" : "initial")};
	.g-tool {
		.cancelled {
			color: #fc4f56;
			font-size: 20px;
		}
		.done {
			height: 20px;
			width: 20px;
			line-height: 2;
			font-weight: 500;
			text-align: center;
			border-radius: 26px;
			font-size: 12px;
			background: #36ba90;
			color: #fff;
		}
	}
`;

export const Th = styled.th`
	border-radius: 1.5px;
	background-color: ${colors.white};
	padding: 18px !important;
	font-size: 12.5px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: ${colors.anchorsColor};
	border: 0;
	border-bottom: 4px solid ${colors.bodyBg};
	text-align: ${props => props.align || "left"};
	width: ${props => props.width || "auto"};
`;

export const Td = styled.td`
	border-radius: 1.5px;
	background-color: ${colors.white};
	padding: 18px 18px !important;
	height: 44px;
	font-size: 13px;
	font-weight: 400;
	text-transform: none;
	line-height: 1.3;
	color: ${colors.anchorsColor};
	text-align: ${props => props.align || "left"};
	border: 0;
	.rest-attendees {
		font-size: 13px;
		font-weight: 400;
		text-transform: none;
		color: #58626b;
	}
	.view-more {
		color: ${colors.disabledGray};
		text-transform: uppercase;
		&:hover {
			text-decoration: underline;
			color: ${colors.primaryColor};
		}
	}
	&:first-child {
		border-left: ${props =>
			!props.subTotal && "3px solid transparent"} !important;
	}
	&:last-child {
		border-right: none !important;
	}
	width: ${props => props.width || "auto"};
	${props =>
		props.ellipsis &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`};
`;

export const Tr = styled.tr`
	cursor: ${props => (props.onClick ? "pointer" : "default")};
	&:hover {
		${Td}:first-child {
			border-left-color: ${colors.primaryColor} !important;
		}
		${Td} {
			background-color: unset !important;
		}
	}
	float: ${props => props.float || "none"};
`;

export const Section = styled.div`
	padding: 25px 0 0 !important;
	border: 0px !important;

	.past-interviews {
		font-size: 15px;
		line-height: 15px;
		text-transform: uppercase;
		color: #58626b;
		font-weight: 500;
		margin-left: 25px;
	}
`;
export const Content = styled.div`
	line-height: 1.6;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};

	font-weight: normal;
	word-break: break-word;
	${props => props.location && `text-align: center; font-size:12px`};
	.adress-grid {
		span {
			display: block;
		}
	}
`;

export const PropositionBox = styled.div`
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	margin: ${props => (props.maxwidth ? "0 0 0 0" : "30px 30px 30px 0")};
	font-weight: 400;
	display: flex;
	align-items: center;
	width: 100%;
	.title {
		max-width: ${props => (props.maxwidth ? "100%" : "none")};
		color: ${props =>
			props.orange ? colors.primaryColor : colors.anchorsColor};
		text-align: left;
		font-weight: bold;
		font-size: 15px;
		text-transform: none;
		line-height: 14px;
		margin-bottom: 18px;
		div {
			font-weight: normal;
			font-size: 12px;
			margin-top: 3px;
		}
	}
	.date {
		text-align: center;
		font-weight: 300;
		line-height: 20px;
	}
	.BoxWrapper {
		display: flex;
		width: 100%;
		&.title {
			margin-bottom: 13px;
			&.modal-proposition {
				color: #242f43;
			}
		}
	}
`;

export const BoxInterview = styled.div`
	max-width: 270px;
	width: 100%;
	height: ${({ height }) => (height ? height : "112px")};
	border-radius: 4px;
	box-shadow: ${({ boxShadow }) =>
		boxShadow ? boxShadow : "0px 2px 1px 0 rgba(197, 199, 206, 0.25)"};
	border: ${({ border }) => (border ? border : "solid 1px #e1e4e7")};
	background-color: #ffffff;
	align-items: center;
	display: flex;
	margin-right: 5px;
	.contentBox {
		width: 136px;
	}
	&:last-child {
		margin-left: 10px;
	}
	.videoConference {
		line-height: 23px;
		span {
			font-size: 14px;
			color: #242f43;
		}
	}
	.titleBox {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: #6d7175;
		display: flex;
		align-items: center;
		margin-bottom: 4px;
		> svg {
			min-width: 20px;
			margin-right: 10px;
		}
	}
	.icon-date-location {
		color: rgba(255, 104, 73, 0.96);
		font-size: 26px;
		margin-right: 16px;
		margin-left: 18px;
		.video-interview-icon {
			font-size: 20px !important;
		}
	}
	.detailsInterview {
		/* margin-left: 30px; */
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		span {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #242f43;
			display: inline-block;
			line-height: 22px;
			&.dateInterview {
				border-right: 1px solid #e4e8ec;
				padding-right: 8px;
				margin-right: 8px;
			}
			&.timeInterview {
				i {
					color: rgba(255, 104, 73, 0.96);
					font-size: 12px;
				}
			}
		}
	}
`;

export const FlexBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DescriptionContainer = styled.div`
	padding: 15px;
	background-color: #f8f8f9;
	border: solid 1px #eef0f1;
	border-radius: 4px;
	margin: 0px 75px 0px 45px;
	margin-bottom: ${props => (props.marginBottom ? "10px" : "40px")};
	.description {
		word-break: break-word;
	}
	.title {
		color: #242f43;
		font-weight: bold;
		font-size: 15px;
	}
`;

export const UserSender = styled.div`
	line-height: 14px;
`;

export const Title = styled.div`
	color: ${colors.anchorsColor};
	font-weight: 500;
	font-size: 12.5px;
	text-transform: uppercase;
	line-height: 14px;
	margin-bottom: 12px;
`;

export const AvatarHolder = styled.div`
	display: inline-block;
	width: 40px;
	vertical-align: middle;
	margin-right: 10px;
	> .avatar {
		border-radius: 0;
	}
`;

export const UserSenderName = styled.div`
	display: inline-block;
	vertical-align: middle;
	font-size: 13.5px;
	font-weight: 400;
	text-transform: capitalize;
	color: ${colors.darkGray};
`;

export const AttendeePopover = styled.div`
	border-radius: 4px;
	box-shadow: 0px 2px 21.5px 0 rgba(197, 199, 206, 0.43);
	border: solid 1px rgba(225, 228, 231, 0.82);
	background-color: #ffffff;
	margin-top: 5px;
	min-width: 200px;
	min-height: 100px;
	padding: 10px;
	z-index: 100;
	position: absolute;
`;

export const InterviewCanceledLabel = styled.div`
	color: #ff6849;
	text-align: center;
	font-weight: 300;
	font-size: 18px;
`;

export const InterviewWrapper = styled.div`
	padding: 35px 0px 0 0;
	display: flex;

	&.interview-modal {
		padding: 0px;
		max-width: 580px;
		margin: 0 auto;
	}
	.descriptionInterview {
		width: 40%;
		margin-top: 40px;
	}
	${props =>
		props.justify &&
		css`
			justify-content: space-between;

			> div {
				flex-grow: 1;
			}
		`};
`;

export const DescriptionText = styled.div`
	color: #293d58;
	text-align: left;
	font-weight: 400;
	.infos {
		color: #2570b7;
	}
	.link {
		text-decoration: underline;
		color: ${colors.primaryColor};
		cursor: pointer;
	}
	.bold {
		margin-top: 24px;
		font-weight: bold;
		margin-bottom: 16px;
	}
	.email {
		text-decoration: underline;
		font-weight: bold;
	}
	.contact-email {
		text-decoration: underline;
	}
`;

export const TitleBlock = styled.h3`
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #202223;
`;

export const PermanentActionsContainer = styled.div`
	button {
		height: 41px;
		border-radius: 3px;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		touch-action: manipulation;
		user-select: none;
		width: 100%;
		line-height: 15px;
		padding: 0px 20px;
		transition: all 0.5s;
	}
	.rc-dropdown {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.9px;
		color: #626b74;
		padding: 7px 8px;
		.rc-dropdown-menu-item {
			display: block;
			padding: 6px 10px;
			width: 100%;
		}
	}
`;
