import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

const AcceptInterviewModal = ({
	acceptInterview,
	closeModal,
	displayModal
}) => {
	return (
		<>
			<ConfirmationModal
				key="accept_interview_modal"
				onClose={closeModal}
				active={displayModal}
				modalName="accept_interview_modal"
				type={ConfirmationTypes.confirmation}
				title="Are you sure you would like to accept the interview?"
				firstButton={{
					action: () => {
						acceptInterview();
					},
					label: "YES, ACCEPT.",
					type: "success"
				}}
				secondButton={{
					action: closeModal,
					label: "NO"
				}}
			/>
		</>
	);
};

export default AcceptInterviewModal;
