import React, { forwardRef } from "react";
import styles from "./custom-input.module.scss";
import cx from "classnames";

const CustomInput = (
	{
		placeHolder,
		value,
		onChange,
		className,
		type = "text",
		maxlength,
		max,
		allowOnlyNumbers = false,
		allowSpaces = true,
		...propsRest
	},
	ref
) => {
	const handleChange = e => {
		let { value } = e.target;

		if (allowOnlyNumbers && !/^\d*$/.test(value)) {
			return;
		}

		if (!allowSpaces) {
			value = value.replace(/\s+/g, "");
		}

		onChange({
			...e,
			target: {
				...e.target,
				value
			}
		});
	};
	return (
		<input
			ref={ref}
			className={cx(styles.input, className)}
			value={value}
			onChange={handleChange}
			type={type}
			maxLength={maxlength}
			max={max}
			placeholder={placeHolder}
			{...propsRest}
			autoComplete="off"
		/>
	);
};

export default forwardRef(CustomInput);
